import React, { useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';

import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import toInteger from 'lodash/toInteger';

import styles from './styles';
import { IProps } from './types';

const Indicator = ({ classes, title, tooltipText, data, icon: Icon, event, externalUrl }: IProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tickets = event?.tickets;
  const hasUnlimited = !!tickets?.some(ticket => ticket.is_unlimited === 1);

  const openTooltip = () => setTooltipOpen(true);
  const closeTooltip = () => setTooltipOpen(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const infinitySymbol = <span className={classes.infinitySymbol}>∞</span>;

  const slug = title.split(' ')[0].toLowerCase();

  return (
    <Paper
      className={classes.root}
      elevation={0}
      variant='outlined'
      square
      data-itsUnlimited={!!hasUnlimited}
      style={{ height: `${title === 'Detalhes dos ingressos' ? '100%' : ''}` }}
    >
      <div className={classes.header}>
        <Icon className={classes.icon} />
        <h5>{title}</h5>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={closeTooltip}
          onOpen={openTooltip}
          open={tooltipOpen}
          title={tooltipText}
          placement={'bottom'}
          arrow
        >
          <QuestionCircleOutlined onClick={toggleTooltip} id={`${slug}-help`} />
        </Tooltip>
      </div>

      <div className={classes.details}>
        {data.map(item => {
          return (
            <React.Fragment key={item.name}>
              <div key={item.name} className={classes.indicatorDetail}>
                <div className={classes.bullet}></div>
                <span className={classes.itemValue}>
                  {toInteger(item.value) > 1000000 ? infinitySymbol : item.value}&nbsp;
                </span>
                {item.name}
              </div>
              {!!item.items &&
                item.items.map(itemItem => {
                  return (
                    <div key={itemItem.name} className={classes.indicatorDetailItem}>
                      <div className={classes.bullet}></div>
                      <span className={classes.itemValue}>{itemItem.value + ' '}</span>
                      {itemItem.name}
                    </div>
                  );
                })}
            </React.Fragment>
          );
        })}
      </div>
      {externalUrl && (
        <div className={classes.extra}>
          <a href={externalUrl.link} target='_blank' rel='noreferrer'>
            {externalUrl.text}
          </a>
        </div>
      )}
    </Paper>
  );
};

export default withStyles(styles)(Indicator);
