import { MyEventsRoutes } from './types';

const myEventsRoutes: MyEventsRoutes[] = [
  {
    path: '/my-events',
    import: 'Admin/MyEvents'
  },
  {
    path: '/my-events/:id/edit',
    import: 'Admin/EventEdit'
  }
];

export default myEventsRoutes;
