import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import {
  notification,
  Modal,
  Typography,
  Divider,
  Button,
  Form,
  Row,
  Col,
  Switch,
  Input as InputAntd,
  InputNumber,
  Select
} from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import Field from 'components/Shared/Field';
import { BrazilIcon } from 'components/Shared/Icons';
import Input from 'components/Shared/Input';
import { cleanString } from 'helpers/functions';
import { cpfMask, phoneMask, stripPhoneMask } from 'helpers/masks';
import { IParticipantInfo } from 'interfaces/participant';
import attendanceListService from 'services/attendanceList';
import { InferType } from 'yup';

import validationSchema from './schema';
import { EventDetailContext } from '../../../HeaderList';

type Form = InferType<typeof validationSchema>;

type IndividualDialogProps = {
  open: boolean;
  onClose: () => void;
};

const centerCol = { display: 'flex', alignItems: 'center', gap: '8px', marginTop: '8px' };

const IndividualDialog = ({ open, onClose }: IndividualDialogProps) => {
  const form = useForm({
    defaultValues: {
      ddi: 55,
      is_international_doc: false,
      ticket_id: undefined,
      name: '',
      phone: '',
      email: '',
      document: ''
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const ddi = form.watch('ddi');
  const ticketSelected = form.watch('ticket_id');
  const is_international_doc = form.watch('is_international_doc');

  const { eventDetail, getAvailableStock, getCurrentTicketName, addedParticipant } =
    React.useContext(EventDetailContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const availableStock = getAvailableStock(ticketSelected);
  const currentTicket = getCurrentTicketName(ticketSelected);

  const handlePasteDocument = e => {
    const document = e.clipboardData.getData('Text');
    form.setValue('document', cpfMask(cleanString(document)));
    form.trigger('document');
  };

  const handlePastePhone = e => {
    const document = e.clipboardData.getData('Text');
    form.setValue('phone', phoneMask(cleanString(document)));
    form.trigger('phone');
  };

  const onSubmit = async ({ ddi, name, email, document, is_international_doc, phone, ticket_id }: Form) => {
    const payload: IParticipantInfo = {
      name,
      email: email.toLowerCase(),
      phone: `+${ddi} ${stripPhoneMask(phone)}`,
      document: cleanString(document),
      ticket_id,
      document_type: is_international_doc ? 'passport' : 'cpf',
      event_id: eventDetail.id,
      use_stock: true
    };

    setIsSubmitting(true);
    attendanceListService.addParticipantManually(payload).subscribe({
      next: ({ data }) => {
        setIsSubmitting(false);

        if (data[0]?.error) {
          notification.error({ message: data[0].error.msg });
          return;
        }

        notification.success({ message: 'Participante adicionado!' });
        onClose();
        addedParticipant();
      },
      error: ({ data: err }) => {
        setIsSubmitting(false);
        let errorMsg = 'Ocorreu um erro ao adicionar o participante. Tente novamente depois';

        if (err?.code === 'ERR_CUSTOM') {
          errorMsg = err.details;
        }

        notification.error({ message: errorMsg });
      }
    });
  };

  const options = eventDetail?.tickets?.map(ticket => ({
    value: ticket.id,
    label: ticket.name
  }));

  const handleChangeCpf = e => {
    form.setValue('document', cpfMask(e.target.value));
    form.trigger('document');
  };

  const handleChangePhone = e => {
    form.setValue('phone', phoneMask(e.target.value));
  };

  return (
    <Modal
      zIndex={4000}
      open={open}
      onCancel={onClose}
      width={800}
      centered
      title={
        <>
          <Typography.Title level={4}>Adicionar Participante</Typography.Title>
          <Divider />
        </>
      }
      footer={[
        <React.Fragment key='primary'>
          <Divider />
          <Button
            loading={isSubmitting}
            type='primary'
            id='add-individual-btn'
            htmlType='submit'
            size='large'
            onClick={form.handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Aguarde...' : 'Concluir'}
          </Button>
        </React.Fragment>
      ]}
    >
      <FormProvider {...form}>
        <Form>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Input legend='Nome' placeholder='Nome do Participante' name='name' key='participant-name-input' />
            </Col>
            <Col md={12} xs={24}>
              <Input legend='E-mail' placeholder='Ex: fulano@eduzz.com' name='email' key='participant-email-input' />
            </Col>
            <Col md={12} xs={24}>
              <Typography.Text>{is_international_doc ? 'DOC' : 'CPF'}</Typography.Text>
              {is_international_doc && (
                <Field
                  name='document'
                  render={
                    <InputAntd
                      size='large'
                      placeholder='Ex.: 999.888.777-66'
                      maxLength={20}
                      key='participant-doc-input'
                    />
                  }
                />
              )}
              {!is_international_doc && (
                <Field
                  name='document'
                  onChange={e => handleChangeCpf(e)}
                  render={
                    <InputAntd
                      size='large'
                      placeholder='Ex.: 999.888.777-66'
                      maxLength={14}
                      key='participant-cpf-input'
                      onPaste={handlePasteDocument}
                    />
                  }
                />
              )}
            </Col>
            <Col md={12} xs={24} style={centerCol}>
              <Field name='is_international_doc' render={<Switch />} />
              <Typography.Text>Documento internacional</Typography.Text>
            </Col>
            <Col md={12} xs={24}>
              <Typography.Text>Telefone</Typography.Text>
              <div style={{ display: 'flex' }}>
                <div tabIndex={-1} style={{ flexBasis: '35%' }}>
                  <Field
                    name='ddi'
                    tabIndex={-1}
                    render={
                      <InputNumber
                        controls={false}
                        prefix={
                          ddi === 55 ? (
                            <>
                              <BrazilIcon />
                              <span>+</span>
                            </>
                          ) : (
                            <span>+</span>
                          )
                        }
                        size='large'
                        placeholder='DDI'
                        maxLength={4}
                      />
                    }
                  />
                </div>

                <div style={{ width: '100%' }}>
                  <Field
                    name='phone'
                    onChange={e => handleChangePhone(e)}
                    render={
                      ddi !== 55 ? (
                        <InputAntd size='large' placeholder='Nº do telefone' maxLength={20} />
                      ) : (
                        <InputAntd
                          size='large'
                          placeholder='Código DDD + Nº do telefone'
                          maxLength={15}
                          onPaste={handlePastePhone}
                        />
                      )
                    }
                  />
                </div>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <Typography.Text>Tipo de Ingresso</Typography.Text>
              <Field
                name='ticket_id'
                key='ticket-type-input'
                render={
                  <Select
                    placeholder='Selecione o tipo de ingresso'
                    size='large'
                    style={{ display: 'block' }}
                    getPopupContainer={node => node.parentNode}
                    options={options}
                  />
                }
              />
            </Col>
            {ticketSelected && (
              <Col md={24} xs={24}>
                <Typography.Text>
                  Seu evento possui <b>{availableStock}</b> ingressos do tipo <b>{currentTicket}</b> disponíveis em
                  estoque.
                </Typography.Text>
              </Col>
            )}
            <Col md={24} xs={24}>
              <Typography.Text>
                Participantes adicionados manualmente receberão ingressos do <b>lote vigente</b> da categoria de
                ingresso selecionada.
              </Typography.Text>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default IndividualDialog;
