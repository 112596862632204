import { EventRoutes } from './types';

const eventRoutes: EventRoutes[] = [
  {
    path: '/event/create/:type/',
    import: 'Events/Create',
    newDirectory: true
  },
  {
    path: '/event/edit/:id',
    import: 'Events/Edit',
    newDirectory: true
  },
  {
    path: '/event-duplicate/:id/',
    import: 'Events/Duplicate',
    newDirectory: true
  },
  {
    path: '/event/edit_by_cnt_cod/:cntCod',
    import: 'Events/EditByCntCod',
    newDirectory: true
  }
];

export default eventRoutes;
