import React, { useState } from 'react';

import { Button, Modal, notification, Typography } from 'antd';

import attendanceListService from 'services/attendanceList';

interface IProps {
  open: boolean;
  markerId: string;
  markerTitle: string;
  handleClose: () => void;
  onComplete: () => void;
}

export default function ArchiveMarker({ open, handleClose, markerId, markerTitle, onComplete }: IProps) {
  const [loading, setLoading] = useState(false);

  const confirmArchiveMarker = () => {
    setLoading(true);
    attendanceListService.archiveMarker(markerId).subscribe(
      () => {
        setLoading(false);
        notification.success({ message: 'Marcador removido com sucesso' });
        onComplete();
      },
      ({ data: err }) => {
        setLoading(false);

        if (err.message === 'Você não possui permissão para realizar esta ação') {
          notification.error({ message: err.message });
        } else {
          notification.error({ message: 'Houve um erro ao remover o marcador. Tente novamente' });
        }
      }
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='Remover Marcador'
      closable={false}
      footer={
        <div style={{ marginTop: '20px' }}>
          <Button
            id='participants-removemarkerdialog-cancelbutton'
            disabled={loading}
            onClick={handleClose}
            style={{ marginRight: '10px' }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            type='primary'
            id='participants-removemarkerdialog-confirmbutton'
            disabled={loading}
            onClick={() => confirmArchiveMarker()}
          >
            Confirmar
          </Button>
        </div>
      }
    >
      <Typography.Text>
        O marcador <b>{markerTitle}</b> será excluído e removido de todos que o possuem. Deseja continuar?
      </Typography.Text>
    </Modal>
  );
}
