import React, { useState, useEffect, useMemo } from 'react';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import LinkOutlined from '@ant-design/icons/LinkOutlined';
import { Button, Col, Divider, Row, Table, Typography, Grid } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { isBefore } from 'date-fns/isBefore';
import { dateFormat } from 'formatters/date';

import { OffersTicketsProps } from './types';

const OffersTickets = ({ items, handleRemoveOffer, handleGenerateLinkOffer, ...props }: OffersTicketsProps) => {
  const fixedValue = (value: string): string => {
    return String(parseFloat(value).toFixed(2)).replace('.', ',');
  };

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [screenSize, setScreenSize] = useState('');

  const totalValue = useMemo(() => {
    const value = items
      .reduce((acc: number, cur) => {
        return acc + parseFloat(cur.quantity) * parseFloat(cur.value);
      }, 0)
      .toFixed(2);
    return fixedValue(value);
  }, [items]);

  const tableData = items.map(item => {
    const isLotExpired = isBefore(item.end_sale, new Date());
    const isUnlimited = item.updated_stock >= 999999;
    const stock = () => {
      if (isLotExpired) return 'Lote fora do período de vendas';
      if (isUnlimited) return 'Ilimitado';
      return item.updated_stock;
    };

    return {
      key: item.id,
      ticketName: item.ticket_name,
      quantity: item.quantity,
      stock: stock(),
      created: dateFormat(item.created, 'dd/MM/yyyy'),
      value: `R$ ${item.value}`
    };
  });

  const columnsDesktop: ColumnsType<any> = [
    {
      title: 'Ingresso',
      dataIndex: 'ticketName',
      key: 'name'
    },
    {
      title: 'Qtd. ofertada',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Qtd. disponível',
      dataIndex: 'stock',
      key: 'stock'
    },
    {
      title: 'Data de criação',
      dataIndex: 'created',
      key: 'created'
    },
    {
      title: 'Valor unitário',
      dataIndex: 'value',
      key: 'value',
      align: 'right'
    }
  ];

  const columnsMobile: ColumnsType<any> = [
    {
      title: 'Ingresso',
      dataIndex: 'ticketName',
      key: 'name'
    },
    {
      title: 'Valor unitário',
      dataIndex: 'value',
      key: 'value',
      align: 'right'
    }
  ];

  useEffect(() => {
    if (screens.md) {
      setScreenSize('large');
      return;
    }

    setScreenSize('small');
  }, [screens]);

  return (
    <React.Fragment>
      <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
        <Col span={24}>
          <Typography.Title level={5}>{props.title}</Typography.Title>
        </Col>
        {screenSize === 'large' && (
          <Col span={24}>
            <Table dataSource={tableData} columns={columnsDesktop} pagination={false} />
          </Col>
        )}
        {screenSize === 'small' && (
          <Col span={24}>
            <Table
              dataSource={tableData}
              columns={columnsMobile}
              expandable={{
                expandedRowRender: data => {
                  return (
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <Typography.Text strong>Qtd. ofertada: </Typography.Text>
                        {data.quantity}
                      </Col>
                      <Col span={24}>
                        <Typography.Text strong>Qtd. disponível: </Typography.Text>
                        {data.stock}
                      </Col>
                      <Col span={24}>
                        <Typography.Text strong>Data de criação: </Typography.Text>
                        {data.created}
                      </Col>
                    </Row>
                  );
                }
              }}
              pagination={false}
            />
          </Col>
        )}
        <Col span={6}>
          <Button
            onClick={() => handleRemoveOffer({ eventId: props.event_id, offerPackId: props.id })}
            disabled={props.loadingLink || props.disableButton}
            loading={props.loadingDelete}
          >
            <DeleteOutlined />
            {screenSize === 'large' && (props.loadingDelete ? 'Excluindo...' : ' Excluir oferta')}
          </Button>
        </Col>
        <Col flex={'auto'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography.Text style={{ marginRight: '15px' }}>Valor total: R$ {totalValue}</Typography.Text>
          <Button
            disabled={props.loadingLink || props.disableButton}
            loading={props.loadingLink}
            onClick={() => handleGenerateLinkOffer(props.id)}
          >
            <LinkOutlined />
            {screenSize === 'large' && (props.loadingLink ? 'Gerando...' : 'Gerar link')}
          </Button>
        </Col>
      </Row>
      <Divider />
    </React.Fragment>
  );
};
export default OffersTickets;
