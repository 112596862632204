import createStyles from '@mui/styles/createStyles';
import { setMargin } from 'helpers/functions';

const styles = theme =>
  createStyles({
    content: {},
    drawer: {
      width: 500
    },
    drawerHeader: {
      height: 80,
      backgroundColor: theme.palette.primary.contrastText,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    drawerContent: {
      padding: 16
    },
    chipArea: {
      border: '1px dashed #ccc',
      borderRadius: 8,
      height: 160,
      padding: 16
    },
    button: {
      background: theme.palette.primary.main,
      color: '#000000',
      width: 160
    },
    buttonSubmit: {
      background: theme.palette.primary.main,
      color: '#000000',
      width: 120
    },
    buttonClean: {
      textTransform: 'initial',
      color: '#7f7f7f',
      fontSize: '1.1em',
      ...setMargin(null, 16, null, null)
    },
    chip: {
      padding: 16,
      fontSize: '.9rem'
    },
    searchInputClient: {
      padding: '0px 7px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: '5px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #E0E2F8'
    },
    searchInputClientInputText: {
      flex: 1,
      border: 'none',
      margin: '0'
    },
    selectFilter: {
      color: 'black'
    },
    paperArea: {
      padding: 12,
      marginBottom: 24
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      ...setMargin(16, null, null, null)
    }
  });

export default styles;
