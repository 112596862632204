import { AxiosResponse } from 'axios';
import { IGenerateTagsModel, ListOfAttendanceList } from 'components/Pages/Admin/AttendanceList/types';
import { IParticipantInfo } from 'interfaces/participant';
import { ISheetInfo } from 'interfaces/sheet';
import { ITags } from 'interfaces/tags';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';

import apiService, { ApiService } from '../api';
import { AttendancePayload, AttendancesDetailByTransactionKeyAndEmail, ListOfNewAttendanceList } from './types';

export class AttendanceListService {
  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }
  public handleErrorMessage(err: any) {
    return this.apiService.handleErrorMessage(err);
  }
  public listTags(): Rx.Observable<any> {
    return this.apiService.get('/attendance_tags/list').pipe(RxOp.map(response => response.data));
  }

  public addTag(data: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_tags/create_tags', data);
  }

  public linkTag(data: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_tags/link_by_invite', data);
  }

  public linkTagMultiple(data: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_tags/link/bulk', data);
  }

  public editTag(data: Partial<ITags>): Rx.Observable<any> {
    return this.apiService.put(`/attendance_tags/edit/${data.id}`, data);
  }

  public getInviteTags(inviteId: string): Rx.Observable<any> {
    return this.apiService.get(`/attendance_tags/${inviteId}`);
  }

  public list(data?: any): Rx.Observable<any> {
    const filter = {
      ...data,
      only_first_last_name: true
    };

    return this.apiService.post(`attendance_list/list_by_event/${filter.event_id}`, filter).pipe(
      RxOp.map(response => {
        if (!response?.data?.[0]?.data) {
          return {
            data: [],
            current_page: response?.data?.[0]?.paginator?.page,
            last_page: response?.data?.[0]?.paginator?.totalPages,
            per_page: response?.data?.[0]?.paginator?.size,
            total: response?.data?.[0]?.paginator?.totalRows
          };
        }

        return {
          data: response.data[0].data.map(item => ({
            attendances: [item],
            total: 1
          })),
          current_page: response.data[0].paginator.page,
          last_page: response.data[0].paginator.totalPages,
          per_page: response.data[0].paginator.size,
          total: response.data[0].paginator.totalRows
        };
      })
    );
  }

  public listAntigo(data?: any): Rx.Observable<ListOfNewAttendanceList> {
    const filter = {
      ...data,
      only_first_last_name: true
    };

    const page = data.page ?? 1;
    const pagesize = data.pagesize ?? 25;

    return this.apiService
      .post(`event/${data.event_id}/attendances/list-grouped/?page=${page}&size=${pagesize}`, filter)
      .pipe(RxOp.map(response => response.data));
  }

  public listByEvent(data?: any): Rx.Observable<ListOfAttendanceList> {
    data.page = data.page ?? 1;
    data.pagesize = data.pagesize ?? 25;

    return this.apiService
      .post(`/attendance_list/list_by_event/${data.event_id}`, data)
      .pipe(RxOp.map(response => response.data[0]));
  }

  public checkinAttendances(data: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_list/checkin_attendances', data);
  }

  public editByInviteKey(data: any): Rx.Observable<any> {
    return this.apiService.post(`/attendance_list/edit_by_invite/${data.id}`, data);
  }

  public resendTicket(data?: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_list/send_ticket_again', data);
  }

  public resendTicketMultiple(data?: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_list/resend_tickets_bulk', data);
  }

  public sendEmailToParticipants(data?: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_list/send_email_to_attendances', data);
  }

  public exportPDF(data?: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_list/export_pdf', data).pipe(RxOp.map(response => response));
  }

  public generateTags(data?: any): Rx.Observable<any> {
    return this.apiService
      .post(`/attendance_tags/generate_tags/${data.eventId}`, data)
      .pipe(RxOp.map(response => response));
  }

  public exportXLS(data?: any): Rx.Observable<any> {
    return this.apiService.post('/attendance_list/export_xls', data).pipe(RxOp.map(response => response));
  }

  public attendanceDetailByEvent(eventId: string, cliCod: string): Rx.Observable<any> {
    return this.apiService
      .get(`/attendance_list/attendance_detail_by_event/${eventId}/${cliCod}`)
      .pipe(RxOp.map(response => response.data));
  }

  public detail(attendanceId: string): Rx.Observable<any> {
    return this.apiService.get(`/attendance_list/detail/${attendanceId}`).pipe(RxOp.map(response => response.data[0]));
  }

  public checkIn(attendanceId: string, active: number): Rx.Observable<any> {
    return this.apiService.get(`/presence/checkin/${attendanceId}/${active}`);
  }

  public presenceConfirm(hash: string): Rx.Observable<any> {
    return this.apiService.get(`/presence/confirm/${hash}`);
  }

  public checkInGetDetails(hash: string): Rx.Observable<any> {
    return this.apiService.get(`/presence/checkin_get_detail/${hash}`).pipe(RxOp.map(response => response.data[0]));
  }

  public archiveMarker(markerId: string): Rx.Observable<any> {
    return this.apiService.post('/attendance_tags/archive', {
      markerId
    });
  }

  public addParticipantManually(participantInfo: IParticipantInfo): Rx.Observable<any> {
    return this.apiService.post('/attendance_list/create_manual', participantInfo);
  }

  public importSheet(sheetInfo: ISheetInfo): Rx.Observable<any> {
    return this.apiService.post('/attendance_list/import_sheet', sheetInfo);
  }

  public uploadStatus(msqId: string): Rx.Observable<any> {
    return this.apiService.get(`/attendance_list/import_status/${msqId}`);
  }

  public removeAddedParticipant(inviteKeys: string[]): any {
    return this.apiService.delete('/attendance_list/delete_manually', { invite_keys: inviteKeys });
  }

  public checkGiftTicketToken(token: string): Rx.Observable<any> {
    return this.apiService.get(`/attendance_list/validate_transfer_link/${token}`);
  }

  public claimGiftTicket(token: string, data: any): Rx.Observable<any> {
    return this.apiService.post(`/attendance_list/edit_by_link/${token}`, data);
  }

  public attendancesDetailByTransactionKeyAndEmail(
    transaction_key: string,
    email: string
  ): Rx.Observable<AttendancesDetailByTransactionKeyAndEmail> {
    return this.apiService
      .get(`public/attendances_detail/${transaction_key}/${encodeURIComponent(email)}`)
      .pipe(RxOp.map(response => response.data));
  }

  public getGenerateTagsModel() {
    return this.apiService.get<AxiosResponse<IGenerateTagsModel | null>>('/attendance_tags/tags_model');
  }

  public attendancePrinted(transactionKey: string, email: string, attendanceIds: string[]) {
    return this.apiService.put('/public/attendance_printed', {
      attendanceIds,
      transactionKey,
      email
    });
  }

  public attendanceAssign(attendances: AttendancePayload[]) {
    return this.apiService.put('public/attendances_assign', {
      attendances
    });
  }
}

const attendanceListService = new AttendanceListService(apiService);
export default attendanceListService;
