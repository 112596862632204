import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Col, Modal, Row, Typography } from 'antd';

import { compareAsc } from 'date-fns/compareAsc';
import { format } from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import { DateDetail } from 'interfaces/event';

interface EventDatesModalProps {
  eventDates: DateDetail[];
  open: boolean;
  onClose: () => void;
}

const EventDatesModal = ({ eventDates, open, onClose }: EventDatesModalProps) => {
  const eventDatedOrdered = (): DateDetail[] => {
    const orderFields = [...eventDates];

    orderFields.sort((a, b) => compareAsc(a.date, b.date));

    return orderFields;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Datas e horários'
      closeIcon={<CloseOutlined onClick={onClose} />}
      footer={null}
    >
      <Row gutter={[8, 8]}>
        {eventDatedOrdered().map(eventDate => {
          return (
            <Col span={24} key={eventDate.identificator}>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <CalendarOutlined style={{ marginRight: '6px' }} />
                  <Typography.Text>
                    {format(eventDate.date, 'dd')} de {format(eventDate.date, 'MMMM', { locale: ptBR })}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <ClockCircleOutlined style={{ marginRight: '6px' }} />
                  <Typography.Text>
                    {format(new Date(eventDate.start_time), 'HH:mm')} às {format(new Date(eventDate.end_time), 'HH:mm')}{' '}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Modal>
  );
};

export default EventDatesModal;
