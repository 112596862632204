import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEventDetail } from 'components/Pages/Admin/TabsMyEvents/context/EventDetail';
import CustomLoader from 'components/Shared/CustomLoader';
import Tour from 'components/Shared/Tour';
import { getTargetElement } from 'components/Shared/Tour/helpers';
import useCustomObservable from 'hooks/useCustomObservable';
import useTabTour from 'hooks/useTabTour';
import { EventPanelStatistics } from 'interfaces/eventStatics';
import ReactPlaceholder from 'react-placeholder/lib';
import { titlePageService } from 'services/routes';

import Dashboard from './components/Dashboard';
import eventPanelService from './service';
import * as S from './styles';
import { PageParams } from '../BasicData/types';
import PageTitle from '../components/PageTitle';

const steps = [
  {
    title: 'Painel do evento',
    description:
      'Verifique suas vendas separadas por ingresso e lote, conferindo quantos ainda estão disponíveis para a venda. Os ingressos adicionados manualmente são inseridos na aba "Participantes".',
    target: getTargetElement("div[data-node-key='event-panel']")
  },
  {
    title: 'Painel do evento',
    description:
      'O círculos com "?" possuem informações importantes sobre a quantidade de ingressos, passe o mouse em cada um deles para ver mais detalhes.',
    target: getTargetElement('svg#ingressos-help'),
    isLastStep: true
  }
];

const EventPanel = () => {
  const { id: eventId } = useParams<PageParams>();
  const { showTour, openTour, closeTour } = useTabTour('event-panel');

  const { data: eventPanelData, loading } = useEventDetail();

  const { value: statisticsData, loading: loadingStatistics } = useCustomObservable<
    EventPanelStatistics,
    string,
    string
  >(() => eventPanelService.getEventStatistics(eventId), eventId);

  const isShownPage = useMemo(
    () => !loading && !!eventPanelData && !loadingStatistics,
    [loading, eventPanelData, loadingStatistics]
  );

  useEffect(() => {
    titlePageService.set(
      ['Meus Eventos'],
      [
        { label: 'Meus Eventos', path: '/my-events' },
        { label: eventPanelData?.eventHeader.title, path: `/my-events/${eventId}/basic-data` },
        { label: 'Painel do evento', path: `/my-events/${eventId}/event-panel` }
      ],
      { label: 'Dados básicos', path: `/my-events/${eventId}/basic-data` }
    );
  }, [eventPanelData, eventId]);

  return (
    <S.Container>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={!loading}>
        {isShownPage && (
          <>
            <PageTitle
              title='Painel do evento'
              subtitle='Acompanhe suas vendas separadas por ingresso e lote'
              onIconClick={openTour}
            />
            <Dashboard
              statisticsData={statisticsData?.data[0]}
              eventData={eventPanelData.event}
              cacheTime={statisticsData.cache}
            />
          </>
        )}
      </ReactPlaceholder>
      {isShownPage && <Tour steps={steps} open={showTour} onClose={closeTour} />}
    </S.Container>
  );
};
export default EventPanel;
