import { notification } from 'antd';

export const errorMessage = {
  generic: (complement?: string) => `Ocorreu um erro ${complement || ''}. Tente novamente`
};

export default function errorHandler(err: any, message?: string) {
  if (err.message === 'Você não possui permissão para realizar esta ação') {
    notification.error({ message: err.message });
  } else if (err?.code === 'ERR_CUSTOM') {
    notification.error({ message: err.details });
  } else if (message || err.message) {
    notification.error({ message: message ?? err.message });
  }
}
