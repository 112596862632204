import { isMobile } from 'react-device-detect';

import { CalendarOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Row, Space, Typography } from 'antd';

import { compareAsc } from 'date-fns/compareAsc';
import { format } from 'date-fns/format';
import { getDate } from 'date-fns/getDate';
import { ptBR } from 'date-fns/locale/pt-BR';
import { DateDetail } from 'interfaces/event';

const menuItemStyle = {
  margin: 0,
  padding: 0
};

interface EventDatesProps {
  fields: DateDetail[];
  onDelete: (arrayId: string, id?: string) => void;
  onDuplicate: (eventDate: DateDetail) => void;
  onEdit: (eventDate: DateDetail) => void;
}

const EventDates = ({ fields, onDelete, onDuplicate, onEdit }: EventDatesProps) => {
  const orderFields = (): DateDetail[] => {
    const orderFields = [...fields];

    orderFields.sort((a, b) => compareAsc(a.date, b.date));

    return orderFields;
  };

  return (
    <Row gutter={[10, 10]}>
      {orderFields().map((field, index) => {
        const date = format(field.date, 'dd/MM/yyyy', { locale: ptBR });
        const dayOfWeek = format(field.date, 'EEEE', { locale: ptBR });
        const day = getDate(field.date);
        const month = format(field.date, 'LLLL', { locale: ptBR });
        const year = format(field.date, 'yyyy', { locale: ptBR });
        const startTime = format(new Date(field.start_time), 'HH:mm', { locale: ptBR });
        const endTime = format(new Date(field.end_time), 'HH:mm', { locale: ptBR });

        return (
          <Col
            span={24}
            key={field.id}
            style={{
              display: 'flex',
              border: '1px solid #d9d9d9',
              padding: '5px',
              margin: '10px 0',
              justifyContent: 'space-between'
            }}
          >
            <Space direction='horizontal'>
              <CalendarOutlined style={{ fontSize: '1rem', margin: '0 5px' }} />
              <Typography.Text strong style={{ minWidth: '50px' }}>
                Dia {index + 1}:
              </Typography.Text>
              {!isMobile && (
                <Typography.Text>{`${dayOfWeek}, ${day} de ${month} de ${year} ás ${startTime} até ${endTime}`}</Typography.Text>
              )}
              {isMobile && (
                <>
                  <Col span={24}>
                    <Typography.Text>{date}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text>{`${startTime} até ${endTime}`}</Typography.Text>
                  </Col>
                </>
              )}
            </Space>
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <Button type='text' onClick={() => onEdit(field)} block>
                        Editar
                      </Button>
                    ),
                    key: `${field.id}-menu-item-1`,
                    style: menuItemStyle
                  },
                  {
                    label: (
                      <Button type='text' onClick={() => onDuplicate(field)} block>
                        Duplicar
                      </Button>
                    ),
                    key: `${field.id}-menu-item-2`,
                    style: menuItemStyle
                  },
                  {
                    label: (
                      <Button type='text' onClick={() => onDelete(field.arrayId, field.id)} block>
                        Excluir
                      </Button>
                    ),
                    key: `${field.id}-menu-item-3`,
                    danger: true,
                    style: menuItemStyle
                  }
                ],
                selectable: true
              }}
              placement='bottomRight'
              key={`${field.id}-dropdown`}
            >
              <Button
                type='text'
                icon={<MoreOutlined rotate={90} style={{ fontSize: '25px' }} />}
                key={`${field.id}-more-icon`}
              />
            </Dropdown>
          </Col>
        );
      })}
    </Row>
  );
};

export default EventDates;
