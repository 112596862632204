import React, { PureComponent } from 'react';

import withStyles from '@mui/styles/withStyles';

import styles from './styles';

type Props = {
  children: React.ReactNode;
  classes?: any;
};

class Table extends PureComponent<Props> {
  public render(): JSX.Element {
    const { classes, children } = this.props;

    return (
      <>
        <table className={classes.table} cellSpacing={0} cellPadding={0}>
          {children}
        </table>
      </>
    );
  }
}

export default withStyles(styles)(Table);
