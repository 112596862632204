import React, { FC, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';

import { hjTagging } from 'helpers/functions';
import { ITags } from 'interfaces/tags';
import { v4 } from 'uuid';

import MarkerEditDialogItem from './Item';
import { IProps } from './types';

const MarkerEditDialog: FC<IProps> = ({ opened, markers, onComplete, onCancel, onMarkerRemoved, onCompleteEdit }) => {
  hjTagging('page:presencelist-markermanager');
  const markerDetails: ITags = {
    id: v4(),
    title: '',
    color: '',
    isNew: false
  };

  const [model] = useState({
    ...markerDetails,
    color: '#000000'
  });
  const [marker, setMarker] = useState<ITags[]>([...markers]);

  function handleMarkerRemoved(tag: ITags) {
    const newMarkers = marker.filter(item => item.id !== tag.id);
    setMarker(newMarkers);

    if (!tag.isNew) {
      onMarkerRemoved(tag);
    }
  }

  function onCompleteAddMarker(data: ITags) {
    const newMarkers = marker.map(item => {
      let newItem = { ...item };

      if (item.id === data.id_temp) {
        newItem = { ...data };
        delete newItem.isNew;
        delete newItem.id_temp;
      }

      return { ...newItem };
    });
    setMarker(newMarkers);
    onComplete();
  }

  function addMarker() {
    const newMarker = { ...markerDetails };
    newMarker.id = v4();
    newMarker.title = model.title;
    newMarker.color = model.color;
    newMarker.isNew = true;

    setMarker([{ ...newMarker }, ...marker]);
  }

  return (
    <Modal
      title='Gerenciar Marcadores'
      closeIcon={<CloseOutlined onClick={onCancel} />}
      open={opened}
      footer={
        <Button type='primary' id='markerdialog-addmarker-button' onClick={addMarker}>
          Adicionar
        </Button>
      }
      styles={{ body: { overflowY: 'auto', maxHeight: 'calc(100vh - 250px)', paddingRight: '15px' } }}
    >
      <Row>
        <Col span={24}>
          {marker.length > 0 &&
            marker.map(item => (
              <MarkerEditDialogItem
                key={item.id}
                marker={item}
                onCompleteEdit={onCompleteEdit}
                onComplete={onCompleteAddMarker}
                onMarkerRemoved={handleMarkerRemoved}
              />
            ))}

          {!marker.length && (
            <>
              <p>
                <strong>Nenhum item cadastrado</strong>
              </p>
              <p>Adicione um marcador abaixo e classifique seus participantes de maneira fácil!</p>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default MarkerEditDialog;
