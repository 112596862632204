import apiService from 'services/api';

export class NpsService {
  private readonly SITE_ID = 1004096;

  constructor(private readonly apiService) {}

  public async getSurveyLastAnswer(surveyId: number) {
    return this.apiService
      .get('/nps/last-answer', {
        site_id: this.SITE_ID,
        survey_id: surveyId
      })
      .toPromise();
  }
}

export const npsService = new NpsService(apiService);
