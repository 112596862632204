import createStyles from '@mui/styles/createStyles';
import { setMargin, setPadding } from 'helpers/functions';
const styles = () =>
  createStyles({
    formFontSizeGridItem: {
      ...setPadding(null, 8, null, null),
      textAlign: 'left',
      flexDirection: 'column'
    },
    formHeader: {
      fontWeight: 600,
      ...setMargin(null, null, 8, null)
    },
    selectLabel: {
      ...setMargin(8, null, 4, null),
      display: 'block'
    },
    selectList: {
      width: '105px',
      textAlign: 'center'
    }
  });

export default styles;
