import { Button } from 'antd';

import Typography from '@mui/material/Typography';
import styled, { CSSProperties } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div``;

export const CustomizedTypography = styled(Typography)<CSSProperties>`
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

export const Title = styled.h6`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 10px;
  ${media.greaterThan('medium')`
    font-size: 1.25rem;
  `}
`;

export const Subtitle = styled.h6`
  color: #000000;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 10px;
  ${media.greaterThan('medium')`
    font-size: 1rem;
  `}
`;

export const PanelHeader = styled.div`
  display: flex;
  align-content: space-between;
  width: 100%;
`;

export const TicketTitle = styled.h6`
  margin-top: 4px;
  font-weight: 600;
  margin-right: 5px;
`;

export const MenuButton = styled(Button)`
  margin: 0px;
  padding: 0px;
  border: none;
  font-weight: 600;
`;
