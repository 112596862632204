import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import {
  PushpinOutlined,
  EditOutlined,
  MailOutlined,
  PrinterOutlined,
  TagOutlined,
  UserDeleteOutlined,
  UserOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';
import { Modal, notification, Space, Typography } from 'antd';

import Checkbox from '@mui/material/Checkbox';
import TableCol from 'components/Shared/Table/components/TableCol';
import TableRow from 'components/Shared/Table/components/TableRow';
import { TicketIcon } from 'helpers/customIcons';
import { hjTagging, whatsappBrNormalize } from 'helpers/functions';
import { IAttendaceList } from 'interfaces/attendanceList';
import attendanceListService from 'services/attendanceList';
import { BLINKET_TICKETS_APP_URL } from 'settings';

import CheckInButton from './CheckInButton';

interface IProps {
  item: IAttendaceList;
  finishedEvent: boolean;
  handleOpenModalResendTicket: (open: boolean, userEmail: string) => void;
  handleOpenModalLink: (open: boolean, client: IAttendaceList) => void;
  handleOpenModalEdit: (open: boolean, model: any) => void;
  handleOpenModalLabelManager: (open: boolean, model?: IAttendaceList[]) => void;
  handleOpenAttendanceDetail: (cliCod: number) => void;
  handleCheckinAttendances: (attendanceId: string, active: 0 | 1) => boolean;
  reloadData: any;
  updateSelectedParticipants: (participant: IAttendaceList, isSelected: boolean) => void;
  handleOpenModalSendEmailToParticipants?: (actionData: string) => void;
  selectedParticipants: IAttendaceList[];
  isEventLiveOnline: boolean;
  disableCheckboxes: boolean;
}

export default function PresenceListItem({
  item,
  handleOpenModalResendTicket,
  handleOpenModalEdit,
  handleOpenModalLink,
  handleOpenModalLabelManager,
  reloadData,
  finishedEvent,
  updateSelectedParticipants,
  selectedParticipants,
  handleOpenModalSendEmailToParticipants,
  isEventLiveOnline,
  disableCheckboxes
}: IProps) {
  let whatsappBaseUrl = 'https://web.whatsapp.com/';

  if (isMobile) {
    whatsappBaseUrl = 'whatsapp://';
  }

  item.whatsapp = whatsappBrNormalize(item.phone);

  const history = useHistory();
  const actions = [
    {
      callback: () => {
        hjTagging('page:presencelist-edit');
        handleOpenModalEdit(true, {
          id: item.invite_key,
          email: item.email,
          name: item.name,
          phone: item.phone,
          document: item.document,
          document_type: item.document_type,
          custom_fields: item.custom_fields,
          status: item.status
        });
      },
      icon: <EditOutlined />,
      label: 'Alterar',
      id: 'presencelist-participant-change-button'
    },
    {
      callback: () => {
        hjTagging('page:presencelist-resend-ticket');
        handleOpenModalResendTicket(true, item.email);
      },
      icon: TicketIcon(),
      label: !!isEventLiveOnline ? 'Reenviar acesso' : 'Reenviar ingresso',
      id: 'presencelist-participant-resend-button'
    },
    {
      callback: () => {
        hjTagging('page:presencelist-marker-assign');
        handleOpenModalLink(true, item);
      },
      icon: <PushpinOutlined />,
      label: 'Adicionar marcador',
      id: 'presencelist-participant-addmarker-button'
    },
    {
      callback: () => {
        hjTagging('page:presencelist-print-labels');
        handleOpenModalLabelManager(true, [item]);
      },
      icon: <TagOutlined />,
      label: 'Gerar etiqueta',
      id: 'presencelist-print-labels'
    },
    {
      callback: () => {
        history.push(
          {
            pathname: `/presence-list/${item.event_id}/participant/${item.cli_cod}`
          },
          { email: item.email }
        );
      },
      icon: <UserOutlined />,
      label: 'Detalhes do participante',
      id: 'presencelist-participant-details'
    }
  ];

  const finishedEventActions = [
    {
      callback: () => {
        history.push(
          {
            pathname: `/presence-list/${item.event_id}/participant/${item.cli_cod}`
          },
          { email: item.email }
        );
      },
      icon: <UserOutlined />,
      label: 'Detalhes do participante',
      id: 'presencelist-participant-details'
    }
  ];

  if (item.total === 1) {
    (finishedEvent ? finishedEventActions : actions).push({
      callback: () => {
        window.open(`${BLINKET_TICKETS_APP_URL}/participant/print/${item.event_id}/0/${item.id}`);
      },
      icon: <PrinterOutlined />,
      label: 'Imprimir ingresso',
      id: 'print-ticket'
    });
  }

  if (handleOpenModalSendEmailToParticipants) {
    actions.push({
      callback: () => {
        hjTagging('page:presencelist-participant-details');
        handleOpenModalSendEmailToParticipants(item.invite_key);
      },
      icon: <MailOutlined />,
      label: 'Enviar email',
      id: 'presencelist-participant-email-send'
    });
  }

  if (item.fat_cod === null)
    actions.push({
      callback: () => {
        Modal.confirm({
          title: (
            <Space>
              <span>Remover participante</span>
            </Space>
          ),
          content: (
            <Typography.Paragraph>
              Essa ação é <strong>irreversível</strong>. Ao remover este participante, ele não estará mais listado na
              lista e não será possível recuperá-lo.
              <br />
              <br />
              <strong>Nome do participante:</strong> {item.name}
              <br />
              <strong>Número do ingresso:</strong> {item.invite_key}
            </Typography.Paragraph>
          ),
          okText: 'Remover participante',
          okType: 'primary',
          cancelText: 'Cancelar',
          okButtonProps: {
            style: { backgroundColor: '#ffbc00', borderColor: '#ffbc00', color: '#000' },
            onFocus: e => (e.target.style.outline = '3px solid #ffe97a'),
            onBlur: e => (e.target.style.outline = 'none')
          },
          cancelButtonProps: {
            style: { backgroundColor: '#fff', borderColor: '#000', color: '#000' },
            onFocus: e => (e.target.style.outline = '3px solid #ffe97a'),
            onBlur: e => (e.target.style.outline = 'none')
          },
          onOk: () => {
            attendanceListService.removeAddedParticipant(item.invite_key.split(' ')).subscribe({
              next: () => {
                notification.success({ message: 'Participante removido com sucesso.' });
                reloadData();
              },
              error: (err: any) => {
                notification.error({
                  message:
                    err.message === 'Você não possui permissão para realizar esta ação'
                      ? err.message
                      : 'Ocorreu um erro ao remover o participante. Tente novamente'
                });
              }
            });
          }
        });
      },
      icon: <UserDeleteOutlined />,
      label: 'Remover participante',
      id: 'presencelist-removeparticipant-button'
    });

  if (!!item.phone && item.whatsapp.status === 'success') {
    (finishedEvent ? finishedEventActions : actions).push({
      callback: () => {
        hjTagging('page:presencelist-participant-whatsapp');
        window.open(whatsappBaseUrl + 'send?phone=' + item.whatsapp.value, '_blank');
      },
      icon: <WhatsAppOutlined />,
      label: 'Enviar mensagem',
      id: 'presencelist-participant-whatsapp'
    });
  }

  const valueAttendanceDocument =
    item.document === 'desconhecido' || !item.document ? 'Documento não informado' : item.document;

  const valueBuyerDocument =
    item.invoice_customer_document === 'desconhecido' || !item.invoice_customer_document
      ? 'Documento não informado'
      : item.invoice_customer_document;

  const valueBuyerName = (
    item.manually_created
      ? 'Adicionado manualmente'
      : item.from_squeeze
        ? 'Inscrito via live'
        : [item.invoice_customer_name, valueBuyerDocument]
  ) as any;

  return (
    <TableRow key={`attendance-${item.id}`}>
      <TableCol xs={{ size: 1 }} sm={{ size: 2 }} type='regular'>
        <Checkbox
          color='primary'
          disabled={disableCheckboxes}
          onClick={e => e.stopPropagation()}
          onChange={e => updateSelectedParticipants(item, e.target.checked)}
          checked={selectedParticipants.some(participant => participant.id === item.id) || disableCheckboxes}
          style={{ paddingLeft: 0 }}
        />
      </TableCol>
      <TableCol
        onClick={() => {
          history.push(
            {
              pathname: `/presence-list/${item.event_id}/participant/${item.cli_cod}`
            },
            { email: item.email }
          );
        }}
        xs={{ size: 3 }}
        sm={{ size: 4 }}
        type='participant'
        value={[item.name, valueAttendanceDocument]}
        max={45}
      />
      {!isMobile && <TableCol xs={{ size: 2 }} sm={{ size: 3 }} type='string' value={item.invite_key} max={20} />}
      <TableCol xs={{ size: 2 }} sm={{ size: 3 }} type='status-text' value={item.status_description} />
      {!isMobile && <TableCol xs={{ size: 3 }} sm={{ size: 3 }} type='string' value={item.ticket_name} />}
      {!isMobile && (
        <TableCol
          xs={{ size: 3 }}
          sm={{ size: 4 }}
          type={typeof valueBuyerName === 'string' ? 'string' : 'participant'}
          value={valueBuyerName}
        />
      )}
      {!isMobile && (
        <TableCol xs={{ size: 4 }} sm={{ size: 4 }} type='regular-center'>
          <CheckInButton checkInDate={item.checkin_date} itemId={item.id} finishedEvent={finishedEvent} />
        </TableCol>
      )}
      <TableCol
        xs={{ size: 2 }}
        sm={{ size: 2 }}
        type='actions'
        actions={finishedEvent ? finishedEventActions : actions}
        customStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      />
    </TableRow>
  );
}
