import * as Sentry from '@sentry/react';

import { User } from '../services/user/types';
import { ENV } from '../settings';

export class LogService {
  constructor() {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_KEY || '',
      environment: ENV,
      sampleRate: ENV === 'production' ? 0.25 : 0.1,
      release: 'e7672b1439'
    });
  }

  public setUser(user: User): void {
    if (!user) {
      Sentry.setUser({
        id: null,
        email: null,
        username: null,
        cli_cod: null,
        extra: {}
      });
      return;
    }

    Sentry.setUser({
      id: user.id.toString(),
      email: user.user_email,
      username: user.user_name,
      cli_cod: user.cli_cod,
      extra: { ...user }
    });
  }

  public handleError(err: any): void {
    if (!err) return;

    if (typeof err === 'string') {
      err = new Error(err);
    }

    Sentry.captureException(err);
  }
}

const logService = new LogService();
export default logService;
