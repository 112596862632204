import React from 'react';

import { Typography } from 'antd';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { IPrintFormatting } from 'interfaces/labelInfo';

interface IProps {
  classes?: any;
  data: IPrintFormatting;
  onChangePrintFormat: (data: string) => void;
  onChangeFirstLabel: (data: number) => void;
}

const { Text } = Typography;

const PrintFormatForm: React.FC<IProps> = ({ data, onChangePrintFormat, onChangeFirstLabel }) => {
  const labelCount = data.labelCount;

  return (
    <div>
      <FormControl size='small' sx={{ display: 'flex ' }}>
        <Text>Selecione o formato de impressão</Text>
        <Select
          value={data.printFormat}
          fullWidth
          onChange={(e: any) => {
            onChangePrintFormat(e.target.value);
          }}
        >
          <MenuItem value='6083'>6083</MenuItem>
          <MenuItem value='6281'>6281</MenuItem>
          <MenuItem value='A4263'>A4263</MenuItem>
          <MenuItem value='8099F'>8099F</MenuItem>
        </Select>
      </FormControl>

      <div style={{ paddingBottom: 16 }} />

      <FormControl size='small'>
        <InputLabel></InputLabel>
        <Text>Iniciar impressão a partir da célula número:</Text>
        <div style={{ display: 'block' }}>
          <Select value={data.firstLabel} onChange={(e: any) => onChangeFirstLabel(e.target.value)}>
            {Array.from(Array(20).keys()).map(i => {
              const value = i + 1;

              const disabled = value >= 15 && labelCount < 20 ? true : value >= 11 && value <= 14 ? true : false;

              return (
                <MenuItem key={value} value={value} disabled={disabled}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </FormControl>
    </div>
  );
};

export default PrintFormatForm;
