// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import React, { useMemo, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import newLogoBlinket from 'assets/images/new-blinket-logo.svg';
import useStyles from 'components/Pages/Admin/styles';
import useCustomObservable from 'hooks/useCustomObservable';
import useTabTour from 'hooks/useTabTour';
import { firstValueFrom } from 'rxjs';
import loginService from 'services/login';
import tokenService from 'services/token';
import userService from 'services/user/index';
import { User } from 'services/user/types';
import { WEBHOOK_URL } from 'settings';

import HoustonThemeProvider, { createTheme } from '@eduzz/houston-ui/ThemeProvider';
import Layout from '@eduzz/ui-layout';

const { Topbar, Content, Sidebar } = Layout;
const { Item, Group } = Sidebar;

interface PrivateLayoutProps {
  children: JSX.Element;
}

const houstonTheme = createTheme('orbita');

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  const location = useLocation();
  const { value: userData } = useCustomObservable<User, string, string>(() => userService.getUser(), null);

  const [isOpenAttendanceDetail, setIsOpenAttendanceDetail] = useState(false);
  const [isOpenAttendanceList, setIsOpenAttendanceList] = useState(true);
  const [cliCodSelected, setCliCodSelected] = useState(null);
  const classes = useStyles();
  const {
    showTour: showAttendanceListTour,
    openTour: handleOpenAttendanceListTour,
    closeTour: handleCloseAttendanceListTour
  } = useTabTour('presence-list');

  const handleLogout = () => {
    if (localStorage.getItem('isSupport')) {
      localStorage.removeItem('isSupport');
    }

    userService.logout();
  };

  const handleOpenAttendanceDetail = (cliCod: number) => {
    setIsOpenAttendanceDetail(false);
    setIsOpenAttendanceList(false);
    setCliCodSelected(null);

    setTimeout(() => {
      setCliCodSelected(cliCod);
      setIsOpenAttendanceDetail(true);
      setIsOpenAttendanceList(false);
      window.scrollTo({ top: 0 });
    }, 300);
  };

  const handleCloseAttendanceDetail = () => {
    setIsOpenAttendanceDetail(false);
    setIsOpenAttendanceList(true);
    window.onpopstate = null;
  };

  useEffect(() => {
    if (!userData) {
      loginService.auth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const privateRoutesWithAttendanceProp = useMemo(() => {
    return React.Children.map(children, child =>
      React.cloneElement(child, {
        attendanceProps: {
          isOpenAttendanceDetail,
          isOpenAttendanceList,
          showAttendanceListTour,
          cliCodSelected,
          handleOpenAttendanceDetail,
          handleCloseAttendanceDetail,
          handleOpenAttendanceListTour,
          handleCloseAttendanceListTour
        },
        classes
      })
    );
  }, [
    children,
    classes,
    cliCodSelected,
    handleCloseAttendanceListTour,
    handleOpenAttendanceListTour,
    isOpenAttendanceDetail,
    isOpenAttendanceList,
    showAttendanceListTour
  ]);

  //isso aqui vai contra tudo o que eu acredito ser correto mas eu fiz por causa dessa gambiarra absurda em cima
  window['attendanceProps'] = {
    attendanceProps: {
      isOpenAttendanceDetail,
      isOpenAttendanceList,
      showAttendanceListTour,
      cliCodSelected,
      handleOpenAttendanceDetail,
      handleCloseAttendanceDetail,
      handleOpenAttendanceListTour,
      handleCloseAttendanceListTour
    },
    classes
  };

  const loginByEmail = async (email: string) => {
    const logged = await firstValueFrom(userService.loginAsEmail(email));
    const { jwt_token: token, jwt_refresh_token: refresh } = logged;
    tokenService.setTokens({ token, refresh });
    window.location.assign(`${process.env.REACT_APP_BLINKET_APP_URL}/home`);
  };

  return (
    <HoustonThemeProvider theme={houstonTheme}>
      <Layout>
        <Topbar
          currentApplication='blinket'
          user={{
            name: userData?.user_name,
            belt: userData?.belt,
            avatar: userData?.photo
          }}
          logo={newLogoBlinket}
          id='eduzz-topbar-menu'
        >
          <Topbar.UserMenu id='eduzz-topbar-menu-user'>
            <Topbar.UserMenuItem
              id='eduzz-topbar-menu-shopping'
              as={NavLink}
              to={{
                pathname: `${process.env.REACT_APP_BLINKET_TICKETS_APP_URL}/?utm_source=blinket&utm_medium=produtor`
              }}
              target='_blank'
              rel='noopener noreferrer'
            >
              Eventos que comprei
            </Topbar.UserMenuItem>

            <>
              {!!userData?.acpo_users &&
                userData.acpo_users
                  .filter(otherUser => otherUser.email != userData.user_email)
                  .map(otherUser => (
                    <Topbar.UserMenuItem
                      id={`eduzz-topbar-menu-acpo-${encodeURIComponent(otherUser.email)}`}
                      key={otherUser.email}
                      onClick={() => loginByEmail(otherUser.email)}
                    >
                      {otherUser.email}
                    </Topbar.UserMenuItem>
                  ))}
            </>

            <Topbar.UserMenuItem id='eduzz-topbar-menu-logout' onClick={handleLogout}>
              Sair
            </Topbar.UserMenuItem>
          </Topbar.UserMenu>
        </Topbar>

        <Sidebar currentLocation={location.pathname}>
          <Group id='eduzz-sidebar-group-home' label='Início'>
            <Item id='eduzz-sidebar-resume' as={NavLink} to='/home'>
              Resumo
            </Item>
            <Item id='eduzz-sidebar-help-center' as={NavLink} to='/help-center'>
              Central de Ajuda
            </Item>
          </Group>
          <Group id='eduzz-sidebar-group-events' label='Eventos'>
            <Item id='eduzz-sidebar-create-event' as={NavLink} to='/event'>
              Criar Evento
            </Item>
            <Item id='eduzz-sidebar-my-events' as={NavLink} to='/my-events'>
              Meus Eventos
            </Item>
          </Group>
          <Group id='eduzz-sidebar-group-advanced' label='Avançado'>
            <a
              id='eduzz-sidebar-webhook'
              href={`${WEBHOOK_URL}/webhook/configs`}
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none', color: '#000' }}
            >
              <Item>Webhook</Item>
            </a>
          </Group>
        </Sidebar>

        <Content className={classes.bgColorGrey}>
          <div className={classes.root}>{privateRoutesWithAttendanceProp}</div>
        </Content>
      </Layout>
    </HoustonThemeProvider>
  );
};

export default PrivateLayout;
