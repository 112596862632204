import { green, grey } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import { fontSizePxToRem, setMargin } from 'helpers/functions';

const styles = theme =>
  createStyles({
    iconButton: {
      padding: 0,
      color: theme.palette.primary.contrastText
    },

    submenu: {
      minWidth: 100,
      color: theme.palette.primary.contrastText
    },

    iconWithText: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        ...setMargin(null, null, null, -10),
        width: 100
      }
    },
    typographyString: {
      fontFamily: "'Roboto', sans-serif",
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: fontSizePxToRem(14),
      lineHeight: '150%',
      color: theme.palette.primary.contrastText
    },

    menuItemWhatsappOption: {
      backgroundImage: 'url(https://eduzz-cdn.s3.amazonaws.com/blinket/assets/Whatsapp.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '25px',
      backgroundPosition: '15px'
    },

    el: {
      'color': '#000000',

      '&.--status-col': {
        padding: 0
      },

      '& .image': {
        width: 90,
        maxWidth: '100%',
        height: 90,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: 4,

        [`${theme.breakpoints.down('sm')}`]: {
          width: 50,
          height: 50
        }
      },

      '& .status, .moderation': {
        'position': 'absolute',
        'height': '100%',
        'width': 5,
        'top': 0,
        'left': 0,
        'background': grey[400],
        'borderRadius': '4px 0 0 4px',

        '&.--active': {
          background: green[500]
        }
      },

      '& .action': {
        'textAlign': 'right',

        '& button': {
          '& span.material-icons': {
            fontSize: fontSizePxToRem(32) + ' !important'
          }
        }
      },

      '& .string': {
        'whiteSpace': 'pre-wrap',
        'textOverflow': 'ellipsis',
        'OTextOverflow': 'ellipsis',
        'overflow': 'hidden',
        'width': '100%',
        'color': theme.palette.primary.contrastText,

        '& div': {
          width: '100%',
          whiteSpace: 'pre-wrap',
          textOverflow: 'ellipsis',
          OTextOverflow: 'ellipsis',
          overflow: 'hidden'
        },

        '& a, a:visited, a:focus': {
          'color': theme.palette.primary.contrastText,

          '& div': {
            color: theme.palette.primary.contrastText
          }
        }
      }
    }
  });

export default styles;
