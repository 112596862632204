import createStyles from '@mui/styles/createStyles';
import { setMargin } from 'helpers/functions';

const styles = theme =>
  createStyles({
    loading: {
      'width': '100%',
      'overflow': 'hidden',

      '& .children': {
        'borderRadius': 4,
        'animationDuration': '5.2s',
        'animationFillMode': 'forwards',
        'animationIterationCount': 'infinite',
        'animationTimingFunction': 'linear',
        'animationName': 'placeHolderShimmer',
        'background': 'linear-gradient(to right, #f6f6f6 8%, rgba(221, 221, 221, 0.55) 18%, #f6f6f6 30%)',
        'backgroundSize': '1000px 1000px',
        'position': 'relative',

        '&.--shadow': {
          boxShadow: theme.shadows[1]
        }
      },

      // types
      '& .element-square, .element-block': {},

      '& .element-circle': {
        borderRadius: '100%',
        margin: '0 auto'
      },

      '& .element-list': {
        '& span': {
          width: '100%',
          animationDuration: '5.2s',
          animationFillMode: 'forwards',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
          animationName: 'placeHolderShimmer',
          background: 'linear-gradient(to right, #f6f6f6 8%, rgba(221, 221, 221, 0.55) 18%, #f6f6f6 30%)',
          backgroundSize: '1000px 50px',
          position: 'relative',
          height: 66,
          display: 'block',
          ...setMargin(null, null, 10, null),
          borderRadius: 4
        }
      },

      '& .element-text': {
        '&.--center': {
          'textAlign': 'center',

          '& span': {
            margin: '0 auto',
            marginBottom: 10
          }
        },

        '& span': {
          borderRadius: 16,
          animationDuration: '5.2s',
          animationFillMode: 'forwards',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
          animationName: 'placeHolderShimmer',
          background: 'linear-gradient(to right, #f6f6f6 8%, rgba(221, 221, 221, 0.55) 18%, #f6f6f6 30%)',
          backgroundSize: '1000px 50px',
          position: 'relative',
          height: 16,
          display: 'block',
          ...setMargin(null, null, 10, null)
        }
      }
    }
  });

export default styles;
