import * as React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, notification, Tooltip } from 'antd';

import attendanceListService from 'services/attendanceList';

const CheckInButton = ({ itemId, checkInDate, finishedEvent }) => {
  const [checked, setChecked] = React.useState(!!checkInDate);
  const [loading, setLoading] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const handleCheckinAttendances = (attendanceId: string, active: 0 | 1) => {
    setLoading(true);
    attendanceListService.checkIn(attendanceId, active).subscribe({
      next: () => {
        setChecked(checked => !checked);
        setLoading(false);
      },
      error: () => {
        setLoading(false);
        return notification.error({ message: 'Ocorreu um erro ao realizar o check-in. Tente novamente' });
        return notification.error({ message: 'Ocorreu um erro ao realizar o check-in. Tente novamente' });
      }
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          transition: 'all 300ms',
          position: 'relative',
          width: '150px',
          alignItems: 'center'
        }}
      >
        <Tooltip title={finishedEvent ? 'Evento finalizado' : ''} color='#ffbc00'>
          {checked ? (
            <>
              <Button
                disabled={loading || finishedEvent}
                onMouseLeave={() => setIsHovering(false)}
                onClick={e => {
                  e.stopPropagation();
                  handleCheckinAttendances(itemId, 0);
                }}
                key={'undo-checkin-btn'}
                id='undo-checkin-btn'
                type='primary'
                style={{
                  position: 'absolute',
                  color: '#000000',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  transition: 'all 300ms',
                  visibility: isHovering ? 'visible' : 'hidden',
                  opacity: isHovering ? 1 : 0,
                  top: '-12px',
                  left: 0
                }}
              >
                {!loading ? 'DESFAZER CHECK-IN' : 'DESFAZENDO CHECK-IN...'}
              </Button>

              <CheckCircleOutlined
                onMouseEnter={() => setIsHovering(true)}
                style={{
                  fontSize: '24px',
                  color: 'green',
                  visibility: isHovering ? 'hidden' : 'visible',
                  opacity: isHovering ? 0 : 1,
                  position: 'absolute',
                  top: '-10px'
                }}
              />
            </>
          ) : (
            <Button
              disabled={loading || finishedEvent}
              onClick={e => {
                e.stopPropagation();
                handleCheckinAttendances(itemId, 1);
              }}
              key={'checkin-btn'}
              id='checkin-btn'
              style={{
                position: 'absolute',
                color: '#000000',
                borderColor: '#d9d9d9',
                fontSize: '12px',
                fontWeight: 'bold'
              }}
            >
              {!loading ? 'FAZER CHECK-IN' : 'FAZENDO CHECK-IN...'}
            </Button>
          )}
        </Tooltip>
      </div>
    </>
  );
};

export default CheckInButton;
