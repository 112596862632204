import { useParams } from 'react-router-dom';

import useCustomObservable from 'hooks/useCustomObservable';
import { IEventForm } from 'interfaces/event';

import Form from '../components/Form';
import { FormContextProvider } from '../Context';
import duplicateEventService from './service';
import * as S from './styles';
import { PageParams } from './types';
import StepButtonsGroup from '../components/StepButtonsGroup';

const DuplicateEvent = () => {
  const { id: eventId } = useParams<PageParams>();

  const { value: eventData, loading } = useCustomObservable<IEventForm, void, void>(
    () => duplicateEventService.detail(eventId),
    null
  );

  const filteredEventData = eventData
    ? {
        ...eventData,
        ticket: eventData.ticket
          .map(ticket => ({
            ...ticket,
            lot: ticket.lot.filter(lot => lot.is_active === 1)
          }))
          .filter(ticket => ticket.lot.length > 0)
      }
    : null;

  return (
    <FormContextProvider isCreate>
      <S.Wrapper>
        <StepButtonsGroup />
        {!loading && <Form defaultValue={filteredEventData} />}
      </S.Wrapper>
    </FormContextProvider>
  );
};
export default DuplicateEvent;
