import createStyles from '@mui/styles/createStyles';
import { fontSizePxToRem } from 'helpers/functions';
const styles = () =>
  createStyles({
    notfound: {
      color: '#777',
      fontSize: fontSizePxToRem(14),
      padding: '8px 0',
      textAlign: 'center',
      display: 'block'
    }
  });

export default styles;
