import { useCallback, useState, useContext, useRef } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import PlusSquareOutlined from '@ant-design/icons/PlusSquareOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import {
  Dropdown,
  Empty,
  notification,
  Typography,
  Input,
  Button,
  Row,
  Col,
  Collapse,
  Tooltip,
  Pagination,
  Form
} from 'antd';

import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import ModalNotifyEdit from 'components/Presentational/ModalNotifyEdit';
import ModalWarning from 'components/Presentational/ModalWarning';
import Loading from 'components/Shared/Loading';
import { isEmpty } from 'helpers/lodash';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { DateDetail, IEventForm, ITicketForm } from 'interfaces/event';
import ticketService from 'services/ticket';
import { v4 } from 'uuid';

import FormContext from '../../../Context';
import Actions from '../Actions';
import { ticketDefault, ticketLotDefault } from '../mock';
import AddSomething from './AddSomething';
import EventDateForm from './EventDateForm';
import EventDates from './EventDates';
import * as S from './styles';
import Ticket from './Ticket';

const perPagePagination = 10;

const { Panel } = Collapse;

export const LoadingBar = () => (
  <div style={{ width: '100%' }}>
    <Fade timeout={500}>
      <Loading showLoading content={[{ type: 'list', sizes: { xs: 12 }, height: 380 }]} />
    </Fade>
  </div>
);

const menuItemStyle = {
  margin: 0,
  padding: 0
};

const FormThree = () => {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { dirtyFields },
    trigger,
    getValues
  } = useFormContext<IEventForm & { isCreate: boolean }>();
  const eventDatesWatch = watch('event_dates');
  const isAdding = useRef(false);

  const hasError = !isEmpty(window['formErrors']);

  const {
    fields: ticketFields,
    append: appendTicketFields,
    remove: removeTicketFields
  } = useFieldArray({
    control,
    name: 'ticket',
    keyName: 'identificator'
  });

  const [inputValue, setInputValue] = useState('');
  const [activeTickets, setActiveTickets] = useState<string[]>([]);
  const [activeTicketForm, setActiveTicketForm] = useState<string>('');

  const setTheActiveTickets = (keys: string[]) => {
    if (keys?.length > activeTickets?.length) {
      keys.forEach(key => {
        const index = ticketFields.findIndex(item => item.identificator === key);
        if (getValues(`ticket.${index}.is_active`)) trigger(`ticket.${index}`);
      });
      setActiveTickets(keys);
      return;
    }

    setActiveTickets(keys);
  };

  const [activeLots, setActiveLots] = useState([]);

  const setTheActiveLots = (ticketIndex: number) => (keys: string[]) => {
    if (keys?.length > activeLots?.length) {
      keys.forEach(key => {
        const lotIndex = ticketFields[ticketIndex].lot.findIndex(item => item.identificator === key);

        if (getValues(`ticket.${ticketIndex}.lot.${lotIndex}.is_active`)) {
          trigger(`ticket.${ticketIndex}.lot.${lotIndex}`);
        }
      });
      setActiveLots(keys);
      return;
    }

    setActiveLots(keys);
  };

  const [eventDateFormOpen, setEventDateFormOpen] = useState(false);
  const [isRemovingTicket, setIsRemovingTicket] = useState(false);
  const [editEventDateValues, setEditEventDateValues] = useState<DateDetail>(null);
  const [openModalDelete, setOpenModalDelete] = useState<ITicketForm & { modal?: 'disable' | 'delete'; index: number }>(
    null
  );
  const [openModalNotifyEdit, setOpenModalNotifyEdit] = useState(false);
  const [modalNotifyOption, setModalNotifyOption] = useState(1);
  const { handleEdit, isCreate, loadingEdit, jobsLotPending, setCurrentStep, setStepsEnables, stepsEnables } =
    useContext(FormContext);
  const showTickets = isCreate && !!eventDatesWatch ? eventDatesWatch.length > 0 : true;

  const {
    fields: eventDateFields,
    append: appendEventDateFields,
    update: updateEventDateFields,
    remove: removeEventDateFields
  } = useFieldArray({
    control,
    name: 'event_dates',
    keyName: 'identificator'
  });

  const handleAddTicket = async () => {
    if (hasError) {
      notification.error({ message: 'Verifique todos os campos' });
      return;
    }

    const newTicket = {
      ...ticketDefault,
      identificator: v4(),
      lot: [{ ...ticketLotDefault, identificator: v4(), name: 'Lote Novo' }]
    };
    appendTicketFields(newTicket);
    isAdding.current = true;
  };

  useDidMountEffect(() => {
    if (isAdding.current) {
      //tudo isso aqui é pra quando adicionar um ingresso abrir o collapse e validar
      const lastIndex = ticketFields.length - 1;
      const lastTicketKey = ticketFields[lastIndex].identificator;
      const lastLotKey = ticketFields[lastIndex].lot[0].identificator;
      setActiveTickets([lastTicketKey]);
      setActiveTicketForm(lastTicketKey);
      setActiveLots([lastLotKey]);
      goToLastPage();
      if (getValues(`ticket.${lastIndex}.is_active`)) trigger(`ticket.${lastIndex}`);
      isAdding.current = false;
    }
  }, [ticketFields.length]);

  const handleEditEventDate = (eventDate: DateDetail) => {
    setEditEventDateValues(eventDate);
    setEventDateFormOpen(true);
  };

  const handleCloseEventDateForm = () => {
    setEventDateFormOpen(false);
    setEditEventDateValues(null);
  };

  const handleDuplicateEventDate = (eventDate: DateDetail) => {
    setEditEventDateValues({ ...eventDate, arrayId: '' });
    setEventDateFormOpen(true);
  };

  const handleDeleteEventDate = (arrayId: string) => {
    const eventDateIndex = eventDateFields.findIndex(eventDate => eventDate.arrayId === arrayId);
    removeEventDateFields(eventDateIndex);
  };

  const handleDuplicateTicket = (ticket: ITicketForm) => {
    if (ticket.lot.length === 1 && ticket.lot[0].is_active === 0) {
      notification.error({ message: 'Este ingresso possui somente um lote inativo' });
      return;
    }

    const newTicket: ITicketForm = { ...ticket, identificator: v4(), id: '' };

    const lot = ticket.lot.map(item => ({
      ...item,
      identificator: v4(),
      id: ''
    }));

    appendTicketFields({ ...newTicket, lot });
    isAdding.current = true;
  };

  const handleDeleteTicket = (index: number, ticket: ITicketForm, showModal?: boolean) => {
    if (jobsLotPending) {
      notification.error({
        message: 'Ainda salvando sua última alteração no evento, aguarde o salvamento finalizar para realizar esta ação'
      });
      return;
    }

    if (loadingEdit || isRemovingTicket) {
      notification.error({ message: 'Ainda processando a última requisição, aguarde para realizar esta ação' });
      return;
    }

    if (showModal === false || isCreate) {
      removeTicketFields(index);
      setPage(1);
      return;
    }

    const modalDisable = ticket.lot?.some(lot => !!lot.quantity_sold);
    setOpenModalDelete({ ...ticket, index, modal: modalDisable ? 'disable' : 'delete' });
  };

  const handleNext = useCallback(() => {
    if (!eventDatesWatch.length) {
      return notification.error({ message: 'Determine a data e hora de seu evento' });
    }

    setCurrentStep('4');
    return setStepsEnables([...stepsEnables, '4']);
  }, [eventDatesWatch, setCurrentStep, setStepsEnables, stepsEnables]);

  const disableTicket = () => {
    const { id, index } = openModalDelete;

    if (jobsLotPending) {
      notification.error({
        message: 'Ainda salvando sua última alteração no evento, aguarde o salvamento finalizar para realizar esta ação'
      });
      return;
    }

    if (loadingEdit || isRemovingTicket) {
      notification.error({ message: 'Ainda processando a última requisição, aguarde para realizar esta ação' });
      return;
    }

    setIsRemovingTicket(true);

    if (id) {
      ticketService.deactivate(id).subscribe({
        next: () => {
          setValue(`ticket.${index}.is_active`, 0);
          notification.success({ message: 'Ingresso desativado com sucesso!' });
          setIsRemovingTicket(false);
        },
        error: ({ data: response }) => {
          setIsRemovingTicket(false);

          if (response && response?.code === 'ERR_CUSTOM') {
            notification.error({ message: response.details });
          } else {
            notification.error({ message: 'Houve uma falha ao desativar o Ingresso' });
          }
        }
      });
    } else {
      removeTicketFields(index);
      notification.success({ message: 'Ingresso excluído com sucesso' });
      setIsRemovingTicket(false);
      setPage(1);
    }

    return setOpenModalDelete(null);
  };

  const removeTicket = () => {
    const { id, index } = openModalDelete;

    if (loadingEdit || isRemovingTicket) {
      notification.error({ message: 'Ainda processando a última requisição, aguarde para realizar esta ação' });
      return;
    }

    if (jobsLotPending) {
      notification.error({ message: 'Aguarde o salvamento finalizar para realizar esta ação' });
      return;
    }

    setIsRemovingTicket(true);

    if (id) {
      ticketService.delete(id).subscribe({
        next: () => {
          removeTicketFields(index);
          notification.success({ message: 'Ingresso excluído com sucesso' });
          setIsRemovingTicket(false);
          setPage(1);
        },
        error: ({ data: response }) => {
          setIsRemovingTicket(false);

          if (response && response?.code === 'ERR_CUSTOM') {
            notification.error({ message: response.details });
          } else {
            notification.error({ message: 'Falha ao excluir o Ingresso' });
          }
        }
      });
    } else {
      removeTicketFields(index);
      notification.success({ message: 'Ingresso excluído com sucesso' });
      setIsRemovingTicket(false);
      setPage(1);
    }

    return setOpenModalDelete(null);
  };

  const shouldSendNotification = (): boolean => {
    let haveAnyTicketSold = false;

    ticketFields.some(ticket => {
      ticket.lot.some(lot => {
        if (lot.quantity_sold !== 0) {
          haveAnyTicketSold = true;

          return true;
        }

        return false;
      });
    });

    if (isCreate || !haveAnyTicketSold) {
      return false;
    }

    if (dirtyFields.event_dates && haveAnyTicketSold) {
      setValue('notifyAttendancesCustomMsg', 'NOTIFICAR');
      return true;
    }

    return false;
  };

  const onInputChange = e => {
    const value = e.target.value;

    if (value?.length < 3) {
      setInputValue('');
      return;
    }

    if (hasError) {
      notification.error({ message: 'Verifique todos os campos' });
      return;
    }

    const inputValue = value.toLowerCase();
    setInputValue(inputValue);

    if (page !== 1) {
      setPage(1);
    }
  };

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(perPagePagination);

  const goToLastPage = () => {
    const lastPage = Math.ceil(ticketFields.length / perPage);
    setPage(lastPage);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onPerPageChange = (_: number, perPage: number) => {
    setPerPage(perPage);
    setPage(1);
  };

  const hasFilter = inputValue.length >= 3;

  const isInCurrentPage = (index: number) => {
    const start = (page - 1) * perPage;
    const end = page * perPage - 1;

    return !!(index >= start && index <= end);
  };

  const handlePrimaryButtonAction = () => {
    if (!showTickets || ticketFields.length === 0) {
      notification.error({ message: 'Não é possível prosseguir sem adicionar uma data e um ingresso' });
      return;
    }

    if (hasError) {
      let ignore = false;
      const errors = window['formErrors']?.['ticket'];

      errors.map((item, index) => {
        let isInactivelot = true;

        item.lot.map((lotError, lotIndex) => {
          if (ticketFields[index]?.lot[lotIndex].is_active === 1) {
            isInactivelot = false;
          }
        });

        ignore = isInactivelot;
      });

      if (!ignore) {
        notification.error({ message: 'Verifique todos os campos' });
        return;
      }
    }

    if (isCreate) {
      handleSubmit(handleNext)();
      return;
    }

    if (isRemovingTicket) {
      notification.error({ message: 'Ainda processando a última requisição, aguarde para realizar esta ação' });
      return;
    }

    if (shouldSendNotification()) {
      setOpenModalNotifyEdit(true);
      return;
    }

    handleSubmit(handleEdit)();
  };

  const primaryButtonActionText = isCreate ? 'Próximo' : loadingEdit || !!jobsLotPending ? 'Salvando' : 'Salvar';

  const canShow = item =>
    item.name.toLowerCase().includes(inputValue) || item.lot.some(i => i.name.toLowerCase().includes(inputValue));

  const watchFieldArray = watch('ticket');
  const controlledFields = ticketFields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  const filteredTickets = inputValue ? controlledFields.filter(canShow) : controlledFields;
  const hasPagination = filteredTickets.length > perPagePagination;
  return (
    <S.Wrapper>
      <Grid item xs={12}>
        <S.Title>Determine a data e hora de seu evento</S.Title>
        <S.Subtitle>
          Preencha os detalhes da data do seu evento e crie os ingressos e lotes necessários para proporcionar uma
          experiência incrível aos seus participantes.
        </S.Subtitle>
      </Grid>
      <Grid container spacing={3}>
        {!!eventDateFields.length && (
          <Grid container item>
            <Grid item xs={12}>
              <EventDates
                fields={eventDatesWatch}
                onDelete={handleDeleteEventDate}
                onDuplicate={handleDuplicateEventDate}
                onEdit={handleEditEventDate}
              />
            </Grid>
          </Grid>
        )}

        {eventDateFormOpen && (
          <Grid container item>
            <EventDateForm
              appendEventDates={appendEventDateFields}
              editValues={editEventDateValues}
              eventDates={eventDateFields}
              open={eventDateFormOpen}
              onClose={handleCloseEventDateForm}
              updateEventDates={updateEventDateFields}
              openTicketsPanel={() => {
                setActiveTickets([ticketFields[0].identificator]);
                setActiveTicketForm(ticketFields[0].identificator);
              }}
            />
          </Grid>
        )}
        {!eventDateFormOpen && (
          <Grid container item>
            <Grid item xs={12}>
              <AddSomething>
                <AddSomething.Button
                  type='text'
                  onClick={() => setEventDateFormOpen(true)}
                  icon={<PlusSquareOutlined />}
                  style={{ width: '100%', textAlign: 'start' }}
                  key={'addDateTime'}
                >
                  Adicionar data do evento
                </AddSomething.Button>
              </AddSomething>
            </Grid>
          </Grid>
        )}

        <Grid container item>
          <Grid item xs={12}>
            <Row>
              <Col md={14}>
                <S.Title>Crie e gerencie seus ingressos</S.Title>
                <S.Subtitle>
                  Utilize as opções disponíveis abaixo para configurar seus ingressos conforme necessário.
                </S.Subtitle>
              </Col>
            </Row>

            {!isCreate && (
              <Grid container item style={{ margin: '20px 0' }}>
                <Grid item xs={12}>
                  <Form>
                    <Form.Item
                      name='input'
                      rules={[
                        {
                          required: true,
                          message: 'Digite pelo menos 3 caracteres',
                          min: 3
                        }
                      ]}
                      style={{ marginBottom: '0' }}
                    >
                      <Input
                        size='large'
                        suffix={<SearchOutlined />}
                        placeholder='Procurar ingresso ou lote'
                        onChange={onInputChange}
                        value={inputValue}
                        disabled={hasError}
                      />
                    </Form.Item>
                  </Form>
                </Grid>
              </Grid>
            )}

            {!filteredTickets.length && hasFilter && (
              <Empty description={`Não há ingressos ou lote com o nome ${inputValue}`} />
            )}

            {filteredTickets.length > 0 && showTickets && (
              <Collapse
                activeKey={activeTickets}
                onChange={setTheActiveTickets}
                collapsible='icon'
                expandIconPosition='end'
                style={{ background: '#fcfcfc', borderRadius: 0 }}
              >
                {controlledFields.map((item, index) => {
                  const ticketIndexError = window['formErrors']?.['ticket']?.[index];
                  const show = canShow(item);
                  const filteredItemIndex = filteredTickets.findIndex(f => f.id === item.id);
                  const isInPage = isInCurrentPage(filteredItemIndex);
                  if (!show || !isInPage) return null;
                  return (
                    <Panel
                      header={
                        <S.PanelHeader>
                          <S.TicketTitle>
                            {!!ticketIndexError && (
                              <Tooltip
                                title={
                                  <Typography style={{ padding: '0', fontSize: '13px', color: 'white' }}>
                                    Por favor, preencha e salve todas as informações obrigatórias do ingresso/lote para
                                    continuar. (*)
                                  </Typography>
                                }
                                color='#6d6d6d'
                                placement='top'
                              >
                                <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: '16px' }} />
                              </Tooltip>
                            )}{' '}
                            {item.name || 'Ingresso Novo'}
                          </S.TicketTitle>
                        </S.PanelHeader>
                      }
                      extra={
                        <Dropdown
                          menu={{
                            items: [
                              {
                                label: (
                                  <S.MenuButton
                                    type='text'
                                    onClick={() => {
                                      setTheActiveTickets([...activeTickets, item.identificator]);
                                      setActiveTicketForm(item.identificator);
                                    }}
                                    block
                                  >
                                    Editar
                                  </S.MenuButton>
                                ),
                                key: '1',
                                style: menuItemStyle
                              },
                              {
                                label: (
                                  <S.MenuButton
                                    type='text'
                                    onClick={() => {
                                      setTheActiveTickets([...activeTickets, item.identificator]);
                                      handleDuplicateTicket(item);
                                    }}
                                    block
                                  >
                                    Duplicar
                                  </S.MenuButton>
                                ),
                                key: '2',
                                style: menuItemStyle
                              },
                              {
                                label: (
                                  <Tooltip
                                    title={ticketFields.length <= 1 ? 'Cadastre outro ingresso para excluir este' : ''}
                                    color='#ffbc00'
                                  >
                                    <S.MenuButton
                                      type='text'
                                      onClick={() => handleDeleteTicket(index, item)}
                                      disabled={ticketFields.length <= 1}
                                      block
                                    >
                                      Excluir
                                    </S.MenuButton>
                                  </Tooltip>
                                ),
                                key: '3',
                                danger: true,
                                style: menuItemStyle
                              }
                            ],
                            selectable: true
                          }}
                          placement='bottomRight'
                        >
                          <Button type='text' icon={<MoreOutlined rotate={90} style={{ fontSize: '25px' }} />} />
                        </Dropdown>
                      }
                      key={item.identificator}
                    >
                      <Ticket
                        canBeDeleted={ticketFields.length > 1}
                        index={index}
                        onDelete={handleDeleteTicket}
                        setActiveLots={setTheActiveLots(index)}
                        activeLots={activeLots}
                        key={item.identificator}
                        ticketIdentificator={item.identificator}
                        activeTicketForm={activeTicketForm}
                        setActiveTicketForm={setActiveTicketForm}
                        isAddingTicket={isAdding.current}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            )}

            {hasPagination && (
              <div style={{ display: 'flex', marginTop: '16px', marginBottom: '16px', justifyContent: 'center' }}>
                <Pagination
                  showSizeChanger
                  current={page}
                  pageSize={perPage}
                  pageSizeOptions={[5, 10, 20, 50, 100]}
                  onChange={onPageChange}
                  onShowSizeChange={onPerPageChange}
                  total={filteredTickets.length}
                  locale={{ items_per_page: '/ Página' }}
                  disabled={hasError}
                />
              </div>
            )}

            {eventDatesWatch && (
              <Grid container item style={{ marginTop: '16px' }}>
                <Grid item xs={12}>
                  <AddSomething>
                    <Tooltip
                      title={
                        eventDatesWatch.length < 1 ? (
                          <p style={{ padding: '0', fontSize: '13px', color: 'white' }}>
                            Adicione a data do evento antes de criar ingresso
                          </p>
                        ) : hasError ? (
                          <p style={{ padding: '0', fontSize: '13px', color: 'white' }}>
                            Por favor, salve as informações do ingresso antes de criar um novo
                          </p>
                        ) : inputValue.length > 0 ? (
                          <p style={{ padding: '0', fontSize: '13px', color: 'white' }}>
                            Limpe a busca para poder adicionar um novo Ingresso
                          </p>
                        ) : null
                      }
                      color='#6d6d6d'
                      placement='top'
                    >
                      <AddSomething.Button
                        id='add-ticket-btn'
                        type='text'
                        disabled={inputValue.length > 0 || hasError || eventDatesWatch.length < 1}
                        onClick={handleAddTicket}
                        icon={<PlusSquareOutlined />}
                      >
                        Criar Ingresso
                      </AddSomething.Button>
                    </Tooltip>
                  </AddSomething>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Actions>
            {isCreate && (
              <Actions.Button
                type='text'
                size='large'
                id='stepback-btn-form-3'
                onClick={() => {
                  if (hasError) return;
                  setCurrentStep('2');
                }}
              >
                Voltar
              </Actions.Button>
            )}
            <Tooltip defaultOpen={!!jobsLotPending} open={!!jobsLotPending} title={jobsLotPending} color='#ffbc00'>
              <Actions.Button
                onClick={handlePrimaryButtonAction}
                type='primary'
                id={isCreate ? 'step-forward-btn-form-3' : 'save-btn-form-3'}
                size='large'
                disabled={loadingEdit || !!jobsLotPending}
                icon={!isCreate && <SaveOutlined />}
              >
                {primaryButtonActionText}
              </Actions.Button>
            </Tooltip>
          </Actions>
        </Grid>
      </Grid>

      {openModalDelete?.modal === 'disable' && (
        <ModalWarning
          content='Atenção: Alguns ingressos deste ingresso já foram vendidos, por esse motivo o ingresso não pode ser excluído. Deseja desabilitar?'
          onCancel={() => setOpenModalDelete(null)}
          onContinue={() => disableTicket()}
          isOpen={openModalDelete.modal === 'disable'}
          title='Desabilitar ingresso'
        />
      )}

      {openModalDelete?.modal === 'delete' && (
        <ModalWarning
          content='Atenção: Esta ação não poderá ser desfeita. Deseja excluir este ingresso definitivamente?'
          onCancel={() => setOpenModalDelete(null)}
          onContinue={() => removeTicket()}
          isOpen={openModalDelete.modal === 'delete'}
          title='Deletar ingresso'
        />
      )}

      <ModalNotifyEdit
        handleRadioOption={value => {
          if (value === 3) {
            setValue('notifyAttendancesCustomMsg', 'DISABLED');
          }

          setModalNotifyOption(value);
        }}
        onCancel={() => setOpenModalNotifyEdit(false)}
        onContinue={handleSubmit(handleEdit)}
        open={openModalNotifyEdit}
        optionSelectNotifyAttendancesDialog={modalNotifyOption}
      />
    </S.Wrapper>
  );
};

export default FormThree;
