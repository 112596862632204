import React, { Reducer, useReducer, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router';

import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import {
  Avatar,
  Button,
  Col,
  ColorPicker,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Tooltip,
  Typography
} from 'antd';

import TemplateBasic from 'assets/images/template-basic.png';
import TemplatePop from 'assets/images/template-pop.png';
import ModalWarning from 'components/Presentational/ModalWarning';
import Field from 'components/Shared/Field';
import ImageSelector, { IImageSelectorResult } from 'components/Shared/ImageSelector';
import { debounce, isEmpty } from 'helpers/lodash';
import { IEventPageEdit } from 'interfaces/event';
import eventService from 'services/event';

import { ActionWrapper, ButtonWrapper } from './styles';
import { ActionProps, ActionTypes, CropperConfigProps, StateProps } from './types';

const initialState: StateProps = {
  backgroundOption: 'Cor no fundo',
  cropperConfig: { height: 500, width: 500, opened: false, onComplete: value => JSON.stringify(value) },
  imageBackground: { base64: '', filename: '', image: { height: 0, width: 0, name: '', url: '' } },
  imageHeader: { base64: '', filename: '', image: { height: 0, width: 0, name: '', url: '' } },
  textColorBackground: '#FFFFF',
  textColorHeader: '#000000',
  localimage: { base64: '', filename: '', image: { height: 0, width: 0, name: '', url: '' } },
  resumeimage: { base64: '', filename: '', image: { height: 0, width: 0, name: '', url: '' } },
  reactQuillClassNamePromation: '',
  reactQuillClassNameDescription: ''
};

const reducer: Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case ActionTypes.imageHeader:
      return { ...state, imageHeader: action.value as IImageSelectorResult };

    case ActionTypes.textColorHeader:
      return { ...state, textColorHeader: action.value as string };

    case ActionTypes.imageBackground:
      return { ...state, imageBackground: action.value as IImageSelectorResult };

    case ActionTypes.textColorBackground:
      return { ...state, textColorBackground: action.value as string };

    case ActionTypes.backgroundOption:
      return {
        ...state,
        backgroundOption: action.value as string
      };
    case ActionTypes.cropperConfig:
      return { ...state, cropperConfig: action.value as CropperConfigProps };

    case ActionTypes.cropperResetConfig:
      return { ...state, cropperConfig: { ...initialState.cropperConfig } };

    case ActionTypes.localimage:
      return { ...state, localimage: action.value as IImageSelectorResult };

    case ActionTypes.resumeimage:
      return { ...state, resumeimage: action.value as IImageSelectorResult };

    case ActionTypes.reactQuillClassNamePromation:
      return { ...state, reactQuillClassNamePromation: action.value as string };

    case ActionTypes.reactQuillClassNameDescription:
      return { ...state, reactQuillClassNameDescription: action.value as string };

    default:
      return state;
  }
};

export interface Props {
  handleVerifyData: () => void;
  handleOpenPreview: (open: boolean) => void;
  handleSaveEdit: (pageContent: IEventPageEdit, isDraft?: boolean) => void;
  draftUrl: string;
  isPresential: boolean;
  isSaving: boolean;
  hasChangedData: boolean;
  draftSaved: boolean;
  eventId: string;
}

const EventPageForm = ({
  handleVerifyData,
  handleOpenPreview,
  draftUrl,
  isPresential,
  isSaving,
  hasChangedData,
  draftSaved,
  eventId,
  handleSaveEdit
}: Props) => {
  const [continueModalWarning, setContinueModalWarning] = useState<(() => void) | null>(null);

  const history = useHistory();
  const { control, watch, setValue, setError, clearErrors, formState, getValues } = useFormContext();

  const handleSaveForm = (isDraft: boolean) => {
    if (!isEmpty(formState?.errors)) {
      notification.error({ message: 'Verifique todos os campos' });
      return;
    }

    handleSaveEdit({ ...(getValues() as unknown as IEventPageEdit) }, isDraft);
  };

  const handlePreview = () => {
    if (hasChangedData && !draftSaved) {
      const onContinue = () => {
        setContinueModalWarning(null);
        handleOpenPreview(true);
      };

      return setContinueModalWarning(() => onContinue);
    }

    return handleOpenPreview(true);
  };

  const handleReturn = () => {
    history.push(`/my-events/${eventId}/event-page`);
  };

  const saleUrlError = formState?.errors?.sale_url?.message;

  const [{ localimage, resumeimage, cropperConfig, imageHeader, imageBackground }, dispatch] = useReducer(reducer, {
    ...initialState,
    localimage: {
      base64: watch('localimage'),
      filename: watch('localimageFileName'),
      image: { height: 0, width: 0, name: '', url: watch('localimage') || '' }
    },
    resumeimage: {
      base64: watch('resumeimage'),
      filename: watch('resumeimageFileName'),
      image: { height: 0, width: 0, name: '', url: watch('resumeimage') || '' }
    },
    imageHeader: {
      base64: watch('logo'),
      filename: watch('logoFileName'),
      image: { height: 0, width: 0, name: '', url: watch('logo') || '' }
    },
    imageBackground: {
      base64: watch('headerimg'),
      filename: watch('headerimgFileName'),
      image: { height: 0, width: 0, name: '', url: watch('headerimg') || '' }
    },
    textColorHeader: watch('header_color_texts') || initialState.textColorHeader,
    textColorBackground: watch('headerbackgroundcolor') || initialState.textColorBackground,
    backgroundOption: watch('headeroption') === '1' ? 'Imagem no fundo' : 'Cor no fundo'
  });

  const handleBackgroundOption = (e: RadioChangeEvent) => {
    setValue('headeroption', e.target.value);
    handleVerifyData();
    dispatch({
      type: ActionTypes.backgroundOption,
      value: e.target.value === 1 ? 'Imagem no fundo' : 'Cor no fundo'
    });
  };

  const handleSetCropperConfig = (config: CropperConfigProps) => {
    handleVerifyData();
    dispatch({ type: ActionTypes.cropperConfig, value: config });
  };

  const handleCloseCropper = () => {
    handleVerifyData();
    dispatch({ type: ActionTypes.cropperResetConfig });
  };

  const handleCropperImage = (
    type: ActionTypes.imageBackground | ActionTypes.imageHeader | ActionTypes.localimage | ActionTypes.resumeimage,
    name: string,
    height: number,
    width: number
  ) => {
    const onComplete = (value: IImageSelectorResult) => {
      if (value) {
        setValue(name, value.base64.replace(/^data:image\/[a-z]+;base64,/, ''));
        setValue(`${name}FileName`, value.filename);
        handleVerifyData();
        dispatch({ type, value });
      }

      handleCloseCropper();
    };

    handleSetCropperConfig({ opened: true, height, width, onComplete });
  };

  const handleFocus = (
    type: ActionTypes.reactQuillClassNameDescription | ActionTypes.reactQuillClassNamePromation,
    value: boolean
  ) => {
    dispatch({ type, value: value ? 'focus' : '' });
  };

  const existentSaleUrl = watch('sale_url');
  const debouncedCheckSaleUrl = useRef(debounce(nextValue => checkSaleUrl(nextValue), 1000)).current;

  const checkSaleUrl = (saleUrl: string) => {
    if (!saleUrl) {
      setError('sale_url', { message: 'Digite um nome para sua página de venda' });
      return;
    }

    if (saleUrl === draftUrl) {
      return;
    }

    handleVerifyData();
    const hasSaleUrl = !!saleUrl && saleUrl.length >= 3;

    const validateSaleUrl = () => {
      setError('sale_url', { message: 'Validando' });
      eventService.availableSaleUrl(saleUrl).subscribe({
        next: data => {
          if (!data) {
            setError('sale_url', { message: 'Inválido' });
            return;
          }

          clearErrors('sale_url');
        },
        error: ({ data }) => {
          if (data?.code === 'ERR_CUSTOM') {
            setError('sale_url', {
              message: 'URL já usada. Escolha outra'
            });
          } else {
            setError('sale_url', {
              message: 'Inválido'
            });
          }
        }
      });
    };

    if (hasSaleUrl) {
      validateSaleUrl();
    }
  };

  return (
    <>
      <Form layout='vertical'>
        <Controller
          control={control}
          name='template_type'
          render={({ field: { value, onChange } }) => (
            <Form.Item>
              <Field
                name='template_type'
                render={
                  <Radio.Group style={{ marginTop: '16px', marginBottom: '16px' }} value={value} onChange={onChange}>
                    <Radio value='basic'>
                      <div style={{ display: 'flex', gap: '16px', alignItems: 'center', marginLeft: '8px' }}>
                        <img src={TemplateBasic} alt='Imagem do estilo de template basic' />
                        <Typography.Text>Template Basic</Typography.Text>
                      </div>
                    </Radio>
                    <Radio value='pop'>
                      <div style={{ display: 'flex', gap: '16px', alignItems: 'center', marginLeft: '8px' }}>
                        <img src={TemplatePop} alt='Imagem do estilo de template pop' />
                        <Typography.Text>Template Pop</Typography.Text>
                      </div>
                    </Radio>
                  </Radio.Group>
                }
              />
            </Form.Item>
          )}
        />
        <Form.Item label='URL da página de venda'>
          <Controller
            control={control}
            name='sale_url'
            render={({ field: { onChange, value } }) => (
              <>
                <Input
                  size='large'
                  prefix={<div style={{ opacity: '0.7' }}>{process.env.REACT_APP_BLINKET_EVENT_URL + '/'}</div>}
                  onChange={event => {
                    const formatedSaleUrl = event.target.value
                      .toLowerCase()
                      .trim()
                      .normalize('NFD')
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, '');

                    onChange(formatedSaleUrl);
                    debouncedCheckSaleUrl(formatedSaleUrl);
                  }}
                  id='sale_url-form-one'
                  value={value}
                />
                <div style={{ color: 'red', height: '16px', marginBottom: '8px' }}>{saleUrlError?.toString()}</div>
              </>
            )}
          />
        </Form.Item>
        <Controller
          name='pixel_key'
          control={control}
          render={({ field: { value, onChange } }) => (
            <Form.Item label='ID Facebook Pixel'>
              <Field
                name='pixel_key'
                value={value}
                render={
                  <Input
                    onChange={value => {
                      handleVerifyData();
                      onChange(value);
                    }}
                    size='large'
                  />
                }
              />
            </Form.Item>
          )}
        />
        <Typography.Title level={4}>CABEÇALHO DA PÁGINA</Typography.Title>
        <Row gutter={[20, 10]}>
          <Col sm={24} md={12}>
            <Controller
              control={control}
              name='header_color_texts'
              render={({ field: { value } }) => (
                <Form.Item label='Cor do texto'>
                  <ColorPicker
                    format='hex'
                    showText
                    value={value}
                    onChangeComplete={hex => {
                      setValue('header_color_texts', hex.toHexString());
                      handleVerifyData();
                    }}
                  />
                </Form.Item>
              )}
            />
            <Form.Item label='Logotipo do evento'>
              <Space align='center'>
                <ImageSelector {...cropperConfig} />
                {watch('logo') && (
                  <Avatar
                    size={128}
                    shape='square'
                    src={<img src={imageHeader.base64 ?? watch('logo')} alt='logo do evento' />}
                    style={{ marginRight: '15px' }}
                  />
                )}
                <Input
                  onChange={handleVerifyData}
                  name='imgHeader'
                  disabled
                  size='large'
                  style={{ pointerEvents: 'unset', cursor: 'unset' }}
                  value={watch('logoFileName')}
                  prefix={
                    <Button
                      type='text'
                      onClick={() => handleCropperImage(ActionTypes.imageHeader, 'logo', 500, 500)}
                      icon={<UploadOutlined />}
                    />
                  }
                  suffix={
                    <Button
                      type='text'
                      onClick={() => {
                        handleVerifyData();
                        setValue('logo', '');
                        setValue('logoFileName', '');
                      }}
                      icon={<CloseOutlined />}
                      disabled={!watch('logo')}
                    />
                  }
                />
              </Space>
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item label='Fundo do cabeçalho'>
              <Controller
                control={control}
                name='headeroption'
                render={({ field: { value } }) => (
                  <Field
                    name='headeroption'
                    value={value}
                    render={
                      <Radio.Group
                        onChange={handleBackgroundOption}
                        style={{ marginTop: '16px', marginBottom: '16px' }}
                      >
                        <Radio value='0'>Cor no fundo</Radio>
                        <Radio value='1'>Imagem no fundo</Radio>
                      </Radio.Group>
                    }
                  />
                )}
              />
              {watch('headeroption') === '0' && (
                <Space align='center'>
                  <Controller
                    control={control}
                    name='headerbackgroundcolor'
                    render={({ field: { value } }) => (
                      <ColorPicker
                        format='hex'
                        showText
                        value={value}
                        onChangeComplete={hex => {
                          setValue('headerbackgroundcolor', hex.toHexString());
                          handleVerifyData();
                        }}
                      />
                    )}
                  />
                </Space>
              )}
              {watch('headeroption') === '1' && (
                <Space align='center'>
                  <ImageSelector {...cropperConfig} />
                  {watch('headerimg') && (
                    <Avatar
                      size={128}
                      shape='square'
                      src={<img src={imageBackground.base64 ?? watch('headerimg')} alt='logo do evento' />}
                      style={{ marginRight: '15px' }}
                    />
                  )}
                  <Input
                    onChange={handleVerifyData}
                    name='backgroundImage'
                    value={watch('headerimgFileName')}
                    disabled
                    size='large'
                    style={{ pointerEvents: 'unset', cursor: 'unset' }}
                    suffix={
                      <Button
                        type='text'
                        onClick={() => {
                          handleVerifyData();
                          setValue('headerimg', '');
                          setValue('headerimgFileName', '');
                        }}
                        icon={<CloseOutlined />}
                        disabled={!watch('headerimg')}
                      />
                    }
                    prefix={
                      <Button
                        type='text'
                        onClick={() => handleCropperImage(ActionTypes.imageBackground, 'headerimg', 600, 1920)}
                        icon={<UploadOutlined />}
                      />
                    }
                  />
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider />

        <Typography.Title level={4}>CONTEÚDO</Typography.Title>

        <Controller
          control={control}
          name='main_video_url'
          render={({ field: { value, onChange } }) => (
            <Form.Item label='Vídeo de apresentação'>
              <Input
                onChange={value => {
                  handleVerifyData();
                  onChange(value);
                }}
                placeholder='(URL do Youtube ou Vimeo)'
                size='large'
                value={value}
                status={formState?.errors?.main_video_url ? 'error' : ''}
              />
              <div style={{ color: 'red', height: '16px', marginBottom: '8px' }}>
                {formState?.errors?.main_video_url?.message.toString()}
              </div>
            </Form.Item>
          )}
        />
        <Controller
          name='v2_desc'
          control={control}
          render={({ field: { value, onChange } }) => (
            <Form.Item label='Descrição do evento'>
              <fieldset>
                <ReactQuill
                  onFocus={() => handleFocus(ActionTypes.reactQuillClassNameDescription, true)}
                  onBlur={() => handleFocus(ActionTypes.reactQuillClassNameDescription, false)}
                  onKeyPress={handleVerifyData}
                  onChange={onChange}
                  value={value}
                />
              </fieldset>
            </Form.Item>
          )}
        />

        {watch('template_type') === 'basic' && (
          <Row gutter={[20, 10]}>
            <Col sm={24} md={12}>
              <Form.Item label='Imagem ilustrativa do evento'>
                <Space align='center'>
                  {watch('resumeimage') && (
                    <Avatar
                      size={128}
                      shape='square'
                      src={<img src={resumeimage.base64 ?? watch('resumeimage')} alt='' />}
                    />
                  )}
                  <Input
                    name='resumeimageFileName'
                    onChange={handleVerifyData}
                    disabled
                    size='large'
                    style={{ pointerEvents: 'unset', cursor: 'unset' }}
                    value={watch('resumeimageFileName')}
                    suffix={
                      <Button
                        type='text'
                        onClick={() => {
                          handleVerifyData();
                          setValue('resumeimage', '');
                          setValue('resumeimageFileName', '');
                        }}
                        icon={<CloseOutlined />}
                        disabled={!watch('resumeimage')}
                      />
                    }
                    prefix={
                      <Button
                        type='text'
                        onClick={() => handleCropperImage(ActionTypes.resumeimage, 'resumeimage', 500, 500)}
                        icon={<UploadOutlined />}
                      />
                    }
                  />
                </Space>
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label='Imagem do local'>
                <Space align='center'>
                  <ImageSelector {...cropperConfig} />
                  {watch('localimage') && (
                    <Avatar
                      size={128}
                      shape='square'
                      src={<img src={localimage.base64 ?? watch('localimage')} alt='' />}
                    />
                  )}
                  <Input
                    name='localimageFileName'
                    onChange={handleVerifyData}
                    disabled
                    size='large'
                    style={{ pointerEvents: 'unset', cursor: 'unset' }}
                    value={watch('localimageFileName')}
                    suffix={
                      <Button
                        type='text'
                        onClick={() => {
                          handleVerifyData();
                          setValue('localimage', '');
                          setValue('localimageFileName', '');
                        }}
                        icon={<CloseOutlined />}
                        disabled={!watch('localimage')}
                      />
                    }
                    prefix={
                      <Button
                        type='text'
                        onClick={() => handleCropperImage(ActionTypes.localimage, 'localimage', 500, 500)}
                        disabled={!isPresential}
                        icon={<UploadOutlined />}
                      />
                    }
                  />
                </Space>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Col span={24}>
          <Controller
            name='scheduletexthtml'
            control={control}
            render={({ field: { value, onChange } }) => (
              <Form.Item label='Programação'>
                <fieldset>
                  <ReactQuill
                    onFocus={() => handleFocus(ActionTypes.reactQuillClassNamePromation, true)}
                    onBlur={() => handleFocus(ActionTypes.reactQuillClassNamePromation, false)}
                    onKeyPress={handleVerifyData}
                    value={value}
                    onChange={onChange}
                  />
                </fieldset>
              </Form.Item>
            )}
          />
        </Col>

        <Col span={24}>
          <ButtonWrapper>
            <div>
              <Button size='large' onClick={handleReturn} disabled={isSaving} icon={<ArrowLeftOutlined />}>
                Voltar
              </Button>
            </div>
            <ActionWrapper>
              <Button size='large' onClick={() => handleSaveForm(true)} disabled={isSaving} icon={<FileTextOutlined />}>
                Salvar rascunho
              </Button>
              <Tooltip title={!existentSaleUrl ? 'Adicione a url da página de vendas' : ''}>
                <Button
                  size='large'
                  onClick={handlePreview}
                  disabled={isSaving || !existentSaleUrl}
                  icon={<EyeOutlined />}
                >
                  Visualizar
                </Button>
              </Tooltip>
              <Button size='large' type='primary' onClick={() => handleSaveForm(false)} disabled={isSaving}>
                Salvar
              </Button>
            </ActionWrapper>
          </ButtonWrapper>
        </Col>
      </Form>
      <ModalWarning
        title='Atenção'
        content='Você não salvou suas alterações, deseja continuar sem salvar? Se continuar, suas alterações não serão mostradas.'
        isOpen={!!continueModalWarning}
        onCancel={() => setContinueModalWarning(null)}
        onContinue={continueModalWarning}
      />
    </>
  );
};

export default EventPageForm;
