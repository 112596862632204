declare let hj: any;

export const getVideoUrl = (value: string) => {
  const regexYoutube =
    /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^?&"""'>]+)/;
  const regexVimeo = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;

  if (regexYoutube.test(value)) {
    return 'https://www.youtube.com/embed/' + value.match(regexYoutube)[1];
  }

  if (regexVimeo.test(value)) {
    const parseUrl = regexVimeo.exec(value);

    const id = parseUrl[5];
    return 'https://player.vimeo.com/video/' + id + '?title=0&byline=0&portrait=0';
  }

  return value;
};

export const hjTagging = (tag: string) => {
  if (typeof hj !== 'undefined') {
    hj('tagRecording', [tag]);
  }
};

export const stripTags = (content: string) => {
  if (typeof content !== 'undefined') {
    return content
      .replace(/<style([\S\s]*?)>([\S\s]*?)<\/style>/gi, '')
      .replace(/<script([\S\s]*?)>([\S\s]*?)<\/script>/gi, '')
      .replace(/( {2}|<([^>]+)>)/gi, '');
  }

  return '';
};

export const replaceAll = (text: string, search: string, replace: string) => {
  return text.split(search).join(replace);
};

/**
 * Converte um número digitado pelo usuário em um número de whatsapp válido no formato integer
 * Alguns exemplos de números aceitos:
 *  '1599144-8116' <- Sem o 0 do DDD
 *  '01591448116' <- Sem o 9
 *  '+55 015 9 9144-8116' Espaços, traços e caracteres especiais
 *  '+55 015 9144-8116' Com o 0 do DD mas um caracter invalido '+' no início
 * @param phone Numero de celular no formato string, podendo ou não conter o código do pais e/ou o 9.
 * @return objeto {status: 'error' ou 'success', value: 'Numero do whatsapp ou uma mensagem de erro'}
 */
export const whatsappBrNormalize = (phone: string) => {
  if (!Boolean(phone) || phone.trim().length === 0) {
    return {
      status: 'error',
      value: 'Celular não informado'
    };
  }

  const charsToRemove = [' ', '+', '-', '.', '(', ')', ',', '*', '#', '@', ':', '/', '[', ']', '_'];
  charsToRemove.forEach(function (str) {
    phone = replaceAll(phone, str, '');
  });

  if (phone.length >= 15) {
    return {
      status: 'error',
      value: 'Este não é celular no formato brasileiro válido'
    };
  }

  if (phone.length <= 9) {
    return {
      status: 'error',
      value: 'Este não é um celular no formato brasileiro válido com DDD'
    };
  }

  const final8Numbers = phone.substr(phone.length - 8, 8);
  const restNumbers = phone.substr(0, phone.length - 8);

  if (restNumbers.length === 2) {
    /* Então é apenas o DDD */
    const ddd = restNumbers;
    return {
      status: 'success',
      value: '55' + ddd + '9' + final8Numbers
    };
  }

  if (restNumbers.length === 3) {
    /* Então pode ser 0DD ou DD9, ex: 015 ou 159 */
    if (restNumbers.substr(0, 1) === '0') {
      const ddd = restNumbers;
      return {
        status: 'success',
        value: '55' + ddd + '9' + final8Numbers
      };
    }

    const dddWith9 = restNumbers.substr(0, 3); /* DD9, ex: 159 */
    return {
      status: 'success',
      value: '55' + dddWith9 + final8Numbers
    };
  }

  if (restNumbers.length === 4) {
    /* Então pode ser 0DD+9 ou CP+DD (CP = Codigo Pais), ex: 0159 ou 5515 */
    if (restNumbers.substr(0, 1) === '0') {
      /* 0DD+9, ex: 0159  */
      const dddWith9 = restNumbers.substr(0, 4);
      return {
        status: 'success',
        value: '55' + dddWith9 + final8Numbers
      };
    }

    const cpAndDdd = restNumbers.substr(0, 4); /* CP+DD, ex: 5515  */
    return {
      status: 'success',
      value: cpAndDdd + '9' + final8Numbers
    };
  }

  if (restNumbers.length === 5) {
    /* Então pode ser CP+DD+9 ou CP+0DD (CP = Codigo Pais), ex: 55159 ou 55015  */
    if (restNumbers.substr(restNumbers.length - 1, 1) === '9') {
      /* Então último digito é 9   */
      const cpWithDddAnd9 = restNumbers.substr(0, 5);
      return {
        status: 'success',
        value: cpWithDddAnd9 + final8Numbers
      };
    }

    const cpAndDdd = restNumbers.substr(0, 5); /* CP+0DD, ex: 55015  */
    return {
      status: 'success',
      value: cpAndDdd + '9' + final8Numbers
    };
  }

  /* restNumbers.length == 6. Então só pode ser CP+0DD+9 (CP = Codigo Pais), ex: 550159  */

  if (restNumbers.substr(0, 2) !== '55') {
    /* Então não é Brasileiro  */
    return {
      status: 'error',
      value: 'Este não é um celular no formato brasileiro'
    };
  }

  const cpAndDddAnd9 = restNumbers.substr(0, 6); /* CP+0DD, ex: 55015  */
  return {
    status: 'success',
    value: cpAndDddAnd9 + final8Numbers
  };
};

export const firstAndLastName = (fullName: string) => {
  const nameParts = fullName.trim().split(' ');
  const countParts = nameParts.length;

  if (countParts === 1) {
    return fullName.trim();
  }

  const firstName = nameParts[0];
  const lastName = nameParts[countParts - 1];
  return firstName + ' ' + lastName;
};

export const phoneMask = (phone: string) => {
  const phoneArray = phone.replace(/(\D)+/g, '').split('');

  const nineDigitsMask = (phone: string[]) => {
    phone.splice(0, 0, ' (');
    phone.splice(3, 0, ') ');
    phone.splice(9, 0, '-');
    return phone.join('');
  };

  const eightDigitsMask = (phone: string[]) => {
    phone.splice(0, 0, ' (');
    phone.splice(3, 0, ') ');
    phone.splice(8, 0, '-');
    return phone.join('');
  };

  const extraDigitsMask = (phone: string[]) => {
    phone.splice(0, 0, '+');
    return phone.join('');
  };

  if (phoneArray.length === 11) {
    return nineDigitsMask(phoneArray);
  }

  if (phoneArray.length === 10) {
    return eightDigitsMask(phoneArray);
  }

  if (phoneArray.length > 11) {
    const nineDigits = phoneArray.slice(phoneArray.length - 11);
    const extraDigits = phoneArray.slice(0, phoneArray.length - 11);
    return extraDigitsMask(extraDigits) + nineDigitsMask(nineDigits);
  }

  return phone;
};

export const cleanString = (str: string) => {
  if (!str) return;
  return str
    .replace(/[{()}.-/_/]/g, '')
    .replace(/\s/g, '')
    .split('-')
    .join('')
    .split('+')
    .join('');
};

export const docMask = (document: string) => {
  const docArray = document.replace(/(\D)+/g, '').split('');

  const cpfMask = (doc: string[]) => {
    doc.splice(3, 0, '.');
    doc.splice(7, 0, '.');
    doc.splice(11, 0, '-');
    return doc.join('');
  };

  const cnpjMask = (doc: string[]) => {
    doc.splice(2, 0, '.');
    doc.splice(6, 0, '.');
    doc.splice(10, 0, '/');
    doc.splice(15, 0, '-');
    return doc.join('');
  };

  if (docArray.length === 11) {
    return cpfMask(docArray);
  }

  if (docArray.length === 13 || docArray.length === 14) {
    return cnpjMask(docArray);
  }

  return document;
};

export const formatDateTimeFromPtBr = (dateTimePtBr: string) => {
  const dateSplitted = dateTimePtBr.split(' ');
  const strData = dateSplitted[0];
  const strHour = dateSplitted[1];

  const dateParts = strData.split('/');
  const hourParts = strHour.split(':');

  const year = parseInt(dateParts[2]);
  const month = parseInt(dateParts[1]) - 1;
  const day = parseInt(dateParts[0]);

  const hours = parseInt(hourParts[0] ? hourParts[0] : '0');
  const minutes = parseInt(hourParts[1] ? hourParts[1] : '0');
  const seconds = parseInt(hourParts[2] ? hourParts[2] : '0');

  return new Date(year, month, day, hours, minutes, seconds);
};

export const dateNormalizeIso8601Format = (date: any): Date => {
  if (typeof date == 'string') {
    date = new Date(date.replace(' ', 'T'));
  }

  return date;
};

export const convertDateToHumanPtBr = (date: any) => {
  date = dateNormalizeIso8601Format(date);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const strDay = day < 10 ? '0' + day : day;
  const strMonth = month < 10 ? '0' + month : month;
  const strHours = hours < 10 ? '0' + hours : hours;
  const strMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `${strDay}/${strMonth}/${year} às ${strHours}:${strMinutes}`;
};

export const mountLocation = (event: any) => {
  let location = '';

  if (!!event.street && event.street.length > 0) {
    location += !!location && location.length > 0 ? ' - ' : '';
    location += event.street;
  }

  if (!!event.number && event.number.length > 0) {
    location += !!location && location.length > 0 ? ', ' : '';
    location += event.number;
  }

  if (!!event.complement && event.complement.length > 0) {
    location += ' (' + event.complement + ')';
  }

  if (!!event.district && event.district.length > 0) {
    location += !!location && location.length > 0 ? ' - ' : '';
    location += event.district;
  }

  if (!!event.zip && event.zip.length > 0) {
    location += !!location && location.length > 0 ? ' - ' : '';
    location += 'CEP: ' + event.zip;
  }

  if (!!event.city && event.city.length > 0) {
    location += !!location && location.length > 0 ? ' - ' : '';
    location += event.city;
  }

  if (!!event.state && event.state.length > 0) {
    location += !!location && location.length > 0 ? ' - ' : '';
    location += event.state;
  }

  return location.trim();
};

export const isValidChar = (char: string) => {
  const regexValidUrl = /^[A-Za-z0-9_-]+$/;
  return regexValidUrl.test(char);
};

export const normalizeEventUrl = (eventTitle: string) => {
  const formattedUrl = eventTitle
    .replace(/ /g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  let finalValue = '';

  for (let i = 0; i < formattedUrl.length; i++) {
    const valid = isValidChar(formattedUrl.charAt(i));
    finalValue += valid ? formattedUrl.charAt(i) : '';
  }

  return finalValue;
};

// funções para estilos

export const fontSizePxToRem = (size: number) => `${size / 16}rem`;

export const setPadding = (
  top: number | string,
  right: number | string,
  bottom: number | string,
  left: number | string
) => {
  const paddingTop = top === 0 || top ? { paddingTop: top } : {};
  const paddingRight = right === 0 || right ? { paddingRight: right } : {};
  const paddingBottom = bottom === 0 || bottom ? { paddingBottom: bottom } : {};
  const paddingLeft = left === 0 || left ? { paddingLeft: left } : {};

  return {
    ...paddingTop,
    ...paddingRight,
    ...paddingBottom,
    ...paddingLeft
  };
};

export const capitalizeFirstLetter = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const setMargin = (
  top: number | string,
  right: number | string,
  bottom: number | string,
  left: number | string
) => {
  const marginTop = top === 0 || top ? { marginTop: top } : {};
  const marginRight = right === 0 || right ? { marginRight: right } : {};
  const marginBottom = bottom === 0 || bottom ? { marginBottom: bottom } : {};
  const marginLeft = left === 0 || left ? { marginLeft: left } : {};

  return {
    ...marginTop,
    ...marginRight,
    ...marginBottom,
    ...marginLeft
  };
};

export const formatUrlWithUtm = (destiny: string, source: string, medium: string) => {
  const urlHasParams = destiny.includes('?');
  const urlParamsSeparator = urlHasParams ? '&' : '?';

  const utmParams = `utm_source=${source}&utm_medium=${medium}`;

  return destiny + urlParamsSeparator + utmParams;
};

export const strTimeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};
