import React, { useMemo } from 'react';

import { StyledEngineProvider } from '@mui/material/styles';
import useCustomObservable from 'hooks/useCustomObservable';
import userService from 'services/user/index';
import { User } from 'services/user/types';
import { ThemeProvider } from 'styled-components';

import breakpoints from './breakpoints';
import { oldHoustonTheme } from './oldhoustontheme';
import clubBlackTheme from './themes/clubBlack';
import defaultTheme from './themes/default';
import { CustomHoustonTheme, StyledHoustonThemeProps } from './types';

const StyledHoustonTheme = ({ children }: StyledHoustonThemeProps) => {
  const { value: userData } = useCustomObservable<User, void, void>(() => userService.getUser(), null);

  const userTheme = useMemo(() => (userData?.is_clube_black ? clubBlackTheme : defaultTheme), [userData]);

  const customHounstonTheme: CustomHoustonTheme = useMemo(
    () => ({ ...oldHoustonTheme, customBreakpoints: breakpoints, user: userTheme }),
    [userTheme]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customHounstonTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default StyledHoustonTheme;
