import { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Breadcrumb, notification } from 'antd';

import Grid from '@mui/material/Grid';
import { isAfter } from 'date-fns/isAfter';
import useCustomObservable from 'hooks/useCustomObservable';
import { IEventForm } from 'interfaces/event';
import userService from 'services/user';
import { User } from 'services/user/types';

import editEventService from './service';
import * as S from './styles';
import { PageParams } from './types';
import VerifyEventDetails from '../../../components/Pages/Admin/EventEdit/VerifyEventDetails';
import EditEventTabs from '../components/EditEventTabs';
import Form from '../components/Form';
import { FormContextProvider } from '../Context';

const EditEvent = () => {
  const [eventData, setEventData] = useState<IEventForm>(null);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [jobsLotPending, setJobsLotPending] = useState('');

  const history = useHistory();

  const { id: eventId } = useParams<PageParams>();

  const { value: userData } = useCustomObservable<User, void, void>(() => userService.getUser(), null);

  const handleEdit = (data: IEventForm) => {
    setLoadingEdit(true);

    const dataValues: IEventForm = { ...data, api_key: userData?.api_key };

    editEventService.editEvent(dataValues).subscribe({
      next: response => {
        notification.success({ message: 'Evento editado com sucesso.' });
        setLoadingEdit(false);

        const jobsPending = response?.jobs_lot_pending ? response?.jobs_lot_pending : 0;
        const textJobsPending =
          'Aguarde, sincronizando com a Eduzz sua última alteração. Faltam ' + jobsPending + ' itens para sincronizar.';
        setJobsLotPending(!jobsPending ? '' : textJobsPending);

        loadEventDetails(eventId);
      },
      error: ({ data: err }) => {
        let loadingValue = false;

        if (err.code === 'ERR_CUSTOM') {
          const msgError = err.details;

          if (typeof msgError === 'string') {
            notification.error({ message: msgError });
          }
        }

        if (err.statusText === 'Network Error') {
          loadingValue = true;
          window.location.reload();
        }

        setLoadingEdit(loadingValue);
      }
    });
  };

  const loadEventDetails = (eventId: string) => {
    editEventService.detail(eventId).subscribe({
      next: response => {
        setEventData(response);
        const jobsPending = response?.jobs_lot_pending ? response?.jobs_lot_pending : 0;
        const textJobsPending =
          'Aguarde, sincronizando com a Eduzz sua última alteração. Faltam ' + jobsPending + ' itens para sincronizar.';
        setJobsLotPending(!jobsPending ? '' : textJobsPending);
      }
    });
  };

  const loadEventDetailsJobsLotPending = (eventId: string) => {
    editEventService.detailJobsLotPending(eventId).subscribe({
      next: response => {
        const jobsPending = response?.jobs_lot_pending ? response?.jobs_lot_pending : 0;
        const textJobsPending =
          'Aguarde, sincronizando com a Eduzz sua última alteração. Faltam ' + jobsPending + ' itens para sincronizar.';
        setJobsLotPending(!jobsPending ? '' : textJobsPending);
      }
    });
  };

  useEffect(() => {
    loadEventDetails(eventId);
  }, [eventId]);

  useEffect(() => {
    if (!!eventData && isAfter(new Date(), eventData.dateEnd)) {
      history.push(`../../my-events/${eventData.id}/basic-data`);
    }
  }, [eventData, history]);

  return (
    <Grid container spacing={2} style={{ marginBottom: 40 }}>
      <Grid item xs={12}>
        <Breadcrumb
          items={[
            {
              title: <Link to='../../'>Resumo</Link>
            },
            {
              title: <Link to='../../my-events'>Meus eventos</Link>
            },
            {
              title: <Link to={`../../my-events/${eventData?.eventId}/basic-data`}>{eventData?.title}</Link>
            },
            {
              title: 'Editar'
            }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <S.Title>Editar evento</S.Title>
      </Grid>
      <VerifyEventDetails
        loadEventDetailsAction={() => loadEventDetailsJobsLotPending(eventId)}
        jobsLotPending={jobsLotPending}
      />
      <FormContextProvider handleEdit={handleEdit} loadingEdit={loadingEdit} jobsLotPending={jobsLotPending}>
        <Grid item xs={12}>
          <EditEventTabs />
        </Grid>
        <Grid item xs={12}>
          {eventData && <Form defaultValue={eventData} />}
        </Grid>
      </FormContextProvider>
    </Grid>
  );
};
export default EditEvent;
