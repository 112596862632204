import { IImageSelectorResult } from 'components/Shared/ImageSelector';

export enum ActionTypes {
  backgroundOption = 'option/background',
  textColorHeader = 'field/HeaderColor',
  textColorBackground = 'field/BackgroundColor',
  cropperConfig = 'config/Cropper',
  cropperResetConfig = 'config/ResetCropper',
  imageHeader = 'image/Header',
  imageBackground = 'image/Background',
  localimage = 'image/local',
  resumeimage = 'image/resume',
  reactQuillClassNamePromation = 'className/Promation',
  reactQuillClassNameDescription = 'className/Description'
}

export interface CropperConfigProps {
  opened: boolean;
  width: number;
  height: number;
  onComplete: (value: IImageSelectorResult) => void;
}

export interface StateProps {
  backgroundOption: string;
  textColorHeader: string;
  textColorBackground: string;
  cropperConfig: CropperConfigProps;
  imageHeader: IImageSelectorResult;
  imageBackground: IImageSelectorResult;
  localimage: IImageSelectorResult;
  resumeimage: IImageSelectorResult;
  reactQuillClassNamePromation: string;
  reactQuillClassNameDescription: string;
}

export interface ActionProps {
  type: ActionTypes;
  value?: string | CropperConfigProps | IImageSelectorResult;
}

export interface EventHeadingProps {
  form: any;
}
