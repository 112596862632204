import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Modal, Button, Typography, Radio, Form, Divider } from 'antd';

import Field from 'components/Shared/Field';

import IndividualDialog from './IndividualDialog';
import MultipleDialog from './MultipleDialog';
import { EventDetailContext } from '../../HeaderList';

const listItem = { display: 'flex', alignItems: 'center', gap: '20px', padding: '9px' } as const;

const AddMethodDialog = () => {
  const { isOpen, onClose } = React.useContext(EventDetailContext);

  const form = useForm({
    defaultValues: {
      participantType: ''
    }
  });
  const participantType = form.watch('participantType');

  const [isOpenIndividualDialog, setIsOpenIndividualDialog] = React.useState(false);
  const [isOpenMultipleDialog, setIsOpenMultipleDialog] = React.useState(false);

  const onClick = () => {
    if (participantType === 'addMultiple') {
      setIsOpenMultipleDialog(true);
      onClose();
    }

    if (participantType === 'addIndividual') {
      setIsOpenIndividualDialog(true);
      onClose();
    }
  };

  const onCloseMultipleDialog = () => {
    setIsOpenMultipleDialog(false);
    onClose();
  };

  const onCloseIndividualDialog = () => {
    setIsOpenIndividualDialog(false);
    onClose();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={onClose}
        destroyOnClose
        title={
          <>
            <Typography.Title level={4}>Adicionar Participantes</Typography.Title>
            <Divider />
          </>
        }
        zIndex={3000}
        footer={[
          <React.Fragment key='primary'>
            <Divider />
            <Button disabled={!participantType} type='primary' size='large' onClick={onClick} id='start-btn'>
              Começar
            </Button>
          </React.Fragment>
        ]}
      >
        <Typography.Text>Selecione o tipo de inclusão de participantes que você deseja.</Typography.Text>

        <FormProvider {...form}>
          <Form>
            <Field
              name='participantType'
              render={
                <Radio.Group style={{ width: '100%', marginTop: '24px' }}>
                  <div style={listItem}>
                    <UserOutlined />
                    <Typography.Text strong>Individual</Typography.Text>
                    <Radio value='addIndividual' style={{ marginLeft: 'auto' }} id='add-individual-radio-btn' />
                  </div>
                  <div style={listItem}>
                    <TeamOutlined />
                    <Typography.Text strong>Planilha</Typography.Text>
                    <Radio value='addMultiple' style={{ marginLeft: 'auto' }} id='add-multiple-radio-btn' />
                  </div>
                </Radio.Group>
              }
            />
          </Form>
        </FormProvider>
      </Modal>

      {isOpenMultipleDialog && <MultipleDialog open={isOpenMultipleDialog} onClose={onCloseMultipleDialog} />}
      {isOpenIndividualDialog && <IndividualDialog open={isOpenIndividualDialog} onClose={onCloseIndividualDialog} />}
    </>
  );
};

export default AddMethodDialog;
