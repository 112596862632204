import React from 'react';

import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { IProps } from './types';

function Loading({ classes, content, spacing, showLoading, center, children }: IProps) {
  function getMaxLines(height: number) {
    const line = {
      max: 100,
      min: 60
    };

    const lineHeight = 28;
    const maxLines = Math.trunc(height / lineHeight);
    const sizes: Array<number> = [];

    for (let i = 0; i <= maxLines; i++) {
      let lineRandomSize = Math.floor(Math.random() * (line.max - line.min + 1) + line.min);

      if (sizes.includes(lineRandomSize) || lineRandomSize < line.min) {
        while (!sizes.includes(lineRandomSize) && lineRandomSize >= line.min) {
          lineRandomSize = Math.floor(Math.random() * (line.max - line.min + 1) + line.min);
        }
      }

      sizes.push(lineRandomSize);
    }

    return sizes;
  }

  function getMaxRows(height: number) {
    const lineHeight = 76;
    const maxRows = Math.trunc(height / lineHeight);
    const rows: Array<number> = [];

    for (let i = 0; i < maxRows; i++) {
      rows.push(i);
    }

    return rows;
  }

  if (!showLoading) {
    return children;
  }

  return (
    <div className={classes.loading}>
      <Grid container spacing={spacing ? spacing : 2} justifyContent={center ? 'center' : 'flex-start'}>
        {Array.isArray(content) &&
          content.map((el, index) => {
            return (
              <Grid
                item
                xs={el.sizes.xs}
                sm={el.sizes.sm ? el.sizes.sm : null}
                md={el.sizes.md ? el.sizes.md : null}
                lg={el.sizes.lg ? el.sizes.lg : null}
                key={`element-${index}`}
              >
                <div
                  className={`
                      ${el.type !== 'text' && el.type !== 'list' ? 'children' : ''}
                      element-${el.type}
                      ${el.shadow ? '--shadow' : ''}
                      ${center ? '--center' : ''}
                    `}
                  id={`element-${el.type}-${index}`}
                  style={{
                    height: el.height,
                    width: el.type === 'circle' ? el.height : '',
                    marginBottom: el.marginBottom ? el.marginBottom : 0
                  }}
                >
                  {el.type === 'text' &&
                    getMaxLines(el.height).map((row, index) => (
                      <span key={'line-' + index} style={{ width: row + '%' }} />
                    ))}

                  {el.type === 'list' && getMaxRows(el.height).map((row, index) => <span key={'row-' + index} />)}
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Loading);
