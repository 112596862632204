import React, { PureComponent } from 'react';

import { Button } from 'antd';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

interface IProps {
  classes?: any;
  open: boolean;
  onClose: () => void;
  saleUrl: string;
}

class DraftPreview extends PureComponent<IProps> {
  public render() {
    const { onClose, open, saleUrl, classes } = this.props;

    const url = `${process.env.REACT_APP_BLINKET_EVENT_URL}/${saleUrl}?preview=1&draft=1`;

    return (
      <Dialog fullScreen scroll='paper' open={open} onClose={onClose} aria-labelledby='responsive-dialog-title'>
        <DialogContent className={classes.draftContent}>
          <iframe
            id='event-page-preview'
            title='event-page-preview'
            src={url}
            style={{ width: '100%', height: '100%' }}
            frameBorder='0'
          />
        </DialogContent>
        <DialogActions>
          <Button type='primary' onClick={onClose} id='myevents-draftpreview-close-button'>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DraftPreview);
