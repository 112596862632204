import createStyles from '@mui/styles/createStyles';
import backgroundTransparency from 'assets/images/transparency.png';
import { fontSizePxToRem, setMargin, setPadding } from 'helpers/functions';

const styles = theme =>
  createStyles({
    imageContainer: {
      background: `url('${backgroundTransparency}') repeat`,
      margin: 'auto'
    },
    content: {
      overflow: 'unset',
      width: '100%',
      maxHeight: 'calc(100vh - 140px)',
      paddingBottom: 12,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 0
      }
    },
    title: {
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizePxToRem(14),
        lineHeight: '150%',
        display: 'block'
      }
    },
    subtitle: {
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizePxToRem(10),
        lineHeight: '150%',
        display: 'block'
      }
    },
    progress: {
      ...setMargin(null, 5, null, null)
    },

    dropArea: {
      ...setPadding(null, null, 20, null),
      width: '100%',
      maxHeight: '100%',
      border: '2px dashed #00000029',
      borderRadius: '3px',
      textAlign: 'center',
      height: 180,
      [theme.breakpoints.down('md')]: {
        ...setPadding(null, null, 0, null),
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      }
    },
    selectButton: {
      [theme.breakpoints.down('md')]: {
        marginLeft: 4,
        fontSize: fontSizePxToRem(12)
      }
    },
    text: {
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizePxToRem(12)
      }
    },

    dropAreaProgress: {
      ...setMargin(60, null, null, null)
    },

    dropAreaDragging: {
      background: '#fff10a1f'
    },

    dropAreaDraggingChildren: {
      pointerEvents: 'none'
    }
  });

export default styles;
