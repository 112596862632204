const lineHeight = {
  compact: 1.2,
  comfortable: 1.8,
  normal: 1.5
} as const;

const font = {
  size: {
    xxxs: '0.75rem',
    xxs: '0.875rem',
    xs: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '2.5rem',
    xxl: '3rem',
    xxxl: '4rem',
    display: '5rem',
    giant: '6rem'
  },
  family: {
    base: 'Open Sans, "Helvetica Neue", Arial, sans-serif'
  },
  weight: {
    regular: 400,
    semibold: 600,
    bold: 700
  }
};

export const oldHoustonTheme = {
  lineHeight: (size: keyof typeof lineHeight) => lineHeight[size],
  fontWeight: (size: keyof (typeof font)['weight']) => font.weight[size],
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    },
    unit: 'px'
  },
  colors: {
    primary: {
      main: '#FFBC00',
      contrastText: '#000000',
      light: '#78909C'
    },
    grey: {
      '50': '#fafafa',
      '100': '#F9FAFB',
      '200': '#ECEFF1',
      '300': '#B0BEC5',
      '400': '#78909C',
      '500': '#546E7A',
      '600': '#37474F',
      '700': '#373737',
      '800': '#272727',
      '900': '#1E1E1E',
      'A100': '#1E1E1E',
      'A200': '#1E1E1E',
      'A400': '#1E1E1E',
      'A700': '#1E1E1E'
    },
    secondary: {
      light: '#ffffff',
      main: '#0D2772',
      contrastText: '#000000',
      dark: '#c79c00'
    },
    info: {
      light: '#CDEBFA',
      main: '#039BE5',
      dark: '#0277B0',
      contrastText: '#fff'
    },
    success: {
      light: '#D2EBD3',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#fff'
    },
    text: {
      disabled: '#B0BEC5',
      hint: '#B0BEC5',
      primary: '#37474F',
      secondary: '#102027'
    },
    warning: {
      main: '#FFCA28',
      dark: '#E5AD00',
      light: '#FFF4D4',
      contrastText: '#37474F'
    }
  }
};
