import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Typography } from 'antd';

import TemplateOrbit from 'assets/images/template-orbit.png';
import { useEventDetail } from 'components/Pages/Admin/TabsMyEvents/context/EventDetail';
import CustomLoader from 'components/Shared/CustomLoader';
import Tour from 'components/Shared/Tour';
import { getTargetElement } from 'components/Shared/Tour/helpers';
import { TourSteps } from 'components/Shared/Tour/types';
import useTabTour from 'hooks/useTabTour';
import ReactPlaceholder from 'react-placeholder/lib';

import { PageParams } from '../BasicData/types';
import PageTitle from '../components/PageTitle';

const steps: TourSteps[] = [
  {
    title: 'Página do evento',
    description:
      'Crie uma página de vendas de forma gratuita para oferecer os ingressos do seu evento. Customize de acordo com sua identidade visual.',
    target: getTargetElement("div[data-node-key='event-page']")
  },
  {
    title: 'Página do evento',
    description:
      'Para uma página mais completa e profissional, selecione o modelo “Orbit Pages“. É grátis até 3 páginas criadas, aproveite!',
    target: getTargetElement('div#template-box-orbit'),
    placement: 'top',
    isLastStep: true
  }
];

export default function EventPage() {
  const { id: eventId } = useParams<PageParams>();
  const { data: eventPageData, loading } = useEventDetail();
  const history = useHistory();

  const { showTour, openTour, closeTour } = useTabTour('event-page');

  const isShown = useMemo(() => !loading && !!eventPageData, [loading, eventPageData]);

  const handleCustomTemplate = () => {
    history.push('event-page/template-basic');
  };

  const eventHasSalePage = eventId !== eventPageData?.advertisementLinks?.saleLink;

  return (
    <>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={!loading}>
        {isShown && (
          <>
            <PageTitle
              title='Página do evento'
              subtitle='Crie e personalize sua página de vendas do evento'
              onIconClick={openTour}
            />
            {!eventHasSalePage && (
              <Typography style={{ marginBottom: '24px' }}>
                <b>Você ainda não tem uma página de vendas!</b>
              </Typography>
            )}
            <div
              id='template-box-orbit'
              style={{ display: 'flex', alignItems: 'center', gap: '24px', marginBottom: '24px' }}
            >
              <img src={TemplateOrbit} alt='Imagem do estilo de template Orbit' />
              <a
                href={eventPageData.partnerConfig.partnerEditUrl}
                target='_blank'
                rel='noopener noreferrer'
                id='resume-live-goto-button'
              >
                Crie uma página de vendas focada em conversão em poucos minutos com o <b>Orbit Pages</b>
              </a>
            </div>
            {!eventHasSalePage ? (
              <span>
                Ou crie uma página de vendas na Blinket <a onClick={handleCustomTemplate}>clicando aqui</a>
              </span>
            ) : (
              <span>
                Você já tem uma página de vendas!
                <a onClick={handleCustomTemplate}> Clique aqui para editar sua página</a>
              </span>
            )}
          </>
        )}
      </ReactPlaceholder>
      {isShown && <Tour steps={steps} open={showTour} onClose={closeTour} />}
    </>
  );
}
