import styled from 'styled-components';

export const Container = styled.div`
  .tickets {
    display: flex;
    margin: 8px 0 0 0;
    flex-direction: column;
    background: #eceff1;
    padding: 18px 12px;
    justify-content: space-between;
    margin-bottom: 24px;
  }
`;
