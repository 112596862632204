import React from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { formatUrlWithUtm } from 'helpers/functions';
import { VERTICAL_URL } from 'settings';

import styles from './styles';
import { IProps } from './types';

const TelescopeCard = ({ classes }: IProps) => {
  const telescopeUrl = formatUrlWithUtm(`${VERTICAL_URL}/analytics`, 'blinket', 'dashboard');

  return (
    <Paper className={classes.root} variant='outlined' square>
      <div className={classes.title}>
        <h5>Analytics</h5>
      </div>
      <Grid container alignItems='center'>
        <Grid item md={8} xs={12}>
          <p className={classes.description}>Ferramenta da Eduzz de análise e monitoramento de vendas em tempo real.</p>
        </Grid>
        <Grid item md={4} xs={12}>
          <Button icon={<ArrowRightOutlined />}>
            <a href={telescopeUrl} target='_blank' rel='noopener noreferrer'>
              Acessar
            </a>
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(TelescopeCard);
