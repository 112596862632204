import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from 'antd';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IProps extends RouteComponentProps {
  open: boolean;
  eventId: string;
  handleClose: () => void;
  isLive: boolean;
}

function DuplicateEvent({ open, handleClose, eventId, isLive, history }: IProps) {
  const [loading, setLoading] = useState(false);

  const confirmDuplicateEvent = () => {
    setLoading(true);

    if (isLive) {
      return history.push(`/event-duplicate-live/${eventId}`);
    }

    history.push(`/event-duplicate/${eventId}`);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Deseja duplicar este Evento?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Você será redirecionado para a criação do evento com os dados previamente preenchidos, sendo necessário
          avançar até a última etapa para salvar o evento.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type='text' disabled={loading} onClick={handleClose} id='myevents-duplicate-cancel-button'>
          Cancelar
        </Button>

        <Button
          type='primary'
          autoFocus
          disabled={loading}
          id='myevents-duplicate-confirm-button'
          onClick={() => confirmDuplicateEvent()}
        >
          Duplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(DuplicateEvent);
