import React, { useEffect, useState } from 'react';

import { Button, Col, Divider, Form, Input, InputNumber, Modal, notification, Row, Switch, Typography } from 'antd';

import { CustomFieldsForm } from 'components/Shared/CustomFieldsForm';
import { createPayload } from 'helpers/customFieldsHelper';
import { cleanString } from 'helpers/functions';
import { cpfMask, phoneMask, stripPhoneMask } from 'helpers/masks';
import { validateCpf } from 'helpers/validations';
import { IEditPresence } from 'interfaces/attendanceList';
import { IEvent } from 'interfaces/event';
import attendanceListService from 'services/attendanceList';

type EditParticipantDialogProps = {
  data: IEditPresence;
  open: boolean;
  eventDetail: Partial<IEvent>;
  onCancel: () => void;
  onComplete: (newData: IEditPresence) => void;
  changeStatus?: string;
};

const baseRules = [
  { whitespace: true, message: 'O campo não pode ficar em branco' },
  { required: true, message: 'Este campo é obrigatório' }
];

export const EditParticipantDialog = ({
  data,
  eventDetail,
  open,
  onCancel,
  onComplete,
  changeStatus
}: EditParticipantDialogProps) => {
  const customFields = eventDetail.attendance_custom_fields ?? [];
  const hasCustomFields = customFields.length > 0;
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInternationalDoc, setIsInternationalDoc] = useState(false);
  const [isInternationalPhone, setIsInternationalPhone] = useState(false);

  const onFinish = async values => {
    setIsSubmitting(true);

    const payload = createPayload({
      ...values,
      phone: isInternationalPhone ? `+${values.ddi} ${values.phone}` : `+55 ${stripPhoneMask(values.phone)}`,
      document_type: isInternationalDoc ? 'passport' : 'cpf',
      id: data.id,
      status: changeStatus ?? 'presence_confirmed',
      email: values.email.toLowerCase()
    });

    attendanceListService.editByInviteKey(payload).subscribe(
      () => {
        notification.success({ message: 'Dados alterados com sucesso' });
        setIsSubmitting(false);
        onComplete(payload as IEditPresence);
      },
      ({ data: err }) => {
        notification.error({ message: err });
      }
    );
    setIsSubmitting(false);
  };

  const handlePasteDocument = e => {
    const document = e.clipboardData.getData('Text');
    form.setFieldValue('document', cpfMask(document));
    form.validateFields(['document']);
  };

  const handlePastePhone = e => {
    const phone = e.clipboardData.getData('Text');
    form.setFieldValue('phone', phoneMask(phone));
  };

  const onIsInternationalDocChange = boolean => {
    if (boolean) {
      form.setFieldValue('phone', cleanString(form.getFieldValue('phone')));
      form.setFieldValue('document', cleanString(form.getFieldValue('document')));
    }

    form.setFieldValue('is_international_doc', boolean);
    setIsInternationalDoc(boolean);
  };

  useEffect(() => {
    if (!data) return;

    if (data.document_type !== 'cpf') {
      setIsInternationalDoc(true);
      form.setFieldValue('is_international_doc', true);
    } else {
      form.setFieldValue('document', cpfMask(data.document));
    }

    if (!data?.phone) return;

    // telefone no formato +55 (83) 99856-9846
    const brPhoneMatch = data?.phone?.match(/(^\+?5{2})/gm);

    if (brPhoneMatch) {
      const formattedPhone = stripPhoneMask(data?.phone);
      form.setFieldValue('phone', phoneMask(formattedPhone.slice(2)));
      return;
    }

    // telefone no formato + (68) 92560-7149
    const firstFormatMatch = data?.phone?.match(/^\+\ \(/);
    // telefone no formato 15963259785 ou (42) 3626-6261
    const secondFormatMatch = data?.phone?.match(/(^\([0-9]{2}\))|^[0-9]+/gm);

    if (firstFormatMatch || secondFormatMatch) {
      const formattedPhone = stripPhoneMask(data?.phone);
      form.setFieldValue('phone', phoneMask(formattedPhone));
      return;
    }

    // número internacional no formato +44 1234 567890
    const intlFormat = data?.phone?.match(/(^\+(?!55)[0-9]{1,3})/gm);

    if (intlFormat) {
      setIsInternationalPhone(true);
      const firstPart = data?.phone?.substring(0, data?.phone?.indexOf(' '));
      const secondPart = data?.phone?.substring(data?.phone?.indexOf(' ') + 1);

      if (firstPart && secondPart) {
        form.setFieldValue('ddi', cleanString(firstPart));
        form.setFieldValue('phone', cleanString(secondPart));
      } else {
        form.setFieldValue('phone', firstPart);
      }
    }
  }, [data, form]);

  const handleChangeCpf = e => {
    form.setFieldValue('document', cpfMask(e.target.value));
    form.validateFields(['document']);
  };

  const handleChangePhone = e => {
    form.setFieldValue('phone', phoneMask(e.target.value));
    form.validateFields(['phone']);
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={600}
      centered
      title={
        <>
          <Typography.Title level={4}>Editar Participante</Typography.Title>
          <Divider />
        </>
      }
      footer={[
        <React.Fragment key='primary'>
          <Divider />
          <Form.Item id='edit-participant-submit-button'>
            <Button
              loading={isSubmitting}
              type='primary'
              key='add-EditParticipant-btn'
              htmlType='submit'
              onClick={() => {
                if (form.validateFields()) {
                  form.submit();
                }
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Aguarde...' : 'Concluir'}
            </Button>
          </Form.Item>
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <Form layout='vertical' form={form} onFinish={values => onFinish(values)} initialValues={{ ddi: '55' }}>
        <Row gutter={[10, 8]}>
          <Col xs={24} md={12}>
            <Form.Item
              name='name'
              label='Nome'
              id={'name-form-item'}
              required
              rules={[
                ...baseRules,
                { min: 4, message: 'Mínimo 4 caracteres' },
                { pattern: /^[A-zÀ-ú\s]+$/, message: 'Apenas letras são permitidas' }
              ]}
              initialValue={data.name}
            >
              <Input required id='presencelist-participantdetails-dialog-name-input' />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name='email'
              label='E-mail'
              id={'email-form-item'}
              required
              rules={[{ ...baseRules, type: 'email', message: 'Adicione um email válido' }]}
              initialValue={data.email}
            >
              <Input required id='presencelist-participantdetails-dialog-email-input' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 8]}>
          <Col xs={24} md={12}>
            <Form.Item
              name='document'
              id={'doc-form-item'}
              label={isInternationalDoc ? 'Documento' : 'CPF'}
              required
              initialValue={data.document}
              rules={[
                ...baseRules,
                { max: 20, message: 'Máximo 20 caracteres' },
                () => ({
                  validator(_, value) {
                    if (isInternationalDoc) return Promise.resolve();
                    const cpf = cleanString(value);

                    if (validateCpf(cpf)) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error('CPF inválido'));
                  }
                })
              ]}
            >
              {isInternationalDoc ? (
                <Input required id='presencelist-participantdetails-dialog-docnumber-input' maxLength={20} />
              ) : (
                <Input
                  placeholder='Ex.: 999.888.777-66'
                  maxLength={20}
                  key='participant-cpf-input'
                  onPaste={handlePasteDocument}
                  onChange={e => handleChangeCpf(e)}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} style={{ alignContent: 'end' }}>
            <Form.Item
              name='is_international_doc'
              id={'doc-type-form-item'}
              initialValue={data.document_type === 'cpf' ? false : true}
            >
              <div style={{ display: 'flex' }}>
                <Switch
                  checked={isInternationalDoc}
                  style={{ marginRight: '5px' }}
                  onChange={onIsInternationalDocChange}
                />
                <Typography>É documento internacional?</Typography>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <Col span={isInternationalPhone ? 8 : 0}>
              <Form.Item name='ddi' label='DDI'>
                <InputNumber min={1} max={999} prefix={<span>+</span>} />
              </Form.Item>
            </Col>

            <Col span={isInternationalPhone ? 16 : 24}>
              {isInternationalPhone ? (
                <Form.Item
                  name='phone'
                  label='Telefone'
                  id={'phone-form-item'}
                  required
                  initialValue={data.phone}
                  rules={[
                    ...baseRules,
                    { min: 10, message: 'Número de telefone inválido' },
                    { max: 15, message: 'Número deve ter no máximo 15 dígitos' }
                  ]}
                >
                  <Input placeholder='Nº do telefone' maxLength={15} />
                </Form.Item>
              ) : (
                <Form.Item
                  name='phone'
                  label='Telefone'
                  id={'phone-form-item'}
                  required
                  initialValue={data.phone}
                  rules={[...baseRules, { min: 13, message: 'Número de telefone inválido' }]}
                >
                  <Input
                    placeholder='Código DDD + Nº do telefone'
                    maxLength={20}
                    onPaste={handlePastePhone}
                    onChange={e => handleChangePhone(e)}
                  />
                </Form.Item>
              )}
            </Col>
          </Col>
        </Row>
        {hasCustomFields && (
          <Row gutter={[10, 8]}>
            {customFields.map(field => (
              <CustomFieldsForm key={`${data.id}-${field.name}`} config={field} customFields={data.custom_fields} />
            ))}
          </Row>
        )}
      </Form>
    </Modal>
  );
};
