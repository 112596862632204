import createStyles from '@mui/styles/createStyles';
import { fontSizePxToRem, setMargin } from 'helpers/functions';

const styles = theme =>
  createStyles({
    root: {
      textAlign: 'center',
      padding: 20,
      width: 350,
      maxWidth: '100%',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        padding: 9
      }
    },
    icon: {
      opacity: 0.7
    },
    button: {
      ...setMargin(20, null, null, null)
    },
    text: {
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizePxToRem(12)
      }
    }
  });

export default styles;
