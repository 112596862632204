import * as React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as ReactLogo } from 'assets/images/new-blinket-logo.svg';

import { LoaderLine } from './Line';

const useStyles = makeStyles(() => ({
  '@global': {
    '#root': {
      paddingBottom: '0px !important'
    }
  },
  'root': {
    'position': 'fixed',
    'top': 0,
    'left': 0,
    'right': 0,
    'bottom': 0,
    'zIndex': 2147483002,
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'backgroundColor': 'rgba(255, 255, 255, 0.32)',
    'backdropFilter': 'blur(8px)',
    'animation': '$rootLoaderFadeIn ease-in-out 0.5s forwards',
    '& > div': {
      'width': '200px',
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',
      'flexDirection': 'column',
      'transition': '0.5s, width 0s',
      'marginTop': -20,
      '& > svg': {
        marginBottom: 20,
        width: 130
      }
    }
  },
  ['@keyframes rootLoaderFadeIn']: {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}));

const GlobalLoading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <ReactLogo />
        <LoaderLine />
      </div>
    </div>
  );
};

export default GlobalLoading;
