import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Radio } from 'antd';

import { IProps } from './types';

function ExportPdfDialog({ opened, onCancel, onComplete, loading }: IProps) {
  const [letterByPage, setLetterByPage] = React.useState('false');

  function handleChangeLetterByPage(type: string) {
    setLetterByPage(type);
  }

  return (
    <Modal
      open={opened}
      title={'Escolha o tipo de exportação'}
      closeIcon={<CloseOutlined onClick={onCancel} />}
      footer={
        <Button
          type='primary'
          onClick={() => onComplete(letterByPage)}
          disabled={loading}
          id='presencelist-exportpdfdialog-confirmbutton'
        >
          Exportar
        </Button>
      }
    >
      <Radio.Group
        value={letterByPage}
        onChange={(e: any) => handleChangeLetterByPage(e.target.value)}
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        <Radio value={'false'} id='presencelist-exportpdfdialog-radiosingle'>
          Lista única
        </Radio>
        <Radio value={'true'} id='presencelist-exportpdfdialog-radiobyletter'>
          Separar por letra
        </Radio>
      </Radio.Group>
    </Modal>
  );
}

export default ExportPdfDialog;
