import React, { PureComponent } from 'react';

import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { IProps } from './types';

class TableRow extends PureComponent<IProps> {
  public render(): JSX.Element {
    const { classes, children, onDoubleClick, onClick, id } = this.props;

    return (
      <>
        {children && (
          <tr
            className={onDoubleClick ? '--dbclick' : onClick && classes.tableSmall}
            onDoubleClick={onDoubleClick ? () => onDoubleClick() : null}
            onClick={
              onClick
                ? e => {
                    e.preventDefault();
                    onClick();
                  }
                : null
            }
            id={id}
          >
            {children}
          </tr>
        )}
      </>
    );
  }
}

export default withStyles(styles)(TableRow);
