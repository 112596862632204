import createStyles from '@mui/styles/createStyles';

const styles = theme =>
  createStyles({
    buttonSubmit: {
      background: theme.palette.primary.main,
      color: '#FFFFFF',
      width: 140
    },
    titleContent: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    dialogContent: { overflow: 'hidden' }
  });

export default styles;
