import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: auto;

  ${({ theme: { colors, fontWeight, lineHeight } }) => css`
    .event-key {
      font-size: 20px;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 6px;
      font-weight: ${fontWeight('semibold')};
    }

    .event-description {
      margin: 16px 0;
      max-width: 100%;

      @media (max-width: 499px) {
        .event-description-text-mobile {
          color: ${colors.primary.contrastText};
          font-size: 14px;
          line-height: ${lineHeight('compact')};
          margin: 8px 0 0 0;
        }

        .event-description-text-desk {
          display: none;
        }

        span {
          color: ${colors.primary.contrastText}
          font-size: 14px;
          font-weight: ${fontWeight('semibold')};
          margin: 0 0 0 5px;
          text-decoration: underline;
        }
      }

      @media(min-width: 500px) {
        .event-description-text-mobile {
          display: none;
        }

        .event-description-text-desk {
          color: ${colors.primary.contrastText};
          display: flex;
          font-size: 16px;
          margin: 8px 0 0 0;
        }
      }

    }
  `}
`;
