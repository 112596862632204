import React from 'react';

import withStyles from '@mui/styles/withStyles';
import { EditParticipantDialog } from 'components/Shared/EditParticipantDialog';
import { IEditPresence } from 'interfaces/attendanceList';

import styles from './styles';
import { IProps } from './types';

const EditPresenceDialog: React.FC<IProps> = ({
  opened,
  data,
  dataList,
  eventDetail,
  onCancel,
  handleUpdateParentState,
  handleUpdateAttendanceDetail
}) => {
  const onComplete = (newData: Partial<IEditPresence>) => {
    handleUpdateParentState({
      data: dataList.map((item: any) => {
        if (item.invite_key === newData.id) {
          item = { ...item, ...newData };
        }

        return item;
      }),
      isOpenEdit: false
    });
    handleUpdateAttendanceDetail();
  };

  return (
    <>
      <EditParticipantDialog
        data={data}
        open={opened}
        onCancel={onCancel}
        onComplete={onComplete}
        changeStatus={data.status}
        eventDetail={eventDetail}
      />
    </>
  );
};

export default withStyles(styles)(EditPresenceDialog);
