import React from 'react';

import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import { Typography } from 'antd';

import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { IProps } from './types';

function IconMessage({ classes }: IProps) {
  return (
    <div className={classes.root}>
      <DownloadOutlined style={{ fontSize: 30 }} />
      <Typography className={classes.text}>Arraste e solte a imagem aqui</Typography>
    </div>
  );
}

export default withStyles(styles)(IconMessage);
