import { IEvent } from 'interfaces/event';
import { IOffer } from 'interfaces/offer';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';
import eventService from 'services/event';
import offerService from 'services/offers';
import { AddOffers, GetOfferLink, DeleteOffer, DeleteOfferResponse } from 'services/offers/types';
import { CDN_URL } from 'settings';

import { EventData } from './types';

class CustomOffersService {
  public get(id: string): Rx.Observable<EventData> {
    return eventService.details<IEvent>(id).pipe(RxOp.map(response => this.getEventData(response)));
  }

  public getCustomOffers(id: string): Rx.Observable<IOffer[]> {
    return offerService.listOffers(id).pipe(RxOp.map(response => response));
  }

  public addCustomOffers(data: AddOffers): Rx.Observable<any> {
    return offerService.addOffer(data).pipe(RxOp.map(response => response));
  }

  public deleteCustomOffers(data: DeleteOffer): Rx.Observable<DeleteOfferResponse> {
    return offerService.deleteOffer(data).pipe(RxOp.map(response => response));
  }

  public createCustomOffersLink(offerId: string): Rx.Observable<GetOfferLink> {
    return offerService.mountCart(offerId).pipe(RxOp.map(response => response));
  }

  private getEventData(data: IEvent): EventData {
    return {
      eventHeader: {
        isLive: data.is_live,
        title: data.title,
        eventId: data.id,
        date: { start: `${data.start_date} às ${data.start_hour}`, end: `${data.end_date} às ${data.end_hour}` },
        image: CDN_URL + data?.image_path
      },
      eventTicks: data.tickets
    };
  }
}

const customOffersService = new CustomOffersService();
export default customOffersService;
