import React from 'react';

import Fade from '@mui/material/Fade';
import withStyles from '@mui/styles/withStyles';
import Loading from 'components/Shared/Loading';
import TableCol from 'components/Shared/Table/components/TableCol';

import TableRow from '../TableRow';
import styles from './styles';
import { IProps } from './types';

function TableBody({ children, loading, rows, emptyMessage }: IProps) {
  if (loading) {
    return (
      <>
        <tbody>
          <Fade timeout={500}>
            <tr style={{ background: 'none' }}>
              <td colSpan={1000}>
                <Loading showLoading={loading} content={[{ type: 'list', sizes: { xs: 12 }, height: 380 }]} />
              </td>
            </tr>
          </Fade>
        </tbody>
      </>
    );
  }

  if (!children || children === '' || rows === 0) {
    return (
      <>
        <tbody>
          <TableRow>
            <TableCol
              xs={{ size: 12 }}
              sm={{ size: 12 }}
              colSpan={1000}
              type='not-found'
              value={emptyMessage || 'Nenhum registro encontrado...'}
            />
          </TableRow>
        </tbody>
      </>
    );
  }

  return (
    <>
      <tbody>{children}</tbody>{' '}
    </>
  );
}

export default withStyles(styles, { withTheme: true })(TableBody);
