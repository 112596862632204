import { Col, DatePicker, Form, Input, Select } from 'antd';

import dayjs from 'dayjs';
import { parseRules } from 'helpers/customFieldsHelper';
import { EventAttendanceCustomFields, SelectOptions } from 'interfaces/event';

export type CustomFields = {
  name: string;
  type: string;
  label: string;
  rules: string[];
  fullWidth: boolean;
  options?: SelectOptions[];
};

type Props = {
  config: EventAttendanceCustomFields;
  customFields?: { [key: string]: string | number };
  inviteKey?: string;
  ignoreFullWidth?: boolean;
};

export const CustomFieldsForm = ({ config, customFields, inviteKey, ignoreFullWidth }: Props) => {
  const { name, label, fullWidth, options } = config;
  const isRequired = config.rules.includes('required');

  if (!customFields || Object.keys(customFields).length === 0) {
    customFields = {};
  }

  const customKey = inviteKey ? `${inviteKey}-` : '';
  const width = ignoreFullWidth ? 12 : fullWidth ? 24 : 12;

  switch (config.type) {
    case 'text':
      return (
        <Col xs={24} md={width}>
          <Form.Item
            name={`${customKey}custom_field.${name}`}
            label={label}
            id={`${customKey}${name}-custom-form-field`}
            key={`${customKey}${name}-custom-form-field`}
            required={isRequired}
            rules={[...parseRules(config.rules)]}
            initialValue={customFields[name]}
          >
            <Input id={`${customKey}${name}-custom-input`} />
          </Form.Item>
        </Col>
      );

    case 'date':
      return (
        <Col xs={24} md={width}>
          <Form.Item
            name={`${customKey}custom_field.${name}`}
            label={label}
            id={`${customKey}${name}-custom-form-field`}
            key={`${customKey}${name}-custom-form-field`}
            rules={[...parseRules(config.rules)]}
            initialValue={!!customFields[name] ? dayjs(customFields[name]) : ''}
          >
            <DatePicker
              id={`${customKey}${name}-custom-input`}
              key={`${customKey}${name}-custom-form-field`}
              format={'DD/MM/YYYY'}
              placeholder='Selecione a data'
              maxDate={dayjs()}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      );

    case 'select':
      const initialValue = options.find(option => option.value == customFields[name])?.value;
      return (
        <Col xs={24} md={width}>
          <Form.Item
            name={`${customKey}custom_field.${name}`}
            label={label}
            id={`${customKey}${name}-custom-form-field`}
            key={`${customKey}${name}-custom-form-field`}
            rules={[...parseRules(config.rules)]}
            initialValue={initialValue}
          >
            <Select
              id={`${customKey}${name}-custom-input`}
              placeholder='Selecione uma opção'
              key={`${customKey}${name}-custom-form-field`}
              style={{ width: '100%' }}
              options={options}
            />
          </Form.Item>
        </Col>
      );

    default:
      throw 'Unknown custom field type ' + config.type;
  }
};
