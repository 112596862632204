import React from 'react';

import { Col, Row } from 'antd';

import withStyles from '@mui/styles/withStyles';
import TelescopeCard from 'components/Pages/Admin/MyEvents/Dashboard/TelescopeCard';
import { GroupUsersIcon, RefundIcon, TicketIcon } from 'helpers/customIcons';

import HintsCard from './HintsCard';
import Indicator from './Indicator';
import styles from './styles';
import { IProps } from './types';

const tooltipTicketsDetail = 'Detalhes dos ingressos consiste nos detalhes das vendas e adições manuais de cada lote';
const tooltipTicketsSummary = 'Ingressos totais consiste na soma dos ingressos vendidos e adicionados manualmente';
const tooltipRefundSummary = 'Os dados de solicitações e reembolso são baseados apenas nas vendas já confirmadas.';
const tooltipCheckinDetails =
  'O cálculo de credenciamento é baseado nos check-ins dentro da Blinket. Em caso de credenciamento terceirizado, certifique-se que as informações estão refletidas na Blinket.';

function IndicatorCards({ statistics, event }: IProps) {
  const { refundsSummary, ticketsSummary, ticketsDetail, checkinDetail } = statistics;

  const refundSummaryData = [
    { name: 'solicitações pendentes', value: refundsSummary.locked },
    { name: 'reembolsos realizados', value: refundsSummary.refunded }
  ];

  const checkinDetailsData = [
    {
      name: 'Participantes Totais',
      value: checkinDetail.total,
      items: [
        {
          name: 'Participantes com Check-in',
          value: checkinDetail.checkin
        },
        {
          name: `Participantes sem presença (${checkinDetail.refunded} reembolsados)`,
          value: checkinDetail.noCheckin
        }
      ]
    },
    { name: 'No-Show', value: (checkinDetail.noShow * 100).toFixed(2) + '%' }
  ];

  const ticketsSummaryData = [
    {
      name: 'Ingressos totais',
      value: ticketsSummary.attendancesTotal,
      items: [
        {
          name: 'Ingressos vendidos (' + ticketsSummary.totalInvoices + ' faturas)',
          value: ticketsSummary.attendancesOfInvoices
        },
        {
          name: 'Ingressos adicionados manualmente',
          value: ticketsSummary.attendancesOfManual
        }
      ]
    },
    { name: 'Ingressos disponíveis', value: ticketsSummary.ticketsTotalAvailable }
  ];

  const ticketsDetailData = [
    ...ticketsDetail.map((item: any) => {
      const items = item.lots.map(lotItem => {
        return {
          name: lotItem.lotName,
          value: lotItem.attendances
        };
      });
      return { name: item.ticketName.toUpperCase() + ' Total', value: item.attendances, items };
    })
  ];

  return (
    <Row gutter={[10, 10]}>
      <Col sm={24} md={12}>
        <Indicator
          key={'indicator-card-checkin'}
          title='Credenciamento'
          icon={GroupUsersIcon}
          tooltipText={tooltipCheckinDetails}
          data={checkinDetailsData}
          event={event}
          externalUrl={{ text: 'Como fazer integração via API?', link: 'https://developers.eduzz.com/docs/api' }}
        />

        <Indicator
          key={'indicator-card-tickets'}
          title='Ingressos'
          icon={GroupUsersIcon}
          tooltipText={tooltipTicketsSummary}
          data={ticketsSummaryData}
          event={event}
        />

        <Indicator
          key={'indicator-card-refounded-tickets'}
          title='Reembolsos'
          icon={RefundIcon}
          tooltipText={tooltipRefundSummary}
          data={refundSummaryData}
        />
      </Col>
      <Col md={24} lg={12}>
        <Indicator
          key={'indicator-card-ticket-details'}
          title='Detalhes dos ingressos'
          icon={TicketIcon}
          tooltipText={tooltipTicketsDetail}
          data={ticketsDetailData}
          event={event}
        />
      </Col>
      <Col md={24} lg={12}>
        <HintsCard event={event} key='hints-card' />
      </Col>
      <Col sm={24} md={12} style={{ display: 'flex' }}>
        <TelescopeCard key='analytics-card' />
      </Col>
    </Row>
  );
}

export default withStyles(styles)(IndicatorCards);
