import React, { Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { notification, Typography } from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import DraftPreview from 'components/Pages/Admin/MyEventsTabs/EventPage/Form/DraftPreview';
import { useEventDetail } from 'components/Pages/Admin/TabsMyEvents/context/EventDetail';
import CustomLoader from 'components/Shared/CustomLoader';
import { IEventPageEdit } from 'interfaces/event';
import ReactPlaceholder from 'react-placeholder/lib';
import eventService from 'services/event';
import * as yup from 'yup';

import customTemplateService from './services';
import * as S from './styles';
import { StateProps, ActionProps, ActionTypes } from './types';
import { PageParams } from '../BasicData/types';
import EventPageForm from './components/FormAntd';

const initialState: StateProps = {
  hasChangedData: false,
  openPreview: false,
  isSaving: false,
  draftSaved: false
};

const reducer: Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case ActionTypes.hasChangedData:
      return { ...state, hasChangedData: action.value as boolean };

    case ActionTypes.openPreview:
      return { ...state, openPreview: action.value as boolean };

    case ActionTypes.isSaving:
      return { ...state, isSaving: action.value as boolean };

    case ActionTypes.draftSaved:
      return { ...state, draftSaved: true, isSaving: false };

    case ActionTypes.hasSavedData:
      return { ...state, hasChangedData: false, isSaving: false };

    default:
      return state;
  }
};

export const CustomTemplate = () => {
  const [{ hasChangedData, openPreview, isSaving, draftSaved }, dispatch] = useReducer(reducer, {
    ...initialState
  });

  const { id: eventId } = useParams<PageParams>();
  const history = useHistory();
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        helpsupport_email: yup.string().email(),
        main_video_url: yup.string().url('Link inválido'),
        sale_url: yup.string().required('Campo obrigatório')
      })
    ),
    mode: 'onChange'
  });

  const { data: customTemplateData, loading, setEventData, setNewSaleUrl } = useEventDetail();

  const verifySaleUrl = useCallback(async () => {
    await methods.trigger('sale_url');
  }, [methods]);

  useEffect(() => {
    if (customTemplateData) {
      const draft_page_config = customTemplateData.pageContent.draft_page_config;
      const page_config = customTemplateData.pageContent.page_config;

      methods.reset({
        template_type: draft_page_config.template_type ?? page_config.template_type ?? 'basic',
        sale_url: draft_page_config.sale_url ?? page_config.sale_url,
        main_video_url: draft_page_config.main_video_url ?? page_config.main_video_url,
        header_color_texts: draft_page_config.header_color_texts ?? page_config.header_color_texts ?? '#000',
        pixel_key: draft_page_config.pixel_key ?? page_config.pixel_key,
        headeroption: draft_page_config.headeroption ?? page_config.headeroption,
        headerbackgroundcolor:
          draft_page_config.headerbackgroundcolor ?? page_config.headerbackgroundcolor ?? '#CFCFCF',
        localimage: draft_page_config.localimage ?? page_config.localimage,
        localimageFileName: draft_page_config.localimageFileName ?? page_config.localimageFileName,
        resumeimage: draft_page_config.resumeimage ?? page_config.resumeimage,
        resumeimageFileName: draft_page_config.resumeimageFileName ?? page_config.resumeimageFileName,
        logo: draft_page_config.logo ?? page_config.logo,
        logoFileName: draft_page_config.logoFileName ?? page_config.logoFileName,
        headerimg: draft_page_config.headerimg ?? page_config.headerimg,
        headerimgFileName: draft_page_config.headerimgFileName ?? page_config.headerimgFileName,
        v2_desc: draft_page_config.v2_desc ?? page_config.v2_desc,
        scheduletexthtml: draft_page_config.scheduletexthtml ?? page_config.scheduletexthtml
      });

      verifySaleUrl();
    }
  }, [customTemplateData, methods, verifySaleUrl]);

  const isShown = useMemo(() => !loading && !!customTemplateData, [loading, customTemplateData]);

  const handleVerifyData = useCallback(() => {
    if (!hasChangedData) {
      dispatch({ type: ActionTypes.hasChangedData, value: true });
    }
  }, [hasChangedData]);

  const handleOpenPreview = useCallback((open: boolean) => {
    dispatch({ type: ActionTypes.openPreview, value: open });
  }, []);

  const handleShowWarnigDraft = useCallback(
    (isDraft: boolean) => {
      dispatch({ type: ActionTypes.draftSaved, value: isDraft });
    },
    [dispatch]
  );

  const handleSaveEdit = useCallback(
    (data: IEventPageEdit, isDraft: boolean) => {
      const templateVersion = data.template_type === 'basic' ? 'v1' : 'v2';
      const page_config_form_data = { ...customTemplateData.pageContent.page_config, ...data, templateVersion };

      const payload = {
        ...(data?.pixel_key && { pixel_key: data.pixel_key }),
        sale_url: data.sale_url,
        template_type: data.template_type,
        description: customTemplateData.pageContent.description,
        draft_description: customTemplateData.pageContent.draft_description,
        ...(isDraft
          ? { draft_page_config: page_config_form_data }
          : { page_config: page_config_form_data, draft_page_config: page_config_form_data })
      };

      dispatch({ type: ActionTypes.isSaving, value: true });

      customTemplateService.put(eventId, payload).subscribe({
        next: () => {
          if (!isDraft) {
            notification.success({ message: 'Salvo com sucesso' });
            history.push(`/my-events/${eventId}/sale-links`);
            setNewSaleUrl(data.sale_url);

            dispatch({ type: ActionTypes.hasSavedData });
          }

          eventService.allDetails(eventId).subscribe({
            next: data => {
              setEventData(data);
            },
            error: () => {
              notification.error({ message: 'Erro ao salvar' });
              dispatch({ type: ActionTypes.isSaving, value: false });
            }
          });

          notification.success({ message: 'Rascunho salvo com sucesso' });
          dispatch({ type: ActionTypes.draftSaved });
          handleShowWarnigDraft(isDraft);
        },
        error: ({ data: response }) => {
          if (response && response?.code === 'ERR_CUSTOM') {
            notification.error({ message: response.details });
          } else {
            notification.error({ message: 'Erro ao salvar' });
          }

          dispatch({ type: ActionTypes.isSaving, value: false });
          handleShowWarnigDraft(isDraft);
        }
      });
    },
    [customTemplateData?.pageContent, eventId, handleShowWarnigDraft, history, setEventData, setNewSaleUrl]
  );

  const hasChangesNotPublished = useMemo(() => {
    const saved = customTemplateData?.pageContent.page_config;
    const draft = customTemplateData?.pageContent.draft_page_config;
    return JSON.stringify(saved) !== JSON.stringify(draft) ? true : false;
  }, [customTemplateData?.pageContent.draft_page_config, customTemplateData?.pageContent.page_config]);

  return (
    <S.Container>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={!loading}>
        {isShown && (
          <>
            <Typography.Title level={3}>Customizar template</Typography.Title>
            <div>
              {hasChangesNotPublished && (
                <div className='custom-template-warning-draft'>
                  <h2>
                    <WarningOutlined /> Atenção
                  </h2>
                  <p>Você salvou algumas informações como “rascunho”, para publicá-las clique em “salvar”.</p>
                </div>
              )}
              <FormProvider {...methods}>
                <EventPageForm
                  eventId={eventId}
                  isSaving={isSaving}
                  hasChangedData={hasChangedData}
                  handleVerifyData={handleVerifyData}
                  handleSaveEdit={handleSaveEdit}
                  handleOpenPreview={handleOpenPreview}
                  draftSaved={draftSaved}
                  isPresential={customTemplateData.event.type === 'presential'}
                  draftUrl={customTemplateData.pageContent.sale_url}
                />
              </FormProvider>
              <DraftPreview
                open={openPreview}
                saleUrl={customTemplateData.pageContent.sale_url}
                onClose={() => handleOpenPreview(false)}
              />
            </div>
          </>
        )}
      </ReactPlaceholder>
    </S.Container>
  );
};
