import * as React from 'react';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    'position': 'relative',
    'display': 'block',
    'width': '100%',
    'overflow': 'hidden',
    'height': 4,
    'backgroundColor': 'rgba(13, 39, 113, 0.25)',
    '& > div': {
      'backgroundColor': '#0D2772',
      '&:before, &:after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'inherit',
        top: 0,
        left: 0,
        bottom: 0
      },
      '&:before': {
        animation: '$rootLoaderIndeterminateAnimation 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite'
      },
      '&:after': {
        animation: '$rootLoaderIndeterminateShortAnimation 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite',
        animationDelay: '1.15s'
      }
    }
  },
  ['@keyframes rootLoaderIndeterminateAnimation']: {
    '0%': {
      left: '-35%',
      right: '100%'
    },
    '60%': {
      left: '100%',
      right: '-90%'
    },
    '100%': {
      left: '100%',
      right: '-90%'
    }
  },
  ['@keyframes rootLoaderIndeterminateShortAnimation']: {
    '0%': {
      left: '-200%',
      right: '100%'
    },
    '60%': {
      left: '107%',
      right: '-8%'
    },
    '100%': {
      left: '107%',
      right: '-8%'
    }
  }
}));

export const LoaderLine = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div />
    </div>
  );
};
