import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns/format';
import { ILoteForm } from 'interfaces/event';

import * as S from './styles';

interface TicketTableProps {
  lots: ILoteForm[];
}

const TicketTable = ({ lots }: TicketTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <S.CustomizedTableRow>
            <S.CustomizedTableCell align='left' header>
              Lote
            </S.CustomizedTableCell>
            <S.CustomizedTableCell align='left' header>
              Qtd
            </S.CustomizedTableCell>
            <S.CustomizedTableCell align='left' header>
              Início das Vendas
            </S.CustomizedTableCell>
            <S.CustomizedTableCell align='left' header>
              Término das Vendas
            </S.CustomizedTableCell>
            <S.CustomizedTableCell align='left' header>
              Valor
            </S.CustomizedTableCell>
          </S.CustomizedTableRow>
        </TableHead>
        <TableBody>
          {lots.map((lot, index) => (
            <TableRow key={lot.identificator}>
              <S.CustomizedTableCell align='left'>{index + 1}</S.CustomizedTableCell>
              <S.CustomizedTableCell align='left'>
                {lot.quantity === 9999999 ? 'Ilimitado' : lot.quantity}
              </S.CustomizedTableCell>
              <S.CustomizedTableCell align='left'>{format(lot.dateStart, 'dd/MM/yyyy HH:mm')}</S.CustomizedTableCell>
              <S.CustomizedTableCell align='left'>{format(lot.dateEnd, 'dd/MM/yyyy HH:mm')}</S.CustomizedTableCell>
              <S.CustomizedTableCell align='left'>{lot.value}</S.CustomizedTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TicketTable;
