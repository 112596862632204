import Dialog from '@mui/material/Dialog';
import styled, { css } from 'styled-components';

export const Modal = styled(Dialog)`
  ${({ theme: { colors, fontWeight, lineHeight } }) => css`
    h4 {
      color: ${colors.primary.contrastText};
      font-size: 20px;
      font-weight: ${fontWeight('regular')};
      line-height: ${lineHeight('compact')};
    }

    .modal-notify-content {
      color: ${colors.primary.contrastText};
      font-size: 14px;
      font-weight: ${fontWeight('regular')};
      line-height: ${lineHeight('compact')};
    }

    .modal-notify-custom-p {
      color: ${colors.primary.contrastText};
      font-size: 14px;
      font-weight: ${fontWeight('regular')};
      line-height: ${lineHeight('comfortable')};
      margin: 7px 0;
    }

    .modal-notify-container-radio {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }
  `}
`;
