import createStyles from '@mui/styles/createStyles';
import { fontSizePxToRem } from 'helpers/functions';

const styles = theme =>
  createStyles({
    root: {
      background: '#fff',
      padding: 16
    },
    header: {
      'display': 'flex',
      'alignItems': 'center',
      'gridArea': 'title',
      'color': theme.palette.primary.contrastText,
      '& h5': {
        'fontSize': fontSizePxToRem(20),
        'margin': '0 8px',
        'fontWeight': 500,
        '@media (max-width: 1360px)': {
          fontSize: fontSizePxToRem(18)
        }
      }
    },
    icon: {
      fontSize: fontSizePxToRem(32),
      color: theme.palette.primary.contrastText
    },
    infinitySymbol: {
      fontSize: '25px'
    },
    details: {
      gridArea: 'details',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    indicatorDetail: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.contrastText,
      marginTop: 8,
      fontSize: fontSizePxToRem(14)
    },
    indicatorDetailItem: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.contrastText,
      marginTop: 8,
      marginLeft: 30,
      fontSize: fontSizePxToRem(14)
    },
    itemValue: {
      paddingRight: 6
    },
    bullet: {
      width: '8px',
      height: '8px',
      margin: '14px 15px 14px 10px',
      backgroundColor: '#d9d9d9'
    },
    detailsPercentage: {
      fontWeight: 600,
      fontSize: 'inherit',
      backgroundColor: 'unset',
      marginRight: 6,
      minWidth: 35
    },
    extra: {
      'display': 'flex',
      '& a': {
        width: '100%',
        textAlign: 'right'
      }
    }
  });

export default styles;
