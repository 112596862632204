import DeleteOutlined from '@ant-design/icons/DeleteOutlined';

import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import media from 'styled-media-query';

export const TooltipWrapper = styled.div`
  margin-top: -10px;
`;

export const CustomizedTypography = styled(Typography)`
  color: #000000;
`;

export const ErrorMessage = styled(Typography)`
  color: #f44336;
`;

export const CustomizedDeleteIcon = styled(DeleteOutlined)`
  color: #fff;
  left: 45%;
  position: absolute;
  top: 45%;
  visibility: hidden;
`;

export const Wrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;

  img {
    border: 2px solid #395fca;
    max-height: 150px;
    max-width: 150px;
  }

  &:hover {
    transition: 0.3s;

    ${CustomizedDeleteIcon} {
      visibility: visible;
    }

    img {
      filter: brightness(0.5);
    }
  }
`;

export const CustomWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  margin: 0;
  padding: 0;
`;

export const CustomGrid = styled(Grid)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 0;
  margin-bottom: 30px;
`;

export const CustomLabel = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-left: 10px;
`;

export const CustomReserveTimeLabelDisabled = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  margin: 13px 0px 0px 45px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;

export const CustomSwitch = styled(Switch)`
  margin: 5px 10px;
`;

export const CustomTypography = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 0 5px;
`;

export const CustomTypographyWhenPreCheckoutEnabled = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 0px 0px 45px;
`;

export const Title = styled.h6`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 10px;

  ${media.greaterThan('medium')`
    font-size: 1.25rem;
  `}
`;

export const Subtitle = styled.h6`
  color: #595959;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 10px;
  ${media.greaterThan('medium')`
  font-size: 1rem;
`}
`;

export const FormTitle = styled.h6`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  ${media.greaterThan('medium')`
    font-size: 1.25rem;
  `}
`;
