import createStyles from '@mui/styles/createStyles';
import { setMargin } from 'helpers/functions';

const styles = theme =>
  createStyles({
    root: {
      'display': 'grid',
      'gridTemplateColumns': 'repeat(2, 1fr)',
      'gridColumnGap': '16px',
      'gridRowGap': '16px',
      '& > div:nth-child(1)': { gridArea: '1 / 1 / 2 / 2' },
      '& > div:nth-child(2)': { gridArea: '2 / 1 / 3 / 2' },
      '& > div:nth-child(3)': { gridArea: '1 / 2 / 3 / 3' },
      '& > div:nth-child(4)': { gridArea: '3 / 1 / 4 / 2' },
      '& > div:nth-child(5)': { gridArea: '3 / 2 / 4 / 3' },
      ...setMargin(12, 0, 16, 0),
      [theme.breakpoints.down('md')]: {
        'display': 'block',
        '& > div:nth-child(1)': { margin: '16px 0px' },
        '& > div:nth-child(2)': { margin: '16px 0px' },
        '& > div:nth-child(3)': { margin: '16px 0px' },
        '& > div:nth-child(4)': { margin: '16px 0px' },
        '& > div:nth-child(5)': { margin: '16px 0px' }
      }
    }
  });

export default styles;
