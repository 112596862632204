import React, { Component } from 'react';
import ReactPlaceholder from 'react-placeholder';

type IProps = unknown;
interface IState {
  component: any;
}

export default function AsyncComponent(importComponent: any, functions?: any) {
  class AsyncFunc extends Component<IProps, IState> {
    public mounted: boolean;
    constructor(props: IProps) {
      super(props);
      this.mounted = true;
      this.state = {
        component: null
      };
    }

    public componentWillUnmount() {
      this.mounted = false;
    }

    public async componentDidMount() {
      this.mounted = true;
      const { default: Component } = await importComponent();

      if (this.mounted) {
        this.setState({
          component: <Component customFunctions={functions} {...this.props} />
        });
      }
    }

    public render() {
      const Component = this.state.component || <div />;
      return (
        <ReactPlaceholder type='media' rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
