import React from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { ORBITA_EDIT_PRODUCT_PATH_URL } from 'helpers/constants';
import { formatUrlWithUtm } from 'helpers/functions';
import { ORBITA_URL } from 'settings';

import styles from './styles';
import { IProps } from './types';

const HintsCard = ({ classes, event }: IProps) => {
  const cnt_cod = event?.tickets[0]?.lot[0]?.cnt_cod;
  const history = useHistory();

  const handleLostSales = () => {
    const url = `${ORBITA_EDIT_PRODUCT_PATH_URL}${cnt_cod}`;
    window.open(formatUrlWithUtm(url, 'blinket', 'dashboard'));
  };
  const handleLeads = () => {
    const url = `${ORBITA_URL}/producer/leads`;
    window.open(formatUrlWithUtm(url, 'blinket', 'dashboard'));
  };
  const handleManageTickets = () => {
    sessionStorage.setItem('step', '3');
    history.push(`/event/edit/${event.id}`);
  };

  return (
    <Paper className={classes.root} elevation={1} square>
      <h5 className={classes.title}>
        Você pode <strong>aumentar suas vendas</strong>
      </h5>

      <p className={classes.description}>Separamos algumas ações que podem te ajudar a aumentar suas vendas</p>

      <div className={classes.listActions}>
        <Button
          type='default'
          icon={<ArrowRightOutlined />}
          iconPosition='end'
          onClick={handleLostSales}
          className={classes.button}
        >
          Ativar a recuperação de vendas perdidas
        </Button>

        {!event.is_live && (
          <Button
            type='default'
            icon={<ArrowRightOutlined />}
            iconPosition='end'
            onClick={handleManageTickets}
            className={classes.button}
          >
            Customizar a estratégia de lotes do seu evento
          </Button>
        )}

        <Button
          type='default'
          icon={<ArrowRightOutlined />}
          iconPosition='end'
          onClick={handleLeads}
          className={classes.button}
        >
          Trabalhar seus leads que não compraram
        </Button>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(HintsCard);
