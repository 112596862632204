import React from 'react';

import WarningOutline from '@ant-design/icons/WarningOutlined';
import { Modal } from 'antd';

import { ModalWarningProps } from './types';

const ModalWarning = ({ title, content, isOpen, onCancel, onContinue }: ModalWarningProps) => {
  return (
    <Modal
      title={
        <>
          <WarningOutline /> {title}
        </>
      }
      open={isOpen}
      okText='Continuar'
      onOk={onContinue}
      cancelText='Voltar'
      onCancel={onCancel}
    >
      {content}
    </Modal>
  );
};
export default ModalWarning;
