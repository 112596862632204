import { addMinutes } from 'date-fns/addMinutes';
import { format } from 'date-fns/format';
import { ptBR } from 'date-fns/locale/pt-BR';
import { dateNormalizeIso8601Format } from 'helpers/functions';

export const formatDateToPtBr = (date: Date, dateFormate: string, fixHours?: boolean): string => {
  if (!date) {
    return '';
  }

  date = dateNormalizeIso8601Format(date);

  if (fixHours) {
    const dateFormated = new Date(addMinutes(date, date.getTimezoneOffset()));
    return format(dateFormated, dateFormate, { locale: ptBR });
  }

  return format(date, dateFormate, { locale: ptBR });
};
