import useExternalScript from 'hooks/useExternalScript';
import { GOOGLE_MAPS_PLACE_APIKEY } from 'settings';

const GoogleMapsLib = () => {
  useExternalScript(
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_PLACE_APIKEY}&libraries=places&region=BR`
  );

  return null;
};

export default GoogleMapsLib;
