import React, { useState } from 'react';

import { Button, notification } from 'antd';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import eventService from 'services/event';

interface IProps {
  open: boolean;
  eventId: string;
  handleClose: () => void;
  onComplete: (offerId: string) => void;
}

export default function ArchiveEvent({ open, handleClose, eventId, onComplete }: IProps) {
  const [loading, setLoading] = useState(false);

  const confirmArchiveEvent = () => {
    setLoading(true);
    eventService.archiveEvent({ eventId }).subscribe(
      () => {
        setLoading(false);
        notification.success({ message: 'Evento excluído com sucesso' });
        onComplete(eventId);
      },
      ({ data: err }) => {
        setLoading(false);

        if (err.message === 'Você não possui permissão para realizar esta ação') {
          notification.error({ message: err.message });
        } else {
          if (err && err?.code === 'ERR_CUSTOM') {
            notification.error({ message: err.details });
          } else {
            notification.error({ message: 'Houve um erro ao excluir o evento. Tente novamente' });
          }
        }
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Deseja excluir este Evento?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <b>Atenção: </b> Todos os ingressos deste evento também serão excluídos e novas vendas não ocorrerão.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type='text' disabled={loading} onClick={handleClose} id='myevents-archive-cancelbutton'>
          Cancelar
        </Button>
        <Button
          type='primary'
          autoFocus
          disabled={loading}
          id='myevents-archive-confirmbutton'
          onClick={() => confirmArchiveEvent()}
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
