import styled from '@emotion/styled';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 4px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
