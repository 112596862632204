import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import TableCol from 'components/Shared/Table/components/TableCol';
import TableRow from 'components/Shared/Table/components/TableRow';

interface IProps extends RouteComponentProps {
  item: string;
  classes?: any;
  type: string;
  onComplete: (inviteKey: string, type: string) => void;
  id?: string;
}
class InviteListItem extends PureComponent<IProps> {
  public render() {
    const { onComplete, id } = this.props;
    return (
      <TableRow key={this.props.item} onClick={() => onComplete(this.props.item, this.props.type)} id={id}>
        <TableCol xs={{ size: 6 }} sm={{ size: 6 }} type='string' value={this.props.item} />
        <TableCol xs={{ size: 8 }} sm={{ size: 8 }} type='spacing-100' value={'                           '} />
        <TableCol xs={{ size: 2 }} sm={{ size: 2 }} type='icon' value='chevron_right' />
      </TableRow>
    );
  }
}

export default withRouter(InviteListItem);
