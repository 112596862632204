import createStyles from '@mui/styles/createStyles';
import { setMargin } from 'helpers/functions';
const styles = () =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column'
    },
    labelStrong: { fontWeight: 600 },
    formControlLabel: {
      ...setMargin(null, null, null, 0)
    },
    radioGroup: {
      ...setMargin(null, null, 16, 15)
    },
    checkList: {
      ...setMargin(null, null, null, 15),
      display: 'flex',
      flexDirection: 'column'
    }
  });

export default styles;
