import * as Rx from 'rxjs';

interface IRoute {
  url: string;
}
export interface ITitlePage {
  titlePage: string[];
  breadCrumbs?: BreadCrumbs[];
  mobileGoBack: BreadCrumbs;
}

export interface BreadCrumbs {
  label: string;
  path?: string;
}
class RoutesService {
  private routes$ = new Rx.BehaviorSubject({ url: '' });

  public get(): Rx.Observable<IRoute> {
    return this.routes$.asObservable();
  }

  public set(url: string) {
    this.routes$.next({ url });
  }

  public unset() {
    this.routes$.unsubscribe();
  }
}

class TitlePageService {
  private titlePage$ = new Rx.BehaviorSubject<ITitlePage>({
    titlePage: [''],
    breadCrumbs: [],
    mobileGoBack: { label: 'Home', path: '/' }
  });

  public get(): Rx.Observable<ITitlePage> {
    return this.titlePage$.asObservable();
  }

  public set(titlePage: string[], breadCrumbs?: BreadCrumbs[], mobileGoBack?: BreadCrumbs) {
    this.titlePage$.next({ titlePage, breadCrumbs, mobileGoBack });
  }

  public unset() {
    this.titlePage$.unsubscribe();
  }
}

export const routesService = new RoutesService();
export const titlePageService = new TitlePageService();
