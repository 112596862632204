import React, { DragEvent, PureComponent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';

import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import IconMessage from 'components/Shared/IconMessage';
import { FindFolderIcon } from 'helpers/customIcons';

import styles from './styles';

export interface ImageReaderResult {
  url: string;
  width: number;
  height: number;
  name: string;
}

interface IState {
  loading: boolean;
  draggingOver: boolean;
}

interface IProps {
  droppable?: boolean;
  className?: string;
  onLoad: (result: ImageReaderResult) => void;
  classes?: any;
}

class ImageReader extends PureComponent<IProps, IState> {
  public inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  public extensions = ['png', 'gif', 'jpeg', 'jpg', 'bmp'];

  constructor(props: IProps) {
    super(props);
    this.state = { loading: false, draggingOver: false };
  }

  public handleSelectImage = (e: any) => {
    e.stopPropagation();
    this.inputRef.current.click();
  };

  public onFileSelected = () => {
    if (!this.inputRef.current.files.length) return;
    this.setState({ loading: true });

    this.loadFile(this.inputRef.current.files[0]);
    this.inputRef.current.value = '';
  };

  public onDropFile = (event: DragEvent<any>) => {
    event.preventDefault();

    this.setState({ draggingOver: false });

    if (this.state.loading) return;
    this.loadFile(event.dataTransfer.files[0]);
  };

  public onDragIn = (event: DragEvent<any>) => this.onDragInOut(true, event);
  public onDragOut = (event: DragEvent<any>) => this.onDragInOut(false, event);

  public onDragInOut = (draggingOver: boolean, event: DragEvent<any>) => {
    event.preventDefault();

    if (this.state.loading) return;

    if (this.state.draggingOver === draggingOver) return;
    this.setState({ draggingOver });
  };

  public loadFile = (file: File) => {
    const regexp = new RegExp(`.(${this.extensions.join('|')})$`, 'gi');

    if (!regexp.test(file.name)) {
      notification.error({ message: `Apenas imagens: ${this.extensions.join(', ')}` });
      this.setState({ loading: false });
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: any) => {
      return this.getImageDimensions(e.target.result, file.name);
    };

    reader.onerror = () => {
      notification.error({ message: 'Não conseguimos carregar a imagem' });
      this.setState({ loading: false });
    };

    reader.readAsDataURL(file);
  };

  public getImageDimensions = (url: string, fileName: string) => {
    const image = new Image();

    image.onload = () => {
      setTimeout(() => {
        this.setState({ loading: false });
        this.props.onLoad({
          url,
          width: image.width,
          height: image.height,
          name: fileName
        });
      }, 1000);
    };

    image.onerror = () => {
      notification.error({ message: 'Não conseguimos carregar a imagem' });
      this.setState({ loading: false });
    };

    image.src = url;
  };

  public render() {
    const { droppable } = this.props;

    return droppable ? this.renderArea() : this.renderButton();
  }

  public renderArea = () => {
    const { draggingOver, loading } = this.state;
    const { classes, className } = this.props;

    return (
      <div
        className={`${classes.dropArea} ${className || ''} ${draggingOver ? classes.dropAreaDragging : null}`}
        onDrop={this.onDropFile}
        onDragOver={this.onDragIn}
        onDragLeave={this.onDragOut}
        onClick={this.handleSelectImage}
      >
        {loading && <LoadingOutlined spin style={{ fontSize: '50px' }} className={classes.dropAreaProgress} />}

        {!loading && (
          <>
            <div className={classes.dropAreaDraggingChildren}>
              <IconMessage />
            </div>

            <Typography variant='body1' className={classes.text}>
              ou
            </Typography>

            <div className={draggingOver ? classes.dropAreaDraggingChildren : null}>{this.renderButton()}</div>
          </>
        )}
      </div>
    );
  };

  public renderButton = () => {
    const { loading } = this.state;
    const { classes } = this.props;

    return (
      <>
        <input
          type='file'
          ref={this.inputRef}
          className='hide'
          onChange={this.onFileSelected}
          accept={`.${this.extensions.join(',.')}`}
          id={'img-dialog-input'}
        />

        <Button
          type='text'
          disabled={loading}
          onClick={this.handleSelectImage}
          id={'img-dialog-select-button'}
          style={{ display: 'flex', margin: 'auto' }}
          icon={
            loading ? (
              <LoadingOutlined spin className={classes.progress} style={{ fontSize: '20px', marginRight: '10px' }} />
            ) : (
              <FindFolderIcon style={{ marginRight: '10px' }} />
            )
          }
        >
          <span className={classes.selectButton}>{loading ? 'Carregando' : 'Selecionar'}</span>
        </Button>
      </>
    );
  };
}

export default withStyles(styles)(ImageReader);
