import React from 'react';

import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import { Typography } from 'antd';

import { ExternalLinkIcon } from 'helpers/customIcons';

import * as S from './styles';
import { EventLocal as Event, EventLocalProps, EventOnline, EventPresential, EventLive } from './types';

const EventLocal = ({ local }: EventLocalProps) => {
  const renderEventPresential = ({ adress, name }: EventPresential) => {
    return (
      <div>
        <div className='event-local-location'>
          <HomeOutlined />
          <p>{name}</p>
        </div>
        <div className='event-local-adress'>
          <EnvironmentOutlined />
          <p>{adress}</p>
        </div>
      </div>
    );
  };

  const renderEventOnline = ({ url }: EventOnline) => {
    if (url === null || url === '') {
      return (
        <>
          <a className='event-online-no-link'>
            <GlobalOutlined className='event-online-no-link-icon' />
            <p>Online</p>
          </a>
          <p>Este evento ainda não possui um link definido!</p>
        </>
      );
    }

    return (
      <a className='event-local-online' href={url} target='_blank' rel='noopener noreferrer'>
        <GlobalOutlined className='event-local-online-icon' />
        <p>Online</p>
        <ExternalLinkIcon />
      </a>
    );
  };

  const renderEventLive = ({ url }: EventLive) => {
    if (url === null || url === '') {
      return (
        <>
          <a className='event-online-no-link'>
            <GlobalOutlined className='event-online-no-link-icon' />
            <p>Live</p>
          </a>
          <p>Este evento ainda não possui um link de transmissão definido!</p>
        </>
      );
    }

    const transmissionLinks = JSON.parse(url);
    const eventLinks = transmissionLinks.map((live, index) => (
      <a key={index} className='event-local-online' href={live.link} target='_blank' rel='noopener noreferrer'>
        <GlobalOutlined className='event-local-online-icon' />
        <p>{live.name}</p>
        <ExternalLinkIcon />
      </a>
    ));

    return <>{eventLinks}</>;
  };

  const renderEvent = (event: Event) => {
    const eventContent = event.presential || event.online || event.live;

    if (event.presential) {
      return renderEventPresential(eventContent as EventPresential);
    }

    return event.live ? renderEventLive(eventContent as EventLive) : renderEventOnline(eventContent as EventOnline);
  };

  return (
    <S.Container>
      <Typography.Title level={5}>Local</Typography.Title>
      <div className='event-local-content'>{renderEvent(local)}</div>
    </S.Container>
  );
};

export default EventLocal;
