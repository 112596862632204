import { geocodeByAddress } from 'react-places-autocomplete';

import { notification } from 'antd';

import { GeoLocationRequest, GeoLocationResponse, GoogleMapsLocation } from './types';

export class GeoLocation {
  public async getGeoLocation({ address }: GeoLocationRequest): Promise<GeoLocationResponse[] | null> {
    try {
      return await geocodeByAddress(address);
    } catch (_error) {
      notification.error({ message: 'Ocorreu um erro ao tentar localizar endereço.' });
      return null;
    }
  }

  public async geoLocationToGoogleLocation({ address }: GeoLocationRequest): Promise<GoogleMapsLocation | null> {
    try {
      const [response] = await this.getGeoLocation({ address });
      const googleDataLocation: GoogleMapsLocation = {
        lat: null,
        lng: null,
        localName: null,
        street: null,
        streetNumber: null,
        neighborhood: null,
        zipCode: null,
        city: null,
        stateShortName: null,
        stateLongName: null,
        country: null,
        placeId: null,
        mapEmbedUrl: null
      };

      googleDataLocation.lat = Number(response.geometry.location.lat()?.toString().substring(0, 20));
      googleDataLocation.lng = Number(response.geometry.location.lng()?.toString().substring(0, 20));
      googleDataLocation.placeId = response.place_id;
      googleDataLocation.mapEmbedUrl = `https://www.google.com/maps/place/?q=place_id:${googleDataLocation.placeId}`;

      response.address_components.forEach(function (elem: any) {
        if (elem.types.includes('route')) {
          googleDataLocation.street = elem.long_name;
        }

        if (elem.types.includes('street_number')) {
          googleDataLocation.streetNumber = elem.long_name;
        }

        if (elem.types.includes('sublocality_level_1') || elem.types.includes('sublocality')) {
          googleDataLocation.neighborhood = elem.long_name;
        }

        if (elem.types.includes('postal_code')) {
          googleDataLocation.zipCode = elem.long_name;
        }

        if (elem.types.includes('administrative_area_level_2')) {
          googleDataLocation.city = elem.long_name;
        }

        if (elem.types.includes('administrative_area_level_1')) {
          googleDataLocation.stateShortName = elem.short_name;
          googleDataLocation.stateLongName = elem.long_name;
        }

        if (elem.types.includes('country')) {
          googleDataLocation.country = elem.long_name;
        }
      });

      return googleDataLocation;
    } catch (_error) {
      notification.error({ message: 'Ocorreu um erro ao tentar localizar endereço.' });

      return null;
    }
  }
}

const geoLocation = new GeoLocation();
export default geoLocation;
