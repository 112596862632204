import createStyles from '@mui/styles/createStyles';
import { setMargin } from 'helpers/functions';

const styles = theme =>
  createStyles({
    content: {
      height: '100%'
    },
    alignItems: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    optionButton: {
      border: '1px solid rgb(222, 222, 222)',
      display: 'inline-flex',
      padding: 10,
      fontSize: '0.9em',
      width: '95%',
      margin: '0 0 0.5rem 0',
      minHeight: 60,
      lineHeight: '1.3rem'
    },
    optionButtonContainer: {
      display: 'flex',
      textAlign: 'center'
    },
    submitButton: {
      color: theme.palette.primary.main
    },
    searchInputClient: {
      padding: '0px 7px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: '5px',
      backgroundColor: '#ECEFF1',
      border: '3px solid #ECEFF1',
      ...setMargin(null, null, 32, null)
    },
    searchInputClientInputText: {
      'flex': 1,
      'border': 'none',
      'margin': '0',
      'backgroundColor': '#ECEFF1',
      '&::placeholder': {
        color: theme.palette.grey[50]
      }
    },
    parentGrid: {
      justifyContent: 'center'
    }
  });

export default styles;
