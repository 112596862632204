import createStyles from '@mui/styles/createStyles';
import { fontSizePxToRem } from 'helpers/functions';

const styles = theme =>
  createStyles({
    root: {
      position: 'relative',
      padding: 16,
      minHeight: 198,
      borderLeft: `${theme.palette.primary.main} 12px  solid`,
      [theme.breakpoints.down('md')]: {
        padding: 12
      }
    },
    title: {
      'fontSize': fontSizePxToRem(16),
      'fontWeight': 400,
      'color': theme.palette.primary.contrastText,
      'marginBottom': 8,
      '& > strong': { color: theme.palette.secondary.main },
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizePxToRem(14)
      }
    },
    description: {
      fontSize: fontSizePxToRem(14),
      color: theme.palette.primary.contrastText,
      maxWidth: 360,
      marginBottom: 8,
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizePxToRem(12)
      }
    },
    listActions: {
      'marginTop': 6,
      'display': 'flex',
      'flexDirection': 'column',
      'maxWidth': 430,
      '& > button': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 4
      }
    },
    button: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  });

export default styles;
