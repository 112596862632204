import { cleanString } from 'helpers/functions';
import { stripPhoneMask } from 'helpers/masks';
import { validateCpf } from 'helpers/validations';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup
    .string()
    .matches(/^[A-zÀ-ú ]*$/, 'Insira apenas letras')
    .min(1, 'Informe seu Nome')
    .required(),
  email: yup.string().min(1, 'Informe seu E-mail').email('Endereço inválido, revise o e-mail inserido').required(),
  document: yup
    .string()
    .min(1, 'Informe seu Documento')
    .test('check document', 'CPF informado é inválido', (value, ctx) => {
      if (ctx?.parent?.is_international_doc) return true;
      return validateCpf(value);
    })
    .required(),
  is_international_doc: yup.boolean(),
  ddi: yup.number().min(1, '').max(999, '').required('').nullable(),
  phone: yup
    .string()
    .min(8, 'Número no mínimo 8 caracteres')
    .test('phone length without mask', 'Código DDD + número telefone', (phone, ctx) => {
      if (!phone) return false;
      const cleanedPhone = cleanString(phone);
      return !(cleanedPhone.length < 10 && ctx?.parent?.ddi === '55');
    })
    .test('phone length limit for ddi 55', 'Número com DDI 55 deve ter no máximo 10 dígitos', (phone, ctx) => {
      if (!phone) return false;
      const cleanedPhone = cleanString(phone);
      return !(ctx?.parent?.ddi === '55' && cleanedPhone.length > 11);
    })
    .test('international phone only digits', 'Insira apenas números', (phone, ctx) => {
      if (!phone) return false;
      const cleanedPhone = cleanString(phone);
      return !(ctx?.parent?.ddi !== '55' && /\D/.test(cleanedPhone));
    })
    .test('international phone length limit', 'Número deve ter no máximo 15 dígitos', (phone, ctx) => {
      if (!phone) return false;
      const cleanedPhone = stripPhoneMask(phone);
      return !(ctx?.parent?.ddi !== '55' && cleanedPhone.length > 15);
    })
    .required(),
  ticket_id: yup.string().required('Informe a Categoria do Ingresso')
});

export default validationSchema;
