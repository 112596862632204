import React from 'react';

import { IEventCategory } from 'interfaces/eventCategory';
import eventService from 'services/event';

const useEventCategory = () => {
  const [eventCategories, setEventCategories] = React.useState<IEventCategory[] | []>([]);

  React.useEffect(() => {
    const fetchCategories = async () => {
      await eventService.listCategories().subscribe(item => {
        setEventCategories([...item, { id: 'CUSTOM_CATEGORY', title: 'Outro', created: null, custom: 0 }]);
      });
    };

    fetchCategories();
  }, []);

  return eventCategories;
};

export default useEventCategory;
