import Button from 'antd/es/button';

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  border: 1px solid #d9d9d9;
  padding: 10px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 100px;
  margin-right: 10px;
  font-size: 1rem;

  @media (max-width: 899px) {
    width: 48%;
    margin-right: '4%';
  }
`;

export const ErrorMessage = styled.p`
  color: #d32f2f;
  font-size: 0.75rem;
  margin: 3px 14px 0 14px;
`;
