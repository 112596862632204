import React, { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';

import { useEventDetail } from 'components/Pages/Admin/TabsMyEvents/context/EventDetail';
import CustomLoader from 'components/Shared/CustomLoader';
import Tour from 'components/Shared/Tour';
import { getTargetElement } from 'components/Shared/Tour/helpers';
import { TourSteps } from 'components/Shared/Tour/types';
import useTabTour from 'hooks/useTabTour';
import ReactPlaceholder from 'react-placeholder/lib';
import { titlePageService } from 'services/routes';

import EventWidget from './components/EventWidget';
import * as S from './styles';
import { PageParams } from './types';
import PageTitle from '../components/PageTitle';

const steps: TourSteps[] = [
  {
    title: 'Carrinho de compras',
    description:
      'Deixe disponível em sua página de vendas todos os ingressos para seu cliente adquirir mais de um de uma só vez.',
    target: getTargetElement("div[data-node-key='cart']")
  },
  {
    title: 'Carrinho de compras',
    description:
      'Está utilizando uma página de vendas fora da blinket? Copie o código do carrinho de compras e cole em sua página. ',
    target: getTargetElement('div.event-widget-button-container button'),
    placement: 'bottomRight',
    isLastStep: true
  }
];

const EventCart = memo(() => {
  const { id: eventId } = useParams<PageParams>();
  const { data: eventCartData, loading } = useEventDetail();
  const { showTour, openTour, closeTour } = useTabTour('cart');

  const handleTutorial = () => {
    window.open('', '_blank').focus();
  };

  useEffect(() => {
    titlePageService.set(
      ['Meus Eventos'],
      [
        { label: 'Meus Eventos', path: '/my-events' },
        { label: eventCartData?.eventHeader.title, path: `my-events/${eventId}/basic-data` },
        { label: 'Carrinho de compras', path: '' }
      ],
      { label: 'Dados básicos', path: `/my-events/${eventId}/basic-data` }
    );
  }, [eventCartData?.eventHeader.title, eventId]);

  return (
    <S.Container>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={!loading}>
        <PageTitle
          title='Carrinho de compras'
          subtitle='Centralize os ingressos em sua página de vendas'
          onIconClick={openTour}
        />
        <h1 className='event-cart-title'>Script Widget</h1>
        <p className='event-cart-description'>
          Com esse script, é possível adicionar o carrinho de compras em sua página de vendas. Para utilizar, basta
          seguir os seguintes passos:
        </p>

        <li>Criar uma “div” em sua página</li>
        <li>Copiar o script abaixo </li>
        <li>Colar em sua página, na seção de scripts</li>
        <li>No campo “selector” do script, colocar o mesmo “id” da div criada anteriormente.</li>

        <p className='event-cart-custom-card'>Também é possível fazer customizações no carrinho</p>
        <a
          href='https://ajuda.eduzz.com/hc/pt-br/articles/4413080195483-Personalização-da-página-de-vendas-para-eventos-Orbit-Pages-'
          target='_blank'
          rel='noopener noreferrer'
          className='event-cart-link desktop'
        >
          Veja um tutorial que preparamos pra você <ArrowRightOutlined />
        </a>
        <p onClick={handleTutorial} className='event-cart-link mobile'>
          Veja este tutorial <ArrowRightOutlined />
        </p>

        <EventWidget />
      </ReactPlaceholder>
      {!loading && <Tour steps={steps} open={showTour} onClose={closeTour} />}
    </S.Container>
  );
});

export default EventCart;
