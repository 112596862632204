import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Typography } from 'antd';

import { EventDetailContext } from '../../..';
import FinishedImg from './Images/FinishedImg.png';

const FinishedStep = () => {
  const { eventDetail, getCurrentTicketName } = React.useContext(EventDetailContext);
  const form = useFormContext();

  const fileName = form.getValues('file_name');
  const ticketSelected = form.getValues('ticket_id');
  const ticketName = getCurrentTicketName(ticketSelected);

  return (
    <>
      <img src={FinishedImg} style={{ marginBottom: '32px' }} />
      <Typography.Title level={4}>Uhuul! Tudo certo!</Typography.Title>
      <Typography.Paragraph>
        Sua lista de participantes do evento <b>{eventDetail.title}</b> foi atualizada. Agora todos os convidados
        adicionados através da planilha <b>{fileName}</b> terão um ingresso <b>{ticketName}</b>
      </Typography.Paragraph>
    </>
  );
};

export default FinishedStep;
