import { PureComponent } from 'react';

import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { IProps } from './types';

class TableHead extends PureComponent<IProps> {
  public render() {
    const { allParticipantsAreSelected, disableSelectAll, head, onSelectAll } = this.props;

    return (
      <thead>
        <tr>
          <th>
            <Checkbox
              checked={allParticipantsAreSelected || disableSelectAll}
              color='primary'
              onChange={e => onSelectAll(e.target.checked)}
              style={{ paddingLeft: 0 }}
              disabled={disableSelectAll}
            />
          </th>
          {head.map((item, index) => (
            <th
              key={'head-' + index}
              className={`${item.numeric ? '--numeric' : ''}${item.status ? '--status' : ' headString'}`}
              style={item?.textAlign && { textAlign: item?.textAlign }}
            >
              {item.label}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default withStyles(styles)(TableHead);
