import createStyles from '@mui/styles/createStyles';

const styles = theme =>
  createStyles({
    loader: {
      width: 70,
      height: 70,
      color: theme.palette.secondary.light
    },
    paper: {
      boxShadow: 'none',
      outline: 'none',
      backgroundColor: 'transparent'
    }
  });

export default styles;
