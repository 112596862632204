import { createTheme } from '@mui/material/styles';

import overrides from './overrides';
import variables from './variables';

const primary = {
  main: '#FFBC00',
  contrastText: '#000000',
  light: '#78909C',
  dark: null,
  overrides
};

const secondary = {
  light: '#ffffff',
  main: '#0D2772',
  contrastText: '#000000',
  dark: '#c79c00',
  overrides
};

export const muiTheme = createTheme({
  palette: { primary, secondary }
});

export const houstonTheme = {
  colors: { primary, secondary },
  variables
};
