import { EventDetail } from 'components/Pages/Admin/TabsMyEvents/context/EventDetail';
import { AdvertisementlotLotInfos } from 'components/Presentational/SaleLinks/types';
import { IEvent, IEventList, IEventPageEdit, IEventPost, LastUpdatedEvent } from 'interfaces/event';
import { IEventCategory } from 'interfaces/eventCategory';
import { EventPanelStatistics } from 'interfaces/eventStatics';
import { PaginationResponse } from 'interfaces/pagination';
import * as Rx from 'rxjs';
import * as RxOp from 'rxjs-operators';
import { CDN_URL, ORBIT_PAGES_URL } from 'settings';
import templatePages from 'utils/templatesBlinket';

import apiService, { ApiService } from '../api';
import { GetEventCheckInDetailsResponse } from './types';

export class EventService {
  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  public list(params?: any): Rx.Observable<IEventList[]> {
    return this.apiService.post('/event/list', params).pipe(RxOp.map(response => response.data[0].data));
  }

  public listPastEvents(params?: any): Rx.Observable<any> {
    return this.apiService.post('/event/list_past', params).pipe(RxOp.map(response => response.data[0]));
  }

  public listLastEvents(params?: any): Rx.Observable<PaginationResponse<LastUpdatedEvent[]>> {
    return this.apiService
      .post('/event/list_current_last_modified', params)
      .pipe(RxOp.map(response => response.data[0]));
  }

  public listCurrentEvents(params?: any): Rx.Observable<any> {
    return this.apiService.post('/event/list_current', params).pipe(RxOp.map(response => response.data[0]));
  }

  public detail(eventId: string): Rx.Observable<any> {
    return this.apiService.get(`/event/detail/${eventId}`).pipe(RxOp.map(response => response.data[0]));
  }

  public details<T>(eventId: string): Rx.Observable<T> {
    return this.apiService.get(`/event/detail/${eventId}`).pipe(RxOp.map(response => response.data[0]));
  }

  public allDetails(eventId: string): Rx.Observable<EventDetail> {
    return this.apiService.get(`/event/detail/${eventId}`).pipe(
      RxOp.map(response => response.data[0]),
      RxOp.map(response => this.transformData(response))
    );
  }

  public detailJobsLotPending<T>(eventId: string): Rx.Observable<T> {
    return this.apiService
      .get(`/event/event_detail_jobs_lot_pending/${eventId}`)
      .pipe(RxOp.map(response => response.data[0]));
  }

  public dashboardStatistics(eventId: string): Rx.Observable<EventPanelStatistics> {
    return this.apiService.get(`/statistics/event_dashboard/${eventId}`).pipe(RxOp.map(response => response));
  }

  public edit(eventId: string, params: Partial<IEvent | IEventPost>): Rx.Observable<any> {
    return this.apiService.post(`/event/edit/${eventId}`, params);
  }

  public editTemplate<T>(eventId: string, params: Partial<T>): Rx.Observable<any> {
    return this.apiService.post(`/event/edit/${eventId}`, params);
  }
  public availableSaleUrl(url: string): Rx.Observable<any> {
    return this.apiService.get(`/event/available_sale_url/${url}`);
  }

  public roles(): Rx.Observable<any[]> {
    return this.apiService.get('/user/roles').pipe(RxOp.map(({ data }) => data));
  }

  public createEvent(model: any): Rx.Observable<any> {
    return this.apiService.post('/event/create_event_ticket_lot', model);
  }

  public createLight(model: any): Rx.Observable<any> {
    return this.apiService.post('/event/create_light', model);
  }

  public editEvent(model: any): Rx.Observable<any> {
    return this.apiService.post('/event/update_event_ticket_lot', model);
  }

  public archiveEvent(data: any): Rx.Observable<any> {
    return this.apiService.post('/event/archive', data);
  }

  public delete(id: number): Rx.Observable<void> {
    return this.apiService.delete(`/user/${id}`);
  }

  public checkInDetails(hash: string): Rx.Observable<GetEventCheckInDetailsResponse> {
    return this.apiService.get(`/presence/checkin_get_detail/${hash}`).pipe(RxOp.map(response => response.data[0]));
  }

  public listCategories(): Rx.Observable<IEventCategory[]> {
    return this.apiService.get('/category/list').pipe(RxOp.map(res => res.data));
  }

  public getEventIdByCntCod(cnt_cod: string): Rx.Observable<any> {
    return this.apiService.get(`/event/get_event_id/${cnt_cod}`);
  }

  private transformData(data: IEvent): EventDetail {
    const pageConfig: IEventPageEdit = JSON.parse(data.page_config);
    const templatePage = Object.keys(templatePages).find(
      template => templatePages[template].name === pageConfig.templateVersion
    );

    return {
      event: data,
      eventHeader: {
        isLive: data.is_live,
        title: data.title,
        eventId: data.id,
        date: {
          start: `${data.start_date} às ${data.start_hour}`,
          end: `${data.end_date} às ${data.end_hour}`
        },
        image: CDN_URL + data?.image_path
      },
      pageContent: {
        page_config: JSON.parse(data.page_config),
        sale_url: data.sale_url,
        description: data.description,
        draft_description: data.description,
        draft_page_config: typeof data.draft_page_config === 'string' ? JSON.parse(data.draft_page_config) : ''
      },
      eventTickets: data.tickets,
      advertisementLinks: {
        saleLink: data.sale_url,
        advertisementLot: this.getEventLotLinks(data)
      },
      page_config: pageConfig,
      partnerConfig: {
        templateType: data.template_type || templatePage,
        isPartnerTemplate: !!templatePages[data.template_type]?.isPartner,
        partnerEditUrl: data.partner_edit_url || `${ORBIT_PAGES_URL}?blinket_event_id=${data.id}`,
        partnerSaleUrl: data.partner_sale_url,
        isPagePublished: !!data.partner_edit_url
      }
    };
  }

  private getEventLotLinks(data: IEvent): Array<AdvertisementlotLotInfos[]> {
    const mapLotItens = (cnt_cod: number, name: string, index: number): AdvertisementlotLotInfos => {
      return {
        name: `${name} - Lote ${index + 1}`,
        cnt_cod
      };
    };

    return data?.tickets.map(item => item.lot.map((lot, index) => mapLotItens(lot.cnt_cod, item.name, index)));
  }
}

const eventService = new EventService(apiService);
export default eventService;
