import React from 'react';

import { ExportOutlined } from '@ant-design/icons';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { Input, Tooltip, Typography } from 'antd';

import { CustomButtonAntd } from 'components/Shared/CustomButtonAntd/style';

type SaleLinkProps = {
  cod: string | number;
  name: string;
  isTicket?: boolean;
  isActive: boolean;
};

const getLink = ({ isActive, cod }: Partial<SaleLinkProps>) => {
  if (!isActive) {
    return ['#', '(Inativo)'];
  }

  const link = `${process.env.REACT_APP_SUN_URL}/${cod}`;
  return [link, link];
};
const SaleLink = ({ cod, name, isTicket, isActive }: SaleLinkProps) => {
  const [loading, setLoading] = React.useState(true);
  const [links, setLinks] = React.useState(['#', '#']);

  React.useEffect(() => {
    if (cod) {
      setLoading(false);
      setLinks(getLink({ isActive, cod }));
    }
  }, [isActive, cod]);

  return (
    <div className='event-links-sales-page' key={cod} id={isTicket ? 'lot-link-auto-change' : 'lot-link'}>
      <span className='event-links-title'>
        {isTicket ? (
          <>
            {name} - Link para virar lote automaticamente{' '}
            <Tooltip
              title={
                <Typography style={{ color: 'white' }}>
                  Ao utilizar esse link a troca de lote expirado será efetuado automaticamente, para o próximo lote
                  disponível.
                </Typography>
              }
              placement='top'
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        ) : (
          <>{name}</>
        )}
      </span>
      <div className='event-links-container-desk'>
        <Input
          name={`link-${name.replace(' ', '-')}`}
          className='event-links-text-field lot-links'
          size='small'
          style={{ backgroundColor: 'white', cursor: 'auto' }}
          prefix={
            <a
              {...(isActive ? { target: '_blank' } : {})}
              href={loading ? '#' : links[0]}
              rel='noopener noreferrer'
              style={{ marginRight: '4px' }}
            >
              <ExportOutlined />
            </a>
          }
          value={loading ? 'Criando link de divulgação...' : links[1]}
        />
        <Tooltip title={<Typography style={{ color: 'white' }}>Copiado!</Typography>} trigger='click'>
          <CustomButtonAntd
            onClick={() => {
              navigator.clipboard.writeText(links[1]);
            }}
            size='large'
            icon={<CopyOutlined />}
          >
            Copiar
          </CustomButtonAntd>
        </Tooltip>
      </div>
    </div>
  );
};

export default SaleLink;
