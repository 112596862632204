import React, { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { Button, Select, Input, Row, Col, Typography } from 'antd';

import CurrencyInput from 'components/Shared/CurrencyInput';
import Field from 'components/Shared/Field';

import * as S from './styles';
import OffersContext from '../../context';

interface TicketsProps {
  handleRemove: (index: number) => void;
  index: number;
}

const Tickets = ({ handleRemove, index }: TicketsProps) => {
  const { tickets } = useContext(OffersContext);
  const form = useFormContext();
  const ticketsOptions = useMemo(
    () =>
      tickets
        .map(ticket => {
          const ordenateAvailableLots = ticket.lot
            .filter(item => item.end_sale.getTime() >= new Date().getTime())
            .sort((a, b) => b.end_sale.getTime() - a.end_sale.getTime());

          return {
            value: `${ticket.id}/stock:${ordenateAvailableLots[0]?.quantity_available}`,
            label: ticket.name,
            hasAvailableLots: !!ordenateAvailableLots.length
          };
        })
        .filter(item => item.hasAvailableLots),
    [tickets]
  );

  const currentOfferForm = form.watch(`tickets.${index}`);
  const totalValues = Number(currentOfferForm?.ticketValue) * Number(currentOfferForm?.ticketQuantity);

  return (
    <S.Container>
      <div className='tickets'>
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <Typography.Text>Ingresso</Typography.Text>
            <Field
              name={`tickets.${index}.ticketId`}
              render={
                <Select
                  placeholder='Selecione o Ingresso'
                  size='large'
                  style={{ display: 'block' }}
                  getPopupContainer={node => node.parentNode}
                  options={ticketsOptions}
                />
              }
            />
          </Col>
          <Col md={12} xs={24}>
            <Typography.Text>Quantidade</Typography.Text>
            <Field name={`tickets.${index}.ticketQuantity`} render={<Input size='large' placeholder='Quantidade' />} />
          </Col>

          <Col md={12} xs={24}>
            <Typography.Text>Valor unitário</Typography.Text>
            <Field name={`tickets.${index}.ticketValue`} render={<CurrencyInput />} />
          </Col>

          <Col md={12} xs={24}>
            <Typography.Text>Valor total do item</Typography.Text>
            <CurrencyInput value={totalValues} disabled />
          </Col>
        </Row>
        {index > 0 && (
          <div style={{ marginLeft: 'auto', marginTop: '16px' }}>
            <Button icon={<DeleteOutlined />} onClick={() => handleRemove(index)}>
              Excluir
            </Button>
          </div>
        )}
      </div>
    </S.Container>
  );
};

export default Tickets;
