import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import eventService from 'services/event';

const EditByCntCod: React.FC = () => {
  const { cntCod } = useParams<{ cntCod: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!cntCod) {
      setIsLoading(false);
      return;
    }

    const subscription = eventService.getEventIdByCntCod(cntCod).subscribe({
      next: response => {
        if (response?.data?.event_id) {
          history.replace(`/event/edit/${response?.data?.event_id}`);
        } else {
          console.error('Event ID não encontrado');
        }
      },
      error: error => {
        console.error('Erro ao buscar event ID:', error);
        setIsLoading(false);
      },
      complete: () => {
        setIsLoading(false);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [cntCod, history]);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return null;
};

export default EditByCntCod;
