import createStyles from '@mui/styles/createStyles';
import { fontSizePxToRem } from 'helpers/functions';

const styles = theme =>
  createStyles({
    btnPublish: {
      background: '#FFBC00',
      color: theme.palette.primary.contrastText,
      borderColor: 'd9d9d9',
      fontSize: fontSizePxToRem(12)
    },
    btnDraft: {
      background: '#ffffff',
      color: theme.palette.primary.contrastText,
      borderColor: 'd9d9d9',
      fontSize: fontSizePxToRem(12)
    },
    draftContent: {
      maxHeight: 'calc(100vh - 60px) !important'
    }
  });

export default styles;
