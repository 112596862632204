import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import ArchiveEvent from 'components/Pages/Admin/MyEvents/ArchiveEvent';
import DuplicateEvent from 'components/Pages/Admin/MyEvents/DuplicateEvent';
import { useEventDetail } from 'components/Pages/Admin/TabsMyEvents/context/EventDetail';
import SaleLinks from 'components/Presentational/SaleLinks';
import CustomLoader from 'components/Shared/CustomLoader';
import Tour from 'components/Shared/Tour';
import { getTargetElement } from 'components/Shared/Tour/helpers';
import useTabTour from 'hooks/useTabTour';
import ReactPlaceholder from 'react-placeholder/lib';
import { titlePageService } from 'services/routes';

import useUpdateTicketListUntilAllCntCodsAreCreated from './hooks';

const SaleLinksPanel = () => {
  const { id } = useParams<{ id: string }>();
  const { showTour, openTour, closeTour } = useTabTour('sale-links');

  const [archiveEvent, setArchiveEvent] = useState(false);
  const [duplicateEvent, setDuplicateEvent] = useState(false);

  const handleDuplicateEvent = () => {
    setDuplicateEvent(prevState => !prevState);
  };

  const handleArchiveEvent = () => {
    setArchiveEvent(prevState => !prevState);
  };

  const { data, loading, newSaleUrl } = useEventDetail();

  useEffect(() => {
    titlePageService.set(
      ['Meus Eventos'],
      [
        { label: 'Meus Eventos', path: '/my-events' },
        { label: data?.event.title, path: `/my-events/${data?.event.id}/basic-data` },
        { label: 'Links de divulgação', path: `/my-events/${data?.event.id}/sale-links` }
      ],
      { label: 'Dados básicos', path: `/my-events/${data?.event.id}/basic-data` }
    );
  }, [data?.event.id, data?.event.title]);

  const { eventTicketsList } = useUpdateTicketListUntilAllCntCodsAreCreated(data?.event, id);

  const history = useHistory();

  const steps = useMemo(() => {
    if (!eventTicketsList) {
      return [];
    }

    type TourStep = {
      title: string;
      description: string;
      target: () => HTMLElement;
      isLastStep?: boolean;
    };

    const tourSteps: TourStep[] = [];

    const eventHasSalePage = id !== data?.event.sale_url;
    const hasNewSaleUrl = !!newSaleUrl;

    if (!eventHasSalePage && !hasNewSaleUrl) {
      tourSteps.push({
        title: 'Links de divulgação',
        description:
          'Você ainda não tem uma página de vendas. Você pode criar uma página de vendas de forma gratuita na aba Página do Evento.',
        target: null
      });
    }

    if (eventHasSalePage || hasNewSaleUrl) {
      tourSteps.push({
        title: 'Links de divulgação',
        description:
          'Use este link para divulgar sua página de vendas da Blinket. Você pode personaliza-la de forma gratuita.',
        target: getTargetElement('div#sales-page-link')
      });
    }

    const eventHasOrbitPage = !!data?.event.partner_sale_url;

    if (eventHasOrbitPage) {
      tourSteps.push({
        title: 'Links de divulgação',
        description: 'Use este link para divulgar sua página de vendas da Orbit Pages.',
        target: getTargetElement('div#orbit-page-sale-link')
      });
    }

    const hasLotLinkAutoChange = eventTicketsList.some(ticket => !!ticket.cnt_cod);

    if (hasLotLinkAutoChange) {
      tourSteps.push({
        title: 'Links de divulgação',
        description:
          'Caso tenha sua própria página de vendas, use o link para virada de lote automático. Quando um lote expira, é alterado para o próximo lote sem precisar de configuração.',
        target: getTargetElement('div#lot-link-auto-change')
      });
    }

    const lastStep = {
      title: 'Links de divulgação',
      description: 'Para divulgar um lote em específico, use este link.',
      target: getTargetElement('div#lot-link'),
      isLastStep: true
    };

    tourSteps.push(lastStep);

    return tourSteps;
  }, [data?.event.partner_sale_url, data?.event.sale_url, eventTicketsList, id, newSaleUrl]);

  return (
    <div style={{ margin: 'auto' }}>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={!loading}>
        <SaleLinks
          tickets={eventTicketsList}
          saleLink={data?.event.sale_url ?? newSaleUrl}
          orbitPagesUrl={data?.event.partner_sale_url}
          openTour={openTour}
        />
        <DuplicateEvent
          eventId={data?.event.id}
          handleClose={handleDuplicateEvent}
          isLive={!!data?.event.is_live}
          open={duplicateEvent}
        />
        <ArchiveEvent
          eventId={data?.event.id}
          onComplete={() => history.push('/my-events')}
          handleClose={handleArchiveEvent}
          open={archiveEvent}
        />
      </ReactPlaceholder>
      {!loading && <Tour steps={steps} open={showTour} onClose={closeTour} />}
    </div>
  );
};

export default SaleLinksPanel;
