import React from 'react';

import { Typography } from 'antd';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { IProps } from './types';

const { Text } = Typography;

const TextFormattingForm: React.FC<IProps> = ({ classes, onChange, data, displayedInfo }) => {
  const { displayEvent, displayTicket, isDisplayName } = displayedInfo;

  return (
    <>
      {(!!displayEvent || !!displayTicket || !!isDisplayName) && (
        <Text className={classes.formHeader}>Selecione o tamanho da fonte:</Text>
      )}

      <Grid container>
        {isDisplayName ? (
          <Grid item sm={4} className={classes.formFontSizeGridItem}>
            <FormControl size='small'>
              <Text className={classes.selectLabel}>Nome:</Text>
              <Select
                onChange={(e: any) =>
                  onChange({
                    ...data,
                    nameFontSize: e.target.value
                  })
                }
                value={data.nameFontSize}
                className={classes.selectList}
              >
                <MenuItem value='3'>Pequeno</MenuItem>
                <MenuItem value='4'>Médio</MenuItem>
                <MenuItem value='5'>Grande</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : null}

        {displayTicket ? (
          <Grid item sm={4} className={classes.formFontSizeGridItem}>
            <FormControl size='small'>
              <Text className={classes.selectLabel}>Ingresso: </Text>
              <Select
                onChange={(e: any) => {
                  onChange({
                    ...data,
                    ticketFontSize: e.target.value
                  });
                }}
                value={data.ticketFontSize}
                className={classes.selectList}
              >
                <MenuItem value='3'>Pequeno</MenuItem>
                <MenuItem value='4'>Médio</MenuItem>
                <MenuItem value='5'>Grande</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : null}

        {displayEvent ? (
          <Grid item sm={4} className={classes.formFontSizeGridItem}>
            <FormControl size='small'>
              <Text className={classes.selectLabel}>Evento: </Text>
              <Select
                onChange={(e: any) => {
                  onChange({
                    ...data,
                    eventFontSize: e.target.value
                  });
                }}
                value={data.eventFontSize}
                className={classes.selectList}
              >
                <MenuItem value='3'>Pequeno</MenuItem>
                <MenuItem value='4'>Médio</MenuItem>
                <MenuItem value='5'>Grande</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>

      <div style={{ paddingBottom: 16 }} />

      <Text className={classes.formHeader}>Alinhamento do conteúdo:</Text>

      <Grid container>
        <Grid item sm={12}>
          <FormControl size='small'>
            <Select
              onChange={(e: any) => {
                onChange({
                  ...data,
                  textAlign: e.target.value
                });
              }}
              value={data.textAlign}
              style={{ width: '250px' }}
            >
              <MenuItem value='left'>Alinhado a Esquerda</MenuItem>
              <MenuItem value='center'>Centralizado</MenuItem>
              <MenuItem value='right'>Alinhado a Direita</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(TextFormattingForm);
