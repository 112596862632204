import { Button } from 'antd';

import styled from 'styled-components';

export const CustomButtonAntd = styled(Button)`
  .ant-btn {
    background-color: none;
    border-color: #d9d9d9;
    color: #000000;
    align-content: center;

    &:hover {
      color: #00000;
      border-color: #000000;
    }
  }
`;
