import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    minWidth: 'calc(100% - 300px)',
    [theme.breakpoints.up('md')]: {}
  },
  bgColorGrey: {
    backgroundColor: '#f9fafb'
  }
}));

export default useStyles;
