import React, { useEffect, useState } from 'react';

import { notification } from 'antd';

import withStyles from '@mui/styles/withStyles';
import eventService from 'services/event';

import parentStyles from '../styles';
import PresenceFilter from './Filter';
import Toolbar from './Toolbar';
import { IProps } from './types';

const FilterOptions = ({
  attendanceItems,
  classes,
  filter,
  tags,
  user,
  eventDetail,
  finishedEvent,
  eventIdSelected,
  selectedParticipants,
  refreshData,
  loadData,
  handleOpenModalLink,
  handleSubmitFilter,
  handleUpdateParentState,
  handleOpenModalResendTicket,
  handleCheckinAttendances,
  handleOpenModalLabelManager,
  handleOpenModalSendEmailToParticipants,
  disableLabelManager
}: IProps) => {
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [events, setEvents] = useState([]);
  const [filterSelected, setFilterSelected] = useState([
    { type: 'search', value: '' },
    { type: 'status', value: 'all' },
    { type: 'ticket_id', value: 'all' },
    { type: 'tags', value: 'all' }
  ]);

  useEffect(() => {
    eventService.list().subscribe(
      events => {
        setEvents(events);
        setLoadingEvents(false);
      },
      ({ data: err }) => {
        if (err.message === 'Você não possui permissão para realizar esta ação') {
          notification.error({ message: err.message });
        }
      }
    );
  }, []);

  const handleAddFilter = (value: any, type: string) => {
    const newFilter = [...filterSelected].filter(item => item.type !== type);

    handleUpdateParentState({ filterSelected: [...newFilter, { value, type }] });
  };

  return (
    <>
      <PresenceFilter
        filter={filter}
        handleUpdateParentState={handleUpdateParentState}
        handleAddFilter={handleAddFilter}
        handleSubmitFilter={handleSubmitFilter}
        classes={classes}
      />

      <Toolbar
        attendanceItems={attendanceItems}
        loading={loadingEvents}
        events={events}
        eventDetail={eventDetail}
        finishedEvent={finishedEvent}
        tags={tags}
        eventIdSelected={eventIdSelected}
        user={user}
        filter={filter}
        selectedParticipants={selectedParticipants}
        disableLabelManager={disableLabelManager}
        refreshData={refreshData}
        loadData={loadData}
        handleSubmitFilter={handleSubmitFilter}
        handleOpenModalResendTicket={handleOpenModalResendTicket}
        handleOpenModalLink={handleOpenModalLink}
        handleCheckinAttendances={handleCheckinAttendances}
        handleOpenModalLabelManager={handleOpenModalLabelManager}
        handleSetFilterSelected={setFilterSelected}
        handleUpdateParentState={handleUpdateParentState}
        handleAddFilter={handleAddFilter}
        handleOpenModalSendEmailToParticipants={
          false ? () => handleOpenModalSendEmailToParticipants('toSelected') : null
        }
      />
    </>
  );
};

export default withStyles(parentStyles)(FilterOptions);
