import createStyles from '@mui/styles/createStyles';

const styles = theme =>
  createStyles({
    buttonNext: {
      background: theme.palette.primary.main,
      color: '#FFFFFF',
      width: 140
    },

    actionButtons: {
      display: 'flex',
      justifyContent: 'right',
      width: '100%'
    },

    finished: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '2em'
    },

    iconFinished: {
      color: 'green',
      fontSize: '64px',
      marginBottom: '32px'
    }
  });

export default styles;
