import createStyles from '@mui/styles/createStyles';
import { fontSizePxToRem, setMargin, setPadding } from 'helpers/functions';

const styles = theme =>
  createStyles({
    root: {
      'gridArea': 'telescope',
      'position': 'relative',
      'overflow': 'hidden',
      ...setPadding(14, 0, 14, 24),
      'minHeight': 172,
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        width: 12,
        height: '100%',
        backgroundColor: '#0d47a1'
      },
      [theme.breakpoints.down('lg')]: {
        minHeight: 182,
        ...setPadding(20, 0, 14, 24)
      }
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      color: '#0d47a1'
    },
    description: {
      color: theme.palette.primary.contrastText,
      fontSize: fontSizePxToRem(14),
      maxWidth: '100%',
      ...setMargin(14, 0, 11, 0),
      [theme.breakpoints.down('lg')]: {
        maxWidth: 250,
        ...setMargin(20, 0, 28, 0)
      }
    },
    link: {
      'color': '#0d47a1',
      'textDecoration': 'none',
      'fontSize': fontSizePxToRem(14),
      'display': 'flex',
      'alignItems': 'center',
      '& svg': {
        marginLeft: 5
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: fontSizePxToRem(12)
      }
    },
    telescope: {
      position: 'absolute',
      right: 17,
      bottom: 0,
      fontSize: fontSizePxToRem(39),
      [theme.breakpoints.down('lg')]: {
        fontSize: fontSizePxToRem(65)
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizePxToRem(42)
      }
    }
  });

export default styles;
