import React, { PureComponent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { IProps, IState } from './types';

class Loader extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { refs: [] };
  }

  public show = (ref: string): void => {
    if (typeof ref !== 'string') {
      throw new Error('Loader.show needs a ref string value');
    }

    const { refs } = this.state;
    if (refs.includes(ref)) return;

    this.setState({ refs: [...refs, ref] });
  };

  public hide = (ref: string): void => {
    if (typeof ref !== 'string') {
      throw new Error('Loader.hide needs a ref string value');
    }

    const { refs } = this.state;
    const index = refs.indexOf(ref);
    if (index === -1) return;

    refs.splice(index, 1);
    this.setState({ refs: [...refs] });
  };

  public render(): JSX.Element {
    const { refs } = this.state;
    const { classes } = this.props;

    return (
      <Modal open={!!refs.length}>
        <LoadingOutlined className={classes.loader} spin />
      </Modal>
    );
  }
}

export default withStyles(styles)(Loader);
