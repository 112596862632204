import createStyles from '@mui/styles/createStyles';
import { fontSizePxToRem } from 'helpers/functions';
const styles = () =>
  createStyles({
    headString: {
      fontFamily: "'Roboto', sans-serif",
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: fontSizePxToRem(12),
      lineHeight: '150%',
      color: '#000000'
    }
  });

export default styles;
