export const antdTheme = {
  token: {
    colorPrimary: '#FFBC00',
    colorSecondary: '#0D2772',
    colorTextLightSolid: '#000000',
    colorBgLayout: '#fcfcfc',
    colorBgTextHover: 'rgba(0, 0, 0, 0.03)',
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    borderRadius: 4
  },
  components: {
    Tooltip: {
      colorBgSpotlight: '#FFBC00'
    }
  }
};
