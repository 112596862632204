import { UserTheme } from 'styles/types';

const defaultTheme: UserTheme = {
  'menu': {
    default: {
      background: '#ffffff',
      color: ''
    },
    selected: {
      background: '#f9fafb',
      borderColor: '#ECEFF1'
    }
  },
  'sub-menu': {
    default: {
      background: '#ffffff'
    }
  },
  'item-menu': {
    default: {
      background: '#f9fafb',
      color: '#78909C'
    },
    active: {
      background: '#f9fafb',
      color: '#37474f'
    },
    hover: {
      background: '#f9fafb',
      color: '#37474f'
    },
    selected: {
      background: '#f9fafb',
      color: '#37474f'
    }
  }
};
export default defaultTheme;
