import React from 'react';
import { isMobile } from 'react-device-detect';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TablePagination, { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Table from 'components/Shared/Table';
import TableBody from 'components/Shared/Table/components/TableBody';
import TableHead from 'components/Shared/Table/components/TableHead';
import { IAttendaceList } from 'interfaces/attendanceList';

import ListItem from './ListItem';
import { IProps } from './types';

const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => `${from}-${to} de ${count}`;
export default function PresenceList({
  items,
  loading,
  pagination,
  loadData,
  finishedEvent,
  handleOpenModalResendTicket,
  handleOpenModalLink,
  handleOpenModalEdit,
  handlePaginationChange,
  handleOpenModalLabelManager,
  handleOpenAttendanceDetail,
  handleCheckinAttendances,
  selectedParticipants,
  handleOpenModalSendEmailToParticipants,
  handleUpdateParentState,
  isEventLiveOnline,
  attendanceItems,
  allParticipantsOfPageAreSelected,
  allPagesParticipantsAreSelected
}: IProps) {
  const attendanceListIds = [];

  const tableHead = [
    { label: 'PARTICIPANTE' },
    { label: 'Nº INGRESSO' },
    { label: 'STATUS' },
    { label: 'TIPO DE INGRESSO' },
    { label: 'COMPRADOR' },
    { label: 'CHECK-IN', textAlign: 'center' },
    { label: 'AÇÕES', textAlign: 'center' }
  ];

  const tableHeadMobile = [{ label: 'PARTICIPANTE' }, { label: 'STATUS' }, { label: 'AÇÕES', textAlign: 'center' }];

  const updateSelectedParticipants = (participant: IAttendaceList, isSelected: boolean) => {
    if (isSelected) {
      if (!selectedParticipants.includes(participant)) {
        selectedParticipants.push(participant);
      }
    } else {
      selectedParticipants.splice(selectedParticipants.indexOf(participant), 1);
    }

    handleUpdateParentState({ selectedParticipants: [...selectedParticipants] });
  };

  const handleSelectAll = (isSelected: boolean) => {
    const updatedSelectedParticipants = isSelected
      ? [
          ...selectedParticipants,
          ...attendanceItems.filter(
            participant => selectedParticipants.findIndex(item => item.id === participant.id) === -1
          )
        ]
      : selectedParticipants.filter(
          participant => attendanceItems.findIndex(item => item.id === participant.id) === -1
        );

    handleUpdateParentState({ selectedParticipants: updatedSelectedParticipants });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    loadData(newPage + 1);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {};

  const tablePaginationProps = {
    labelRowsPerPage: 'items',
    component: 'div',
    labelDisplayedRows,
    count: pagination.totalRows,
    rowsPerPage: pagination.size,
    backIconButtonProps: { disabled: loading || pagination.page === 1 },
    nextIconButtonProps: { disabled: loading || pagination.page === pagination.totalPages },
    page: pagination.page - 1,
    onPageChange: handleChangePage,
    onRowsPerPageChange: handleChangeRowsPerPage,
    rowsPerPageOptions: []
  };

  return (
    <>
      <Table>
        <TableHead
          head={isMobile ? tableHeadMobile : tableHead}
          onSelectAll={handleSelectAll}
          allParticipantsAreSelected={allParticipantsOfPageAreSelected}
          disableSelectAll={allPagesParticipantsAreSelected}
        />
        <TableBody loading={loading}>
          {items.length > 0 &&
            items.map(row => {
              attendanceListIds.push(row.id);

              row.attendance_list_ids = attendanceListIds;

              return (
                <ListItem
                  key={row.id}
                  item={row}
                  finishedEvent={finishedEvent}
                  handleOpenModalResendTicket={handleOpenModalResendTicket}
                  handleOpenModalLink={handleOpenModalLink}
                  handleOpenModalEdit={handleOpenModalEdit}
                  handleOpenModalLabelManager={handleOpenModalLabelManager}
                  handleOpenAttendanceDetail={handleOpenAttendanceDetail}
                  handleCheckinAttendances={handleCheckinAttendances}
                  reloadData={loadData}
                  updateSelectedParticipants={updateSelectedParticipants}
                  selectedParticipants={selectedParticipants}
                  handleOpenModalSendEmailToParticipants={handleOpenModalSendEmailToParticipants}
                  isEventLiveOnline={isEventLiveOnline}
                  disableCheckboxes={allPagesParticipantsAreSelected}
                />
              );
            })}
        </TableBody>
      </Table>

      <Grid container style={{ marginTop: 12, marginBottom: 32 }}>
        <Grid
          item
          sm={4}
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ marginRight: '0.5em' }}>Itens por página:&nbsp;</span>
          {!loading ? (
            <TextField
              select
              name='pageSize'
              value={pagination.size}
              onChange={(e: any) => handlePaginationChange(e.target.value)}
              id='presencelist-pagesize-input'
            >
              <MenuItem value={25} id='presencelist-pagesize-input-25'>
                25
              </MenuItem>
              <MenuItem value={50} id='presencelist-pagesize-input-50'>
                50
              </MenuItem>
              <MenuItem value={100} id='presencelist-pagesize-input-100'>
                100
              </MenuItem>
            </TextField>
          ) : (
            '...'
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          <TablePagination {...tablePaginationProps} />
        </Grid>
      </Grid>
    </>
  );
}
